import React from "react";
import { ApplicationModalContainer, ApplicationFormContainer } from "./styles";
import Modal from "../../../../../common/Modal";
import Loading from "../../../../../common/Loading";
import callAPI from "../../../../../api/apiCaller";
import { Context } from "../../../../../Store";
import { useParams } from "react-router-dom";
import Pills from "../../../../../common/Pills";
import Labelize from "../../../../../common/Labelize";
import {
  createInitialValid,
  validateIsValid,
  validateText,
} from "../../../../../common/utils";
import { ButtonSpacer } from "../../../../../common/Button/styles";
import TextInput from "../../../../../common/TextInput";
import {
  DropSelect,
  MultipleChoiceOuter,
  MultipleChoiceLabel,
  MultipleChoiceInput,
  DropDownOuter,
  DropDownIcon,
} from "./styles";
import Icon from "../../../../../common/Icon";

function ApplicationModal({ closeModal, programName }) {
  const params = useParams();
  const [applicationForm, setApplicationForm] = React.useState(null);
  const [application, setApplication] = React.useState([]);
  const [roleOptions, setRoleOptions] = React.useState(null);
  const [applyAs, setApplyAs] = React.useState("");
  const { dispatch } = React.useContext(Context);
  const [isValid, setIsValid] = React.useState(null);
  const [isApplying, setIsApplying] = React.useState(false);
  React.useEffect(() => {
    callAPI(dispatch, "getApplicationForm", {
      id: params.selectedProgram,
    }).then((retrievedForm) => {
      setApplicationForm(retrievedForm.data);
      let options = [
        {
          label: "Mentee",
          value: "mentee",
        },
        {
          label: "Mentor",
          value: "mentor",
        },
        {
          label: "Member",
          value: "member",
        },
      ].filter((option) => retrievedForm.data.accepts[option.value]);
      setRoleOptions(options);
      if (options.length > 0) {
        setApplyAs(options[0].value);
      }
    });
  }, []);
  React.useEffect(() => {
    if (applyAs === "") {
      return;
    }
    applicationForm[applyAs] &&
      setIsValid(
        createInitialValid([...Array(applicationForm[applyAs]?.length).keys()])
      );
    let newApplication = applicationForm[applyAs].map((q, index) => {
      return {
        id: q.id,
        question: q.question,
        multipleQuestion: q.multipleQuestion,
        answer: "",
      };
    });
    setApplication(newApplication);
  }, [applyAs]);
  function apply() {
    setIsApplying(true);

    callAPI(dispatch, "applyToProgram", {
      id: params.selectedProgram,
      role: applyAs,
      answers: [...application],
    }).then((res) => {
      if (res.isResponseOk) {
        window.location.reload();
        closeModal(false);
      } else closeModal(false);
    });
  }
  if (roleOptions === null) {
    return (
      <Modal title={"Apply to " + programName?.title}>
        <ApplicationModalContainer>
          <Loading loadingItem="Application Questions" />
        </ApplicationModalContainer>
      </Modal>
    );
  }
  if (roleOptions.length === 0) {
    return (
      <Modal
        title={"Apply to " + programName?.title}
        actions={[
          {
            text: "Back",
            onClick: () => {
              closeModal(false);
            },
            icon: "back",
          },
        ]}
      >
        <ApplicationModalContainer>
          {programName?.title} is not accepting any applications now.
        </ApplicationModalContainer>
      </Modal>
    );
  }
  if (application.length !== applicationForm[applyAs].length) {
    return (
      <Modal title={"Apply to " + programName?.title}>
        <ApplicationModalContainer>
          <Loading loadingItem="Application Form" />
        </ApplicationModalContainer>
      </Modal>
    );
  }

  return (
    <Modal
      title={"Apply to " + programName?.title}
      actions={[
        {
          text: "Cancel",
          onClick: () => {
            closeModal(false);
          },
          icon: "cross",
        },
        {
          text: "Apply",
          variant: "primary",
          onClick: () => {
            apply();
          },
          disabled: !validateIsValid(isValid),
          icon: isApplying ? "loading" : "tick",
        },
      ]}
    >
      <ApplicationModalContainer>
        <Labelize label={"Apply as"}>
          <Pills value={applyAs} setValue={setApplyAs} options={roleOptions} />
          {programName?.programType !== "TEAM" && roleOptions.length === 1 && (
            <>
              <ButtonSpacer /> {programName?.title} is not accepting
              applications for{" "}
              {roleOptions[0].value === "mentor" ? "Mentees" : "Mentors"}
            </>
          )}

          {!applyAs &&
            programName?.programType === "TEAM" &&
            roleOptions.length === 1 && (
              <>
                <ButtonSpacer /> {programName?.title} is not accepting
                applications for {roleOptions[0].value}
              </>
            )}
        </Labelize>
        {/* <ApplicationFormContainer>
        <ButtonSpacer />
        {
          application.map((qa, index) => {
            return <Labelize label={qa.question}>
              <TextInput value={qa.answer} setValue={(newValue) => {
                setApplication(application.map((cqa, i) => {
                  if (i === index) {
                    return {
                      ...qa,
                      answer: newValue
                    }
                  }
                  else {
                    return cqa
                  }
                }))
              }}
                isValid={isValid[index]}
                validator={validateText}
                setIsValid={(newIsValid) => {
                  let newValid = { ...isValid };
                  newValid[index] = newIsValid;
                  setIsValid(newValid)
                }}
                placeholder="type here..."
              />
              <ButtonSpacer />
            </Labelize>
          })
        }
      </ApplicationFormContainer> */}

        <ApplicationFormContainer>
          <ButtonSpacer />
          {application.map((qa, index) => {
            return (
              <Labelize label={qa.question} key={index}>
                {qa.multipleQuestion === null ? (
                  <TextInput
                    value={qa.answer}
                    setValue={(newValue) => {
                      setApplication(
                        application.map((cqa, i) => {
                          if (i === index) {
                            return { ...qa, answer: newValue };
                          }
                          return cqa;
                        })
                      );
                    }}
                    isValid={isValid[index]}
                    validator={validateText}
                    setIsValid={(newIsValid) => {
                      let newValid = { ...isValid };
                      newValid[index] = newIsValid;
                      setIsValid(newValid);
                    }}
                    placeholder="type here..."
                  />
                ) : qa?.multipleQuestion?.type === "Dropdown" ? (
                  <DropDownOuter>
                    <DropSelect
                      value={qa.answer}
                      onChange={(e) => {
                        setApplication(
                          application.map((cqa, i) => {
                            if (i === index) {
                              return { ...qa, answer: e.target.value };
                            }
                            return cqa;
                          })
                        );
                        setIsValid((prevValid) => {
                          const updatedValid = { ...prevValid };
                          updatedValid[index] = validateText(e.target.value);
                          return updatedValid;
                        });
                      }}
                    >
                      {qa.multipleQuestion.options.map((option, i) => (
                        <option key={i} value={option}>
                          {option}
                        </option>
                      ))}
                    </DropSelect>
                    <DropDownIcon>
                      <Icon type={"chevron-down"} size="18" color="#302F2F" />
                    </DropDownIcon>
                  </DropDownOuter>
                ) : qa?.multipleQuestion?.type === "Checkboxes" ? (
                  <MultipleChoiceOuter>
                    {qa.multipleQuestion.options.map((option, i) => (
                      <MultipleChoiceLabel key={i}>
                        <MultipleChoiceInput
                          type="checkbox"
                          checked={qa.answer.includes(option)}
                          onChange={(e) => {
                            const newAnswer = e.target.checked
                              ? [...qa.answer, option]
                              : qa.answer.filter((a) => a !== option);
                            setApplication(
                              application.map((cqa, i) => {
                                if (i === index) {
                                  return { ...qa, answer: newAnswer };
                                }
                                return cqa;
                              })
                            );
                            setIsValid((prevValid) => {
                              const updatedValid = { ...prevValid };
                              updatedValid[index] = validateText(newAnswer);
                              return updatedValid;
                            });
                          }}
                        />
                        {option}
                      </MultipleChoiceLabel>
                    ))}
                  </MultipleChoiceOuter>
                ) : qa?.multipleQuestion?.type === "Multiple Choice" ? (
                  <MultipleChoiceOuter>
                    {qa.multipleQuestion.options.map((option, i) => (
                      <MultipleChoiceLabel key={i}>
                        <MultipleChoiceInput
                          type="radio"
                          name={`question${index}`}
                          value={option}
                          checked={qa.answer === option}
                          onChange={(e) => {
                            setApplication(
                              application.map((cqa, i) => {
                                if (i === index) {
                                  return { ...qa, answer: e.target.value };
                                }
                                return cqa;
                              })
                            );
                            setIsValid((prevValid) => {
                              const updatedValid = { ...prevValid };
                              updatedValid[index] = validateText(e.target.value);
                              return updatedValid;
                            });
                          }}
                        />
                        {option}
                      </MultipleChoiceLabel>
                    ))}
                  </MultipleChoiceOuter>
                ) : null}
                <ButtonSpacer />
              </Labelize>
            );
          })}
        </ApplicationFormContainer>
      </ApplicationModalContainer>
    </Modal>
  );
}
export default ApplicationModal;
