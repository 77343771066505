import styled from "styled-components";
import { headerSize, panelSpacing } from "../../../common/styles";
import colors from "../../../config/colors";

export const ProgramsContainer = styled.div`
    background-color: ${colors.secondary};
    height:calc(100vh - ${headerSize}px);
    ${'' /* padding:${panelSpacing}px; */}
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    // @media (max-width:991px) {
    //     flex-direction: column;
    //     gap: 20px;
    // }
`;