import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import {
  Calendar as BigCalendar,
  Views,
  momentLocalizer,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { CalendarWrapper, CalenderHead, HeadText, HeadBottom } from "./style";
import moment from "moment";
import CustomToolbar from "./CustomToolBar";

const localizer = momentLocalizer(moment);

const Calendar = forwardRef(
  (
    {
      eventData,
      setRangeCount,
      setDisplayedEvents,
      setHasNext,
      setHasPrevious,
    },
    ref
  ) => {
    const [currentRange, setCurrentRange] = useState({
      start: moment().startOf("week").toDate(),
      end: moment().endOf("week").toDate(),
    });
    const CustomEvent = () => {
      return null;
    };
    const CustomMonthCell = ({ date, children }) => {
      if (!date) return <>{children}</>;
      const currentMonth = new Date().getMonth();
      const cellMonth = date.getMonth();
      const isCurrentMonth = cellMonth === currentMonth;

      return (
        <div
          style={{
            color: isCurrentMonth ? "inherit" : "#ccc",
            pointerEvents: isCurrentMonth ? "auto" : "none",
            opacity: isCurrentMonth ? 1 : 0.5,
            textAlign: "center",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {children}
          <div
            style={{
              width: "6px",
              height: "6px",
              backgroundColor: isCurrentMonth ? "#4A90E2" : "transparent",
              borderRadius: "50%",
              margin: "0 auto",
            }}
          ></div>
        </div>
      );
    };

    const handleRangeChange = (range) => {
      let rangeStart, rangeEnd;
      let displayedEvents = [];

      if (Array.isArray(range)) {
        rangeStart = new Date(range[0]);
        rangeEnd = new Date(range[range.length - 1]);
        rangeStart.setHours(0, 0, 0, 0);
        rangeEnd.setHours(23, 59, 59, 999);
      } else if (range?.start && range?.end) {
        rangeStart = new Date(range.start);
        rangeEnd = new Date(range.end);
        rangeStart.setHours(0, 0, 0, 0);
        rangeEnd.setHours(23, 59, 59, 999);
      } else {
        console.error(
          "Invalid range format. Expected either an array or an object with 'start' and 'end'."
        );
        return;
      }

      displayedEvents = eventData.filter((event) => {
        const eventStart = new Date(event.start);
        const eventEnd = new Date(event.end);

        return (
          (eventStart >= rangeStart && eventStart <= rangeEnd) ||
          (eventEnd >= rangeStart && eventEnd <= rangeEnd) ||
          (eventStart <= rangeStart && eventEnd >= rangeEnd)
        );
      });
      const eventsPresentOnCurrentView = displayedEvents.map((event) =>
        Math.floor(new Date(event.start).getTime() / 1000).toString()
      );
      setDisplayedEvents(eventsPresentOnCurrentView);
      setRangeCount(displayedEvents.length);

      const hasNext = eventData.some(
        (event) => new Date(event.start) > rangeEnd
      );
      const hasPrevious = eventData.some(
        (event) => new Date(event.end) < rangeStart
      );
      setHasNext(hasNext);
      setHasPrevious(hasPrevious);
    };

    useEffect(() => {
      const initialRange = {
        start: moment().startOf("week").toDate(),
        end: moment().endOf("week").toDate(),
      };
      handleRangeChange(initialRange);
    }, []);

    useImperativeHandle(ref, () => ({
      nextRange: () => {
        const duration =
          currentRange.end.getTime() - currentRange.start.getTime() + 1;
        const newStart = new Date(currentRange.start.getTime() + duration);
        const newEnd = new Date(currentRange.end.getTime() + duration);
        const newRange = { start: newStart, end: newEnd };
        handleRangeChange(newRange);
        setCurrentRange(newRange);
      },
      previousRange: () => {
        const duration =
          currentRange.end.getTime() - currentRange.start.getTime();
        const newStart = new Date(currentRange.start.getTime() - duration);
        const newEnd = new Date(currentRange.end.getTime() - duration);
        const newRange = { start: newStart, end: newEnd };
        handleRangeChange(newRange);
        setCurrentRange(newRange);
      },
    }));
    const handleViewChange = (view) => {
      let newRange;
      if (view === "week") {
        newRange = {
          start: moment(currentRange.start).startOf("week").toDate(),
          end: moment(currentRange.start).endOf("week").toDate(),
        };
      } else if (view === "month") {
        newRange = {
          start: moment(currentRange.start).startOf("month").toDate(),
          end: moment(currentRange.start).endOf("month").toDate(),
        };
      }

      handleRangeChange(newRange);
      setCurrentRange(newRange);
    };

    return (
      <>
        <CalendarWrapper>
          <CalenderHead>
            <HeadText>Duration</HeadText>
            <HeadBottom>60 min</HeadBottom>
          </CalenderHead>
          <BigCalendar
            localizer={localizer}
            onRangeChange={(range) => handleRangeChange(range)}
            onNavigate={(date, view, action) => {
              let newRange;
              if (action === "PREV") {
                newRange = {
                  start: moment(currentRange.start)
                    .subtract(1, view === "week" ? "weeks" : "months")
                    .startOf(view === "week" ? "week" : "month")
                    .toDate(),
                  end: moment(currentRange.end)
                    .subtract(1, view === "week" ? "weeks" : "months")
                    .endOf(view === "week" ? "week" : "month")
                    .toDate(),
                };
              } else if (action === "NEXT") {
                newRange = {
                  start: moment(currentRange.start)
                    .add(1, view === "week" ? "weeks" : "months")
                    .startOf(view === "week" ? "week" : "month")
                    .toDate(),
                  end: moment(currentRange.end)
                    .add(1, view === "week" ? "weeks" : "months")
                    .endOf(view === "week" ? "week" : "month")
                    .toDate(),
                };
              } else if (action === "TODAY") {
                newRange = {
                  start: moment()
                    .startOf(view === "week" ? "week" : "month")
                    .toDate(),
                  end: moment()
                    .endOf(view === "week" ? "week" : "month")
                    .toDate(),
                };
              } else {
                newRange = {
                  start: moment(date)
                    .startOf(view === "week" ? "week" : "month")
                    .toDate(),
                  end: moment(date)
                    .endOf(view === "week" ? "week" : "month")
                    .toDate(),
                };
              }

              handleRangeChange(newRange);
              setCurrentRange(newRange);
            }}
            date={currentRange.start}
            events={eventData}
            defaultView={Views.WEEK}
            views={["month", "week"]}
            onView={handleViewChange}
            style={{ height: "620px" }}
            components={{
              toolbar: CustomToolbar,
              event: CustomEvent,
              dateCellWrapper: CustomMonthCell,
            }}
            allDayAccessor={null}
            step={30}
            timeslots={1}
          />
        </CalendarWrapper>
      </>
    );
  }
);

export default Calendar;
