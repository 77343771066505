import React from 'react';
import {ImagePreview} from './style';

const MeetingImage = ({ item, iamMentor, programOneOnOne, params, isTeam, isMyMente, isMyMentor }) => {
  const getImage = () => {
    if (item?.program?.programType !== "TEAM") {
      if (item?.meeting?.status === "ACCEPT") {
        if (iamMentor && programOneOnOne && params.connectionId) {
          return item?.meeting?.user?.image;
        } else if (item?.meeting?.status === "ACCEPT" && isMyMente && programOneOnOne && params.connectionId) {
          return item.Mentor?.image;
        } else if (item?.meeting?.status === "ACCEPT" && isMyMentor) {
          return item.Mentor?.image;
        } else {
          return item?.meeting?.user?.image;
        }
      }
    } else {
      return item.team?.media || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
    }
  };

  return <ImagePreview size={100} src={getImage()} />;
};

export default MeetingImage;
