import styled from 'styled-components';


export const DropDownContainer = styled.div`
  position: relative;
  width: 200px;
  font-family: Arial, sans-serif;
`;

export const DropDownButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  color: #333;

  &:hover {
    background-color: #f7f7f7;
  }

  svg {
    transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 0.3s ease;
  }
`;

export const DropDownList = styled.ul`
  position: absolute;
  top: 40px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  max-height: 200px;
  overflow-y: auto;
  z-index: 100;
  padding: 0;
  margin: 0;
`;

export const DropDownItem = styled.li`
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? '#f0f0f0' : 'transparent')};
  
  &:hover {
    background-color: #e6e6e6;
  }
`;