import React from "react";
import colors from "../../config/colors";
import { ButtonSpacer } from "../Button/styles";
import Icon from "../Icon";
import {
  NumberOfDaysBetweenDates,
  NumberOfDaysBetweenDatesAndCurrentDate,
  dateToUnixTimeStamp,
  formatDateForMeetings,
} from "../utils";
import {
  TableContainer,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  TableHeaderCellContent,
  TablePagination,
  ResultsLabel,
  ResultsSelect,
  PaginationSpan,
  PreviousButton,
  NextButton,
  PaginationButtons,
  CurrentPage,
  AngleSpan
} from "./styles";
import Checkbox from "../Checkbox";

/**
 *
 * The classic table component.
 * Takes in columns and rows and renders them as a table.
 */
function PaginatedTable({
  columns,
  rows,
  sortTimeSheet,
  meetingsDashboard,
  meetings,
  setExportCurrentPageData
}) {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);

  const totalPages = Math.ceil(rows.length / itemsPerPage);

  const paginatedData = rows.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  const [sortOptions, setSortOptions] = React.useState({
    column: null,
    direction: null,
  });
  function sortOnColumn(column) {
    if (column === sortOptions.column && !sortTimeSheet) {
      setSortOptions({
        ...sortOptions,
        direction: sortOptions.direction === "asc" ? "desc" : "asc",
      });
    } else {
      setSortOptions({
        ...sortOptions,
        column: column,
        direction: !sortTimeSheet ? "asc" : "desc",
      });
    }
  }

  React.useEffect(() => {
    if (sortTimeSheet) {
      sortOnColumn(columns[0].key);
    }
  }, []);

  React.useEffect(() => {
    const preparedExportData = paginatedData.map((row) => {
      const days = row?.meetingScheduleDate
        ? NumberOfDaysBetweenDates([
            row?.acceptedOn,
            dateToUnixTimeStamp(row?.createdAt),
          ])
        : NumberOfDaysBetweenDatesAndCurrentDate([
            dateToUnixTimeStamp(row?.createdAt),
          ]);
      return {
        requestedBy: `${row.user.firstName} ${row.user.lastName}`,
        purpose: row.reasonForMeeting,
        days: days >= 0 ? days : "-",
        date: formatDateForMeetings(row.createdAt),
        acceptedBy: `${row.Mentor?.firstName} ${row?.Mentor?.lastName}`,
      };
    });
    setExportCurrentPageData(preparedExportData);
  }, [itemsPerPage, currentPage])

  return (
    <>
      <TableContainer meetingsDashboard={meetingsDashboard}>
        <TableHead meetingsDashboard={meetingsDashboard}>
          {columns.map((column) => (
            <TableHeaderCell
              meetingsDashboard={meetingsDashboard}
              key={"header_" + column.key}
              onClick={() => {
                if (column.sortable !== false) {
                  sortOnColumn(column.key);
                }
              }}
            >
              <TableHeaderCellContent
                meetingsDashboard={meetingsDashboard}
                space={column.checkbox}
              >
                {column.checkbox && <Checkbox />}
                {column.title}

                {sortOptions.column === column.key && (
                  <>
                    <ButtonSpacer />
                    {sortOptions.direction === "asc" ? (
                      <Icon type={"collapse"} color={colors.text} size="10" />
                    ) : (
                      <Icon type={"expand"} color={colors.text} size="10" />
                    )}
                  </>
                )}
              </TableHeaderCellContent>
            </TableHeaderCell>
          ))}
        </TableHead>
        <TableBody>
          {paginatedData
            .sort((a, b) => {
              if (sortOptions.column === null) {
                return 0;
              } else {
                let valueA = a[sortOptions.column] || "";
                let valueB = b[sortOptions.column] || "";
                return (
                  (sortOptions.direction === "asc" ? 1 : -1) *
                  valueA.toString().localeCompare(valueB.toString())
                );
              }
            })
            .map((row, index) => (
              <TableRow key={"row_" + index}>
                {columns.map((column) => (
                  <TableCell
                    meetings={meetings}
                    key={"cell_" + column.key + "_" + index}
                    meetingsDashboard={meetingsDashboard}
                  >
                    {column.render ? (
                      <column.render value={row[column.key]} row={row}>
                        {row[column.key]}
                      </column.render>
                    ) : (
                      row[column.key]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </TableContainer>
      <TablePagination>
        <div>
          <ResultsLabel>
            Results per page:
            <ResultsSelect value={itemsPerPage} onChange={handleItemsPerPageChange}>
              {[5, 10, 15, 20].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </ResultsSelect>
          </ResultsLabel>
        </div>
        <PaginationButtons>
          <PaginationSpan>
            <CurrentPage>{currentPage}</CurrentPage> out of {totalPages}
          </PaginationSpan>
          <PreviousButton
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <AngleSpan>{'<'}</AngleSpan> Previous
          </PreviousButton>

          <NextButton
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next <AngleSpan next={true}>{'>'}</AngleSpan>
          </NextButton>
        </PaginationButtons>
      </TablePagination>
    </>
  );
}
export default PaginatedTable;
