const colors={
    primary:"#44449B",
    secondary:"#EFEFEF",
    background:"#FFFFFF",
    backgroundGrey:"#F5F5F5",
    backgroundBlack:"#313134",
    darkBackground:"#CCCCCC",
    text:"#2c2c2c",
    textBlack:"#302F2F",
    textGray:"#333232",
    whiteText:"#FFFFFF",
    success:"#00C714",
    warning:"#F6C000",
    failure:"#D70000",
    disabledText:"#D1D1D1",
}

export default colors;