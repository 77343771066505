import styled from "styled-components";
import { panelSpacing } from "../../../../../common/styles";

export const RequestListContainer = styled.div`
width:  100%;
margin-left:20px;
margin: ${props=> props.margin && '10px'};
border-radius: 10px;
padding: 20px 16px 20px 16px;
background: #FFF;
box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);

::-webkit-scrollbar {
    width: 2px;
}

@media (max-width:991px){
    max-height:100vh;
}
`;

export const RequestsKeyHeader = styled.p`
    white-space: nowrap;
    text-overflow:ellipsis;
    overflow: hidden;
    @media (max-width:991px){
        width: 200px;
    }
    margin:0;
    padding-right: 5px
`;

export const RequestsDetailsWrapper = styled.div`
display: flex;
flex-direction: row;    
`;

export const Spacer = styled.div`  
margin-top:2rem; 
`;

export const RequestListWrapper  = styled.div`
border: 2px solid #efefef;
border-radius: 10px;
padding:10px;
margin-bottom:1rem;
`
export const ButtonWrapper= styled.div`
justify-content:'flex-start';
display:flex;
align-items: center
`

export const Requestcontainer= styled.div`
    height:calc(100vh - 64px);
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`
export const CardsWrapper= styled.div`
    height: ${props => props.isHome ? "calc(100vh - 170px)" : "calc(100vh - 270px)"};
    @media(max-width:991px) {
        height: ${props => props.isHome ? "calc(100vh - 170px)" : "calc(100vh - 340px)"};
    }
    @media(max-width:641px) {
        height: ${props => props.isHome ? "calc(100vh - 170px)" : "calc(100vh - 380px)"};
    }
`

export const TabsContainer= styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
 background-color: #FFFFFF;
 padding: 0 12px;
 border-radius: 10px 10px 0px 0px;
 @media (max-width: 480px){
    padding: 0;
    margin-top: 55px;
 }
 
`
export const NoprogramContainer= styled.div`
width:100%;
margin-inline: 50%;
padding-top: 70px;
`
export const ButtonsWraper= styled.div`
display:flex;
padding-top: 20px;
margin-inline: 60px;
justify-content: flex-start;
`
export const ImagePreview= styled.img`
width:${props=>props.size}px;
height:${props=>props.size}px;
margin-bottom:${props=>props.panelSpacing ?props.panelSpacing:panelSpacing}px;
display:block;
border-radius:${panelSpacing}px;
object-fit:cover;
padding-top: 70px;


`
