import React from 'react';

const MeetingModal = ({ title, onClose, isDelete, deleteProgram, cancelMeeting }) => {
  return (
    <div>
      <h3>{title}</h3>
      <button onClick={onClose}>Close</button>
      {isDelete ? (
        <button onClick={deleteProgram}>Yes, cancel meeting</button>
      ) : (
        <button onClick={cancelMeeting}>Yes</button>
      )}
      <button onClick={onClose}>No</button>
    </div>
  );
};

export default MeetingModal;