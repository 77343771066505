import styled from "styled-components";
import { Scrollable, borderRadius } from "../../../../common/styles";
import colors from "../../../../config/colors";

export const ProgramsListContainer = styled.div`
    width: 270px;
    min-width: 270px;
    background-color: #fff;
    height: 100%;
    border-radius: ${borderRadius}px;
    box-sizing: border-box;
    z-index:18;
    @media(max-width:991px) {
       position: fixed;
       left: ${props => props.hamburgerOpen===true ? '0%' : '-100%'};
       transition: 0.5s;
       border: 2px solid ${colors.secondary};
       border-left: none;
    }
    @media (max-width: 1600px) {
        width: 250px;
        min-width: 250px;
    }
`;
export const ProgramsListHeader = styled.div`
    padding: 16px 12px 25px 16px;
    border-bottom: 1px solid ${colors.secondary};
`;
export const HeaderLine = styled.div`
    color: #333232;
    font-family: "Nunito Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    @media (max-width: 1600px) {
        font-size: 18px;
    }
`;
export const ListActions = styled.div`
    display: flex;
    gap: 10px;
    position: relative;
`;
export const ProgramSections = styled.div`
 overflow-y: scroll;
overflow-x: hidden;
height: inherit;
${Scrollable};
height: calc(100vh - 220px);
 ::-webkit-scrollbar {
        background-color:${colors.secondary};
        border:solid 1px ${colors.darkBackground};
        width:5px;
        border-radius:0px 0px 10px 0px;
      }
      ${'' /* @media(min-width:1200px) {
        max-height:63vh;
    }
      @media(min-width:991px) {
        max-height:63vh;
        
    } */}
    @media(max-width:767px) {
        height: calc(100vh - 350px);
    }
    @media (max-width: 1600px) {
        height: calc(100vh - 185px);
    }
`;
export const CrossButtonContainer= styled.div`
    display: none;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px 14px 7px 0px;
    
`
export const CrossButtonContainerSpan= styled.span`
    width: 20px;
    height: 4px;
    background-color: #44449B;
    display: block;
    transform: rotate(45deg) translate(5px,3px);
    border-radius: 5px;
    &:last-child {
        transform: rotate(-45deg) translate(0px,2px);
    }
    @media(min-width:991px) {
        display:none
    }
`
export const ButtonWraper= styled.div`
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: end;
    gap:10px
   
  
`
export const JoinPorgramContainer= styled.div`
width: 80vh;
@media (max-width:767px){
width: 100%;
}

`;


export const Button = styled.div`
    width: 26px;
    height: 26px;
    border-radius: 5px;
    background: #F4F4F4;
    cursor: pointer;
`
export const JoinButton = styled.div`
    border-radius: 5px;
    background: #F4F4F4;
    cursor: pointer;
    height: 26px;
    padding: 0 12px;
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display:flex;
    justify-content: center;
    align-items: center;
`

export const Image = styled.img`
`

//
export const SampleFolderOuter = styled.div`
    margin: 12px 16px 0;
    position: relative;
`;

export const FolderDropDown = styled.div`
    display: flex;
    width: 230px;
    padding: 7px 6px;
    align-items: center;
    justify-content: space-between;
    border-radius: 3px;
    background: #FAFAFC;
    @media (max-width:1600px) {
        width: 210px;
    }
`;

export const Text = styled.h3`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
`;

export const FolderOptions = styled.div`
    align-items: center;
    display: flex;
    gap: 10px;
`;

export const EditDeleteOption = styled.img`
    cursor: pointer
`;

export const OpenCloseOption = styled.img`
    cursor: pointer
`;