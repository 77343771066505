import styled from "styled-components";
import { Scrollable } from "../../../../../common/styles";
import colors from "../../../../../config/colors";

export const TaskMembersContainer = styled.div`
    width: 100%;
    max-height:70vh;
    ${Scrollable}
`;

export const MemberRow = styled.div`
    padding:10px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    border: 1px solid ${colors.secondary};
    margin:0 20px;
`;
export const MemberInfo = styled.div`
    display: flex;
    align-items: center;
    width:210px;
    @media ( max-width: 767px ) {
        width: 340px;
    }
`;
export const MemberImage = styled.img`
    height:40px;
    width:40px;
    object-fit:cover;
    border-radius:50%;
    opacity: ${props=>props.disabled && '0.5'}; 
`;
export const MemberName = styled.div`
    color:${props=>props.color ? colors.primary :''};
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left:10px;
`;
export const Span = styled.span`
    display:block
`;
export const MemberActions = styled.div`
    display:flex;
    align-items:center;
    margin:0 20px;
`;
export const Container = styled.div`
  position: relative;
  display: inline-block;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  color: red;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddEditModalHeader = styled.div`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-bottom:1px solid #EDEDED;
    padding:20px;
        span > div {
            padding:0;
            border:0;
        }
`;

export const HeaderRow = styled.div`        
    display: inline-flex;
    justify-content:space-between;
    align-items: flex-start;
    padding: 9px 9px 9px 9px;
    border-radius: 5px 5px 0px 0px;
    border: 1px solid #EDEDED;
    border-bottom:0;
    background: #FBFBFB;
    margin:0 20px;
    box-sizing:border-box;
    width:calc( 100% - 40px );
`;

export const HeaderCell = styled.div`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
        span {
            display:inline-block;
            width:280px;
            @media ( max-width: 767px ) {
                width:190px; 
            }
        }
        &:first-child {
            width:230px;
            @media ( max-width:992px ) {
                width:210px;
            }
        }
        &:last-child {
            margin:0 auto 0 125px;
        }
        @media( max-width:1200px ) {
            &:last-child {
                margin:0 auto 0 155px;
            }
        }
        @media( max-width:800px ) {
            &:last-child {
                margin:0 auto 0 40px;
            }
        }
        @media( max-width:767px ) {
            &:last-child {
                margin:0 auto 0 -10px;
            }
        }
`;

export const ModalInputField = styled.div`
    position:relative;
    margin:20px;
    svg {
        position:absolute;
        left:10px;
        top:10px;
        z-index:10;
    }
    input {
        padding-left:50px;
    }
`;

export const StyledActions = styled.div`
    margin:0 20px;
`;

export const ModalButton = styled.div`
    display:flex;
    flex-wrap:wrap;
    div {
        color: #4C4B9A;
        font-family: "Nunito Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;

export const MemberDate = styled.div`
    width:280px;
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align:left;
    display:flex;
    justify-content:flex-start;
        span {
            display:inline-block;
            width:100%;
        }
`;