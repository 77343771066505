import React, { useState } from "react";
import Icon from "../Icon";
import {
  SortHead,
  SortContainer,
  SortOptions,
  SortButtons,
  Button,
} from "./style";

const Index = ({ sortData }) => {
  const [openSort, setOpenSort] = useState(false);
  return (
    <SortContainer>
      <SortHead onClick={() => setOpenSort(!openSort)}>
        Sort
        <Icon type={"sort"} />
      </SortHead>
      {openSort && (
        <SortOptions>
          <SortButtons>
            <Button
              new={true}
              onClick={() => {
                sortData("newest");
                setOpenSort(false);
              }}
            >
              <Icon type={"up-arrow"} />
              Newest
            </Button>
            <Button
              onClick={() => {
                sortData("oldest");
                setOpenSort(false);
              }}
            >
              <Icon type={"down-arrow"} />
              Oldest
            </Button>
          </SortButtons>
        </SortOptions>
      )}
    </SortContainer>
  );
};

export default Index;
