import styled from "styled-components";
import colors from "../../../../../config/colors";
import Icon from "../../../../../common/Icon";

export const ApplicationModalContainer = styled.div``;
export const ApplicationFormContainer = styled.div`
    margin-top:10px;
    border-top:solid 1px ${colors.secondary};
    padding-top:10px;
`;

export const DropSelect = styled.select`
    background: rgba(68, 68, 155, 0.10);
    border-radius: 5px;
    padding: 5px 16px 5px 8px; 
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    appearance: none;
    -webkit-appearance: none; 
    -moz-appearance: none;
    min-width: 87px;
    max-width: 150px;
    position: relative;
    z-index: 3;
    &:focus{
        outline: none !important;
    }
`;

export const MultipleChoiceOuter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
`;

export const MultipleChoiceLabel = styled.label`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
`;

export const MultipleChoiceInput = styled.input`
    margin: 0 6px 0 0;
    width: 24px;
    height: 18px;
`;

export const DropDownOuter = styled.div`
    display: flex;
    align-items: center;
`;

export const DropDownIcon = styled.div`
    position: relative;
    left: -25px;
    top: 2px;
`;
