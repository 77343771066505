import React, { useState } from "react";
import Pills from "../../../../common/Pills";
import {
  ButtonWraper,
  MeetingDetailContainer,
  MeetingDetailHead,
  ButtonText,
  BackButton,
  MeetingExport,
  MeetingExportText,
  MeetingExportContainer,
  ExportOptions,
  Option,
  Buttons,
} from "../style";
import { PillContainer } from "../../Programs/ProgramContent/ProgramParticipants/styles";
import Button from "../../../../common/Button";
import ActivityContent from "../ActivityContent";
import AddParticipants from "../../Programs/popups/AddParticipants";
import Icon from "../../../../common/Icon";
import { ExportCSV } from "../../../../common/utils";

export default function Meetings({
  currentPage,
  setCurrentPage,
  setMeetingDetail,
}) {
  const [searchParticipant, setSearchParticipant] = useState("");
  const [isAddParticipantsOpen, setIsAddParticipantsOpen] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [exportCurrentPageData, setExportCurrentPageData] = useState([]);
  const isCheck = currentPage === "UPCOMING" || currentPage === "COMPLETE";
  const labels = [
    "Requested By",
    "Purpose",
    "Days Pending",
    "Date",
    ...(isCheck ? ["Accepted By"] : []),
  ];
  const dataKeys = [
    "requestedBy",
    "purpose",
    "days",
    "date",
    ...(isCheck ? ["acceptedBy"] : []),
  ];
  const handleExport = (type) => {
    if (type === "all") {
      ExportCSV({
        labels,
        dataKeys,
        tableData: exportData,
        fileName: "Export_All",
      });
    } else if (type === "current") {
      ExportCSV({
        labels,
        dataKeys,
        tableData: exportCurrentPageData,
        fileName: "Export_Current_Page",
      });
    }
  };

  return (
    <>
      <MeetingDetailContainer>
        <MeetingDetailHead>
          <BackButton
            onClick={() => {
              setMeetingDetail(true);
            }}
          >
            <Icon type="back" />
            <ButtonText>Back</ButtonText>
          </BackButton>
          <MeetingExportContainer>
            <MeetingExport onClick={() => setOpenExport(!openExport)}>
              <Icon type={"export"} />
              <MeetingExportText>Export</MeetingExportText>
              <Icon type={"chevron-down"} />
            </MeetingExport>
            {openExport && (
              <ExportOptions>
                <Option onClick={() => handleExport("all")}>Export All</Option>
                <Option onClick={() => handleExport("current")}>
                  Export Current Page
                </Option>
                {/* <Buttons>
                <Button variant={"secondary"} text={"Close"} isNotRound={true} />
                <Button variant={"Save"} text={"Export"} isNotRound={true} />
              </Buttons> */}
              </ExportOptions>
            )}
          </MeetingExportContainer>
        </MeetingDetailHead>
        <PillContainer reverse={true} meetings={true}>
          <Pills
            meetings={true}
            value={currentPage}
            setValue={setCurrentPage}
            options={[
              {
                label: "New",
                value: "UPCOMING",
              },
              {
                label: "Completed",
                value: "COMPLETE",
              },
              {
                label: "Unaccepted",
                value: "PAST",
              },
            ]}
          />
          <ButtonWraper>
            <Button
              icon="add"
              text="Add Participants"
              onClick={() => {
                setIsAddParticipantsOpen(true);
              }}
            />
          </ButtonWraper>
        </PillContainer>
        <ActivityContent
          currentPage={currentPage}
          searchParticipant={searchParticipant}
          setSearchParticipant={setSearchParticipant}
          setExportData={setExportData}
          setExportCurrentPageData={setExportCurrentPageData}
        />
      </MeetingDetailContainer>
      {isAddParticipantsOpen && (
        <AddParticipants closeModal={() => setIsAddParticipantsOpen(false)} />
      )}
    </>
  );
}
