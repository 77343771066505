import styled from "styled-components";

export const CheckboxGroupContainer = styled.div``;

export const CheckboxContainer = styled.div`
    margin: 10px 0px;
`;

export const CheckboxLabel = styled.div`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left:10px;
`;