import React, { useEffect, useRef } from "react";
import {
  ImagePreviews,
  NoprogramContainer,
  RequestContent,
  RequestListWrapper,
  RequestsDetailsComment,
  RequestsDetailsWrapper,
  RequestsKeyHeader,
  RequestsKeyHeaders,
  Spacer,
  RequestedHeaderLine,
  CalendarContainer,
  AvailabilityContainer,
  DateWrapper,
  CalendarOuterContainer,
  CalendarLeft,
  PaginationSpan,
  PreviousButton,
  NextButton,
  PaginationButtons,
  CurrentPage,
  AngleSpan,
  Pagination,
  ResultsLabel,
  ResultsSelect,
  ProgramInBoundsContainer
} from "./styles";
import callAPI from "../../../../../api/apiCaller";
import { Context } from "../../../../../Store";
import Button from "../../../../../common/Button";
import { RequestButtonContainer } from "../NewRequest/styles";
import {
  convertToLinks,
  dateToUnixTimeStamp,
  unixStampToDate,
} from "../../../../../common/utils";
import { useParams } from "react-router-dom";
import Loading from "../../../../../common/Loading";
import { ProgramLoaderContainer } from "../ProgramOutBounds/style";
import NoProgramImage from "../home-program.svg";
import { RequestCount } from "../../../../../Store/ProgramMeetingCount/actions";
import { setProgramList } from "../../../../../Store/ProgramsList/actions";
import Icon from "../../../../../common/Icon";
import Calendar from "../../../../../common/Calender";
import MeetingTable from "../../../../../common/MeetingsTable";
import Sort from "../../../../../common/Sort";

function ProgramInBounds({
  isArchive,
  currentTab,
  allProgramMeetingStatistics,
  specificProgramMeetingsStatistics,
  refresh,
}) {
  const params = useParams();
  const calendarRef = useRef(null);
  const { state, dispatch } = React.useContext(Context);
  const [openNetworkPrograms, setOpenNetworkProgramsRequests] =
    React.useState(null);
  const [isProcessing, setIsProcessing] = React.useState(null);
  const [isUnArchive, setIsUnArchive] = React.useState(false);
  const recipientId = params.connectionId;
  const [openCalendarId, setOpenCalendarId] = React.useState(null);
  const [displayCount, setDisplayCount] = React.useState(0);
  const [displayedEvents, setDisplayedEvents] = React.useState([]);
  const [hasNext, setHasNext] = React.useState(true);
  const [hasPrevious, setHasPrevious] = React.useState(false);
  const [showAll, setShowAll] = React.useState(false);
  const [sortedData, setSortedData] = React.useState(null);


  const handlePrevious = () => {
    calendarRef.current?.previousRange();
  };

  const handleNext = () => {
    calendarRef.current?.nextRange();
  };

  const handleCalendarToggle = (id) => {
    setOpenCalendarId((prev) => (prev === id ? null : id));
  };

  const programs = () => {
    callAPI(
      dispatch,
      isArchive
        ? "getOpenNetworkArchiveRequests"
        : recipientId
        ? "getOpenNetworkRequestOneOnOne"
        : "getOpenNetworkRequest",
      { id: params.selectedProgram, rid: recipientId }
    ).then((res) => {
      setOpenNetworkProgramsRequests([]);
      setSortedData([]);
      if (res.isResponseOk) {
        setOpenNetworkProgramsRequests(res.data);
        setSortedData(res.data);
      }
      if (!isArchive) {
        dispatch(RequestCount(res.data.length));
      }
    });
  };

  function getPrograms() {
    callAPI(dispatch, "fetchPrograms").then((programs) => {
      dispatch(setProgramList(programs.data));
    });
  }
  useEffect(() => {
    callAPI(
      dispatch,
      recipientId ? "getOpenNetworkRequestOneOnOne" : "getOpenNetworkRequest",
      { id: params.selectedProgram, rid: recipientId }
    ).then((res) => {
      dispatch(RequestCount(res.data.length));
    });
  }, [isUnArchive]);

  function acceptMeeting(meetingId, date) {
    console.log(meetingId, date, "sdsda");
    callAPI(dispatch, "acceptMeetingRequest", {
      id: meetingId,
      meetingScheduleDate: date,
      status: "ACCEPT",
    }).then((res) => {
      programs();
      if (params.selectedProgram) {
        specificProgramMeetingsStatistics();
      } else {
        allProgramMeetingStatistics();
      }
    });
  }

  React.useEffect(() => {
    programs();
    // to render meeting count
    if (params.selectedProgram) {
      specificProgramMeetingsStatistics();
    } else {
      allProgramMeetingStatistics();
    }

    return () => {
      setOpenNetworkProgramsRequests(null);
    };
  }, [currentTab, refresh]);

  const today = new Date();
  const [isValid, setIsValid] = React.useState({
    av1: null,
  });

  if (openNetworkPrograms === null) {
    return (
      <ProgramLoaderContainer>
        {" "}
        <Loading loadingItem="Requests Meeting" />{" "}
      </ProgramLoaderContainer>
    );
  }

  function handleRequest(request) {
    setIsProcessing(true);
    let payload = {};
    if (currentTab === "Requests") {
      payload = {
        meetingId: request.meetingId,
        programId: request.programId,
        date: request.date,
        date2: request.date2,
        date3: request.date3,
        status: "ARCHIVE",
      };
    } else {
      payload = { id: request.userMeetings[0].id };
    }
    callAPI(dispatch, !isArchive ? "handleRequests" : "handleRequestsUndo", {
      ...payload,
    }).then((res) => {
      // to render meeting count
      setIsProcessing(false);
      programs();
      getPrograms();
      if (params.selectedProgram) {
        specificProgramMeetingsStatistics();
      } else {
        allProgramMeetingStatistics();
      }
    });
  }

  if (openNetworkPrograms.length === 0) {
    return (
      <NoprogramContainer>
        <RequestsKeyHeaders size={13} widthSize={300} marginSpace={30}>
          You do not currently have any{" "}
          {currentTab === "Archived" && "Archived Meetings"}{" "}
          {currentTab === "Requests" && "Meeting Requests"}.
        </RequestsKeyHeaders>
        <ImagePreviews src={NoProgramImage} />
      </NoprogramContainer>
    );
  }
  const sortData = (order) => {
    const sorted = [...openNetworkPrograms].sort((a, b) => {
      if (order === "newest") {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
      return new Date(a.createdAt) - new Date(b.createdAt);
    });
    setSortedData(sorted);
  };

  return (
    <>
      <Sort sortData={sortData} />
      <ProgramInBoundsContainer>
      {sortedData.map((request, index) => {
        const eventsData = request.dates?.map((timestamp) => {
          const date = new Date(parseInt(timestamp, 10) * 1000);
          return {
            start: date,
            end: new Date(date.getTime() + 60 * 60 * 1000),
            allDay: false,
          };
        });
        const visibleDates = showAll
          ? request.dates
          : request.dates.slice(0, 5);
        return (
          <RequestListWrapper
            id={index}
            isMeetingTab={request?.program?.programType === "OPEN_NETWORK"}
          >
            <RequestedHeaderLine>
              {isArchive
                ? request?.reasonForMeeting
                : request?.meeting?.reasonForMeeting}
            </RequestedHeaderLine>
            <RequestsDetailsWrapper>
              <RequestsKeyHeader>
                <Icon type={"meeting-reason"} /> Requested By:{" "}
              </RequestsKeyHeader>{" "}
              <RequestContent>
                {" "}
                {request?.user?.firstName} {request?.user?.lastName}
              </RequestContent>
            </RequestsDetailsWrapper>

            <RequestsDetailsWrapper>
              <RequestsKeyHeader>
                <Icon type={"program"} /> Program:{" "}
              </RequestsKeyHeader>{" "}
              <RequestContent>
                {" "}
                {isArchive ? request?.title : request?.program?.title}
              </RequestContent>
            </RequestsDetailsWrapper>

            <RequestsDetailsWrapper>
              <RequestsKeyHeader>
                <Icon type={"location"} /> Location:{" "}
              </RequestsKeyHeader>{" "}
              <RequestContent>
                {" "}
                {isArchive ? request?.location : request?.meeting?.location}
              </RequestContent>
            </RequestsDetailsWrapper>
            {request?.program?.programType === "OPEN_NETWORK" && (
              <RequestsDetailsWrapper>
                <RequestsKeyHeader>
                  <Icon type={"link"} /> Meeting Link:{" "}
                </RequestsKeyHeader>{" "}
                <RequestContent>
                  {" "}
                  {isArchive ? (
                    request?.meetingLink ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: convertToLinks(request.meetingLink),
                        }}
                      />
                    ) : (
                      "-"
                    )
                  ) : request?.meeting?.meetingLink ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: convertToLinks(request.meeting.meetingLink),
                      }}
                    />
                  ) : (
                    "-"
                  )}
                </RequestContent>
              </RequestsDetailsWrapper>
            )}
            <RequestsDetailsWrapper meeting={true}>
              <RequestsKeyHeader> Availability: </RequestsKeyHeader>
              <Spacer />
            </RequestsDetailsWrapper>
            <DateWrapper>
              {visibleDates?.map((date) => {
                return (
                  <Button
                    text={unixStampToDate(date)}
                    disabled={dateToUnixTimeStamp(today) > date ? true : false}
                    onClick={() => handleCalendarToggle(index)}
                    icon={""}
                  />
                );
              })}
              {request.dates.length > 5 && (
                <Button
                  text={showAll ? `View Less` : `View More ${request.dates.length - visibleDates.length}`}
                  onClick={() => setShowAll(!showAll)}
                />
              )}
              {openCalendarId === index && (
                <CalendarOuterContainer>
                  <CalendarContainer>
                    <CalendarLeft>
                      <Calendar
                        ref={calendarRef}
                        eventData={eventsData}
                        setRangeCount={setDisplayCount}
                        setDisplayedEvents={setDisplayedEvents}
                        setHasNext={setHasNext}
                        setHasPrevious={setHasPrevious}
                      />
                      <Pagination>
                        <div>
                          <ResultsLabel>
                            Displaying
                            <PaginationSpan leftSpace={true}>
                              {displayCount} out of {request.dates.length}{" "}
                              requests
                            </PaginationSpan>
                          </ResultsLabel>
                        </div>
                        <PaginationButtons>
                          <PaginationSpan>
                            <CurrentPage>{displayCount}</CurrentPage> out of{" "}
                            {request.dates.length}
                          </PaginationSpan>
                          <PreviousButton
                            onClick={handlePrevious}
                            disabled={!hasPrevious}
                          >
                            <AngleSpan>{"<"}</AngleSpan> Previous
                          </PreviousButton>

                          <NextButton onClick={handleNext} disabled={!hasNext}>
                            Next <AngleSpan next={true}>{">"}</AngleSpan>
                          </NextButton>
                        </PaginationButtons>
                      </Pagination>
                    </CalendarLeft>
                    <AvailabilityContainer>
                      <MeetingTable
                        eventData={displayedEvents}
                        acceptMeeting={acceptMeeting}
                        isArchive={isArchive}
                        requestId={request.id}
                        meetingId={request.meetingId}
                      />
                    </AvailabilityContainer>
                  </CalendarContainer>
                </CalendarOuterContainer>
              )}
            </DateWrapper>
            <RequestsDetailsComment>
              <RequestsKeyHeader>
                <Icon type={"additional-info"} /> Addition Comments:{" "}
              </RequestsKeyHeader>
              {/* <RequestContent>{isArchive ? request?.additionalInfo: request?.meeting?.additionalInfo ? request?.meeting?.additionalInfo :'-'}</RequestContent> */}
              <RequestContent>
                {isArchive ? (
                  request?.additionalInfo ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: convertToLinks(request.additionalInfo),
                      }}
                    />
                  ) : (
                    "-"
                  )
                ) : request?.meeting?.additionalInfo ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: convertToLinks(request.meeting.additionalInfo),
                    }}
                  />
                ) : (
                  "-"
                )}
              </RequestContent>
            </RequestsDetailsComment>
            {request?.program?.programType !== "1_1_NETWORK" && (
              <RequestButtonContainer>
                <Button
                  text={isArchive ? "Undo Archive" : "Archive Request"}
                  variant="secondary"
                  icon={isProcessing ? "loading" : ""}
                  isNotRound={true}
                  onClick={() => {
                    handleRequest(request);
                    if (isArchive) {
                      setIsUnArchive(!isUnArchive);
                    }
                  }}
                />
              </RequestButtonContainer>
            )}
          </RequestListWrapper>
        );
      })}
      </ProgramInBoundsContainer>
    </>
  );
}

export default ProgramInBounds;
