import styled from "styled-components";
import colors from "../../../../../config/colors";
import { Scrollable } from "../../../../../common/styles";

export const UserInfoContainer = styled.div`
    height:calc(100vh - 700px);
    width:100%;
    ${Scrollable}
    @media( max-width: 1500px ) {
        height:calc(100vh - 370px);
    }
`;

export const SubHeading = styled.div`
`;

export const Flex = styled.div`
    display: flex;
    gap: 10px;
`;

export const MeetingInfoLabel = styled.div`
    display: flex;
    flex-direction: ${props => props.flexColumn && "column"};
    color: #A6A6A6;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 10px;
    margin: 16px 0;
`;