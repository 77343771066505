import styled from "styled-components";


export const Button = styled.button`
    padding: 12px 12px 12px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #757575;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 6px;
    border-bottom: ${props => props.new ? "1px solid #E2EBF3": "0"};
    background: #FFF;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    width: 100%;
    cursor: pointer;
`;

export const SortContainer = styled.div`
    max-width: 75px;
    margin-left: auto;
    position: relative;
    margin-top: 22px;
    margin-bottom: 5px; 
`;
export const SortOptions = styled.div`
    width: 94px;
    height: 86px;
    border-radius: 3px;
    box-shadow: 0px 4px 50px 0px rgba(151, 140, 140, 0.20);
    position: absolute;
    top: 35px;
    right: 10px;
    z-index: 10;
`;
export const SortButtons = styled.div`
  
`;
export const SortHead = styled.button`
    color: #4F449B;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    border: 1px solid #4F449B; 
    height: 40px;
    cursor: pointer;
    width: 100%;
    background: #FFF;
`;
