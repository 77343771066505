import styled from 'styled-components';


export const DatePickerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    label{
        position: relative;
        svg{
            position: absolute;
            top: 10px;
            left: 5px;
            z-index: 5;
        }
    }
    input {
        width: 80px;
        height: 36px;
        padding: 0 4px 0 30px;
        border-radius: 5px;
        border: 1px solid #EDEDED;
        color: #B3B3B3;
        font-family: "Nunito Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;
