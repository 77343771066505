import styled from "styled-components";
import {
  Scrollable,
  borderRadius,
  panelSpacing,
} from "../../../../../common/styles";
import colors from "../../../../../config/colors";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

export const ApplicationEditorContainer = styled.div`
    margin-top: 16px;
`;
export const FormSection = styled.div`
    border-radius:${borderRadius}px;
    margin-top: 22px;
`;
export const QuestionSection = styled.div`
border-bottom:solid 1px ${colors.secondary};
padding:10px 0px;
`;
export const QuestionNumber = styled.div`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
export const QuestionType = styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
`;
export const QuestionTypeLabel = styled.div`
    font-size:14px;
`;
export const AddNewQuestionContainer = styled.div`
    margin-top: 40px;
    display:flex;
    align-items: center;
    justify-content: center;
`;

export const TabTile = styled(Tab)`
    display: flex;
    height: 34px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: ${(props) => (props.isActive ? "#FFF" : "transparent")};
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
`;
export const TabsTile = styled(Tabs)`

`;
export const TabLists = styled(TabList)`
    border-radius: 5px;
    background: rgba(68, 68, 155, 0.10);
    display: flex;
    padding: 5px 6px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    list-style-type:none;
    margin: 0 auto;
    max-width: ${props => props.isMember ? "156px" : "310px"};
`;
export const TabPanelContent = styled(TabPanel)`
`;

export const CrossButton = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 18.5px;
    background: rgba(219, 219, 219, 0.30);
    cursor: pointer;
`;

export const QuestionHeader = styled.div`
    display: flex;
    gap: 9px;
    align-items: center;
    margin-bottom: 15px;
`;

export const QuestionDropdownOuter = styled.div`
`;



export const DropdownOptionsOuter = styled.div`
`;

export const DropdownOptions = styled.div`
    display:flex;
    max-width:54%;
    width:100%;
    margin-top: 12px;
`;

export const DropdownDelete = styled.button`
    color: #393CF6;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: none;
    border: none;
    cursor: pointer;
`;

export const DropdownAdd = styled.button`
    color: #393CF6;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: none;
    border: none;
    cursor: pointer;
    margin-top: 12px;
`;

export const MultipleChoiceOptions = styled.div`
    display:flex;
    align-items: center;
    max-width: 58%;
    width:100%;
    margin-top: 12px;
`;
export const MultipleChoiceRadio = styled.input`
    width: 24px;
    height: 24px;
    border-radius: 5px;
    border: 1px solid #D1D1D1;
    margin: 0 10px 0 0;
`;
export const MultipleChoiceDelete = styled.button`
    color: #393CF6;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: none;
    border: none;
    cursor: pointer;
`;
export const MultipleChoiceAdd = styled.button`
    color: #393CF6;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: none;
    border: none;
    cursor: pointer;
    margin-top: 12px;
`;

export const CheckBoxOptions = styled.div`
    display:flex;
    align-items: center;
    max-width: 58%;
    width:100%;
    margin-top: 12px;
`;
export const CheckBoxOptionsCheckbox = styled.input`
    width: 24px;
    height: 24px;
    border-radius: 5px;
    border: 1px solid #D1D1D1;
    margin: 0 10px 0 0;
`;
export const CheckBoxOptionsDelete = styled.button`
    color: #393CF6;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: none;
    border: none;
    cursor: pointer;
`;
export const CheckBoxOptionsAdd = styled.button`
    color: #393CF6;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: none;
    border: none;
    cursor: pointer;
    margin-top: 12px;
`;
