import React, { useState, useContext } from "react";
import MeetingImage from "./MeetingImage";
import StatusSection from "./StatusSection";
import MeetingDetails from "./MeetingDetails";
import ActionButtons from "./ActionButtons";
import MeetingModal from "./MeetingModal";
import { unixStampToDate } from "../../../../../../common/utils";
import { getUserInfo } from "../../../../../../Store/User/accessors";
import { Context } from "../../../../../../Store";
import {
  MeetingOuterContainer,
  MeetingInnerContainer,
  Info,
  MeetingInformation,
} from "./style";

const Meetings = ({
  openNetworkProgramsMeetings,
  params,
  navigate,
  setIsDeleteModal,
  setSelectedMeeting,
  setShowForm,
  handleAddToCalender,
  handleCancelRequest,
  isUpcoming,
  isPast,
  reassignMentee,
  disableButtonId,
  isLoading
}) => {
  const { state } = useContext(Context);
  const [isDeleteModal, setIsDeleteModalState] = useState(false);
  const [isCancelRequest, setIsCancelRequestState] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isCalenderDropdownActive, setIsCalenderDropdownActive] =
    useState(false);
  const [isDeleteRequest, setIsDeleteRequest] = useState(false);

  const deleteProgram = () => {
    setIsDeleteModalState(false);
  };

  return (
    <MeetingOuterContainer>
      {openNetworkProgramsMeetings?.map((item, i) => {
        const isMyMente = item?.Mentor?.id === item?.user?.id;
        const isMyMentor = item?.Mentor?.id !== item?.user?.id;
        const iamMentor = item?.Mentor?.id === getUserInfo(state).id;
        const programOneOnOne = item?.program?.programType === "1_1_NETWORK";
        const isNotMyMentor = item?.Mentor?.id !== item?.user?.id;
        return (
          <MeetingInnerContainer key={i}>
            <MeetingInformation>
              <MeetingImage
                item={item}
                iamMentor={iamMentor}
                programOneOnOne={programOneOnOne}
                params={params}
                isTeam={item?.program?.programType === "TEAM"}
              />
              <Info>
                <StatusSection
                  item={item}
                  isMyMente={isMyMente}
                  isMyMentor={isMyMentor}
                  iamMentor={iamMentor}
                  programOneOnOne={programOneOnOne}
                  params={params}
                />
                <MeetingDetails
                  item={item}
                  isTeam={item?.program?.programType === "TEAM"}
                  unixStampToDate={unixStampToDate}
                />
              </Info>
            </MeetingInformation>

            <ActionButtons
              item={item}
              navigate={navigate}
              iamMentor={iamMentor}
              handleAddToCalender={handleAddToCalender}
              setShowForm={setShowForm}
              setIsDeleteModal={setIsDeleteModal}
              setSelectedMeeting={setSelectedMeeting}
              selectedItemId={selectedItemId}
              isCalenderDropdownActive={isCalenderDropdownActive}
              setIsCalenderDropdownActive={setIsCalenderDropdownActive}
              isDeleteRequest={isDeleteRequest}
              reassignMentee={reassignMentee}
              disableButtonId={disableButtonId}
              isLoading={isLoading}
              isUpcoming={isUpcoming}
            />
            {isDeleteModal && (
              <MeetingModal
                title="Are you sure you want to proceed?"
                onClose={() => setIsDeleteModalState(false)}
                isDelete={true}
                deleteProgram={deleteProgram}
              />
            )}
            {isCancelRequest && (
              <MeetingModal
                title="Are you sure you want to Cancel this Meeting?"
                onClose={() => setIsCancelRequestState(false)}
                cancelMeeting={handleCancelRequest}
              />
            )}
          </MeetingInnerContainer>
        );
      })}
    </MeetingOuterContainer>
  );
};

export default Meetings;
