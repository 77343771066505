import React, { useState, useEffect, useContext } from "react";
import {
  ProgramContainer,
  Body,
  Avatar,
  BookingHeading,
  NoprogramContainer,
  RequestsKeyHeaders,
  TableContainerOuter,
  AvatarInfo,
  MeetingsHead,
  FilterOption,
  FilterText,
  SearchBoxFilterContainer,
  DropIcon,
  DropdownItems,
  DropdownButton,
  RequestedByContainer,
  FilterOptions,
  FilterHeadText,
  FilterBy,
  FilterLabel,
  FilterLabelText,
  FilterClear,
  FilterButtons,
  ResetButton,
  ApplyButton,
  DateInputs,
  SearchParticipantsWrapper,
  TableContainer,
} from "./styles";
import callAPI from "../../../../api/apiCaller";
import { Context } from "../../../../Store";
import Button from "../../../../common/Button";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../../../common/Loading";
import { ButtonSpacer, SectionActions } from "../../../../common/Button/styles";
import Labelize from "../../../../common/Labelize";
import {
  NumberOfDaysBetweenDates,
  NumberOfDaysBetweenDatesAndCurrentDate,
  dateToUnixTimeStamp,
  formatDateForMeetings,
} from "../../../../common/utils";
import PaginatedTable from "../../../../common/Table/PaginatedTable";
import { TableActions } from "../../../../common/Table/styles";
import { Info } from "../../Programs/ProgramContent/ProgramParticipants/ActiveParticipants/styles";
import { useParams } from "react-router-dom";
import Modal from "../../../../common/Modal";
import MeetingInfo from "../../Programs/popups/MeetingInfo";
import Icon from "../../../../common/Icon";
import TextInput from "../../../../common/TextInput";
import Checkbox from "../../../../common/Checkbox";
import EnhancedDateRangePicker from "../../../../common/DatePicker";

function ActivityContent({
  currentPage,
  searchParticipant,
  setSearchParticipant,
  setExportData,
  setExportCurrentPageData,
}) {
  const params = useParams();
  const [requests, setRequests] = useState(null);
  const { dispatch, state } = useContext(Context);
  const navigate = useNavigate();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isDeleteRequest, setIsDeleteRequest] = useState(false);
  const [isViewMore, setIsviewMore] = useState(false);
  const [program, setProgram] = useState(false);
  const [actionButtons, setActionButtons] = useState(null);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [filterOptions, setFilterOptions] = useState(false);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [requestedBy, setRequestedBy] = useState("");
  const [acceptedBy, setAcceptedBy] = useState("");
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const handleCheckboxChange = (rowId) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  useEffect(() => {
    callAPI(
      dispatch,
      currentPage === "UPCOMING"
        ? "ProgramNewRequestForManager"
        : currentPage === "COMPLETE"
        ? "ProgramCompleteRequestForManager"
        : "ProgramUnFullFiledRequestForManager",
      { id: params.selectedProgram }
    ).then((res) => {
      if (res.isResponseOk) {
        setRequests(res.data);
        setFilteredRequests(res.data);
        const preparedExportData = res.data.map((row) => {
          const days = row?.meetingScheduleDate
            ? NumberOfDaysBetweenDates([
                row?.acceptedOn,
                dateToUnixTimeStamp(row?.createdAt),
              ])
            : NumberOfDaysBetweenDatesAndCurrentDate([
                dateToUnixTimeStamp(row?.createdAt),
              ]);

          return {
            requestedBy: `${row.user.firstName} ${row.user.lastName}`,
            purpose: row.reasonForMeeting,
            days: days >= 0 ? days : "-",
            date: formatDateForMeetings(row.createdAt),
            acceptedBy: `${row.Mentor?.firstName} ${row?.Mentor?.lastName}`,
          };
        });
        setExportData(preparedExportData);
        setExportCurrentPageData(preparedExportData);
      } else {
        setRequests([]);
        setFilteredRequests([]);
      }
    });
  }, [currentPage]);

  let url =
    window.location.protocol +
    "//" +
    window.location.host +
    "/programs/" +
    program?.id +
    "/home";
  const applyFilters = () => {
    const filtered = requests.filter((item) => {
      const matchesRequestedBy =
        requestedBy === "" ||
        item?.user?.firstName
          ?.toLowerCase()
          .includes(requestedBy.toLowerCase()) ||
        item?.user?.lastName?.toLowerCase().includes(requestedBy.toLowerCase());

      const matchesAcceptedBy =
        acceptedBy === "" ||
        item?.Mentor?.firstName
          ?.toLowerCase()
          .includes(acceptedBy.toLowerCase()) ||
        item?.Mentor?.lastName
          ?.toLowerCase()
          .includes(acceptedBy.toLowerCase());

      const matchesDateRange =
        (!dateRange.from && !dateRange.to) ||
        (new Date(item.createdAt).getTime() >=
          new Date(dateRange.from).getTime() &&
          new Date(item.createdAt).getTime() <=
            new Date(dateRange.to).getTime());
      return matchesRequestedBy && matchesAcceptedBy && matchesDateRange;
    });

    setFilteredRequests(filtered);
  };

  useEffect(() => {
    if (!searchParticipant) {
      setFilteredRequests(requests);
      return;
    }

    const filterParticipant = requests?.filter((item) => {
      const firstName = item?.user?.firstName?.toLowerCase() || "";
      const lastName = item?.user?.lastName?.toLowerCase() || "";
      return (
        firstName.includes(searchParticipant.toLowerCase()) ||
        lastName.includes(searchParticipant.toLowerCase())
      );
    });

    setFilteredRequests(filterParticipant);
  }, [searchParticipant, requests]);

  function removeItemFromArray(itemToRemove) {
    const updatedArray = requests.filter((item) => item.id !== itemToRemove);
    setRequests(updatedArray);
  }

  function deleteProgram(meeting) {
    setIsDeleteRequest(true);
    callAPI(dispatch, "deleteMeetingRequest", {
      rid: meeting.id,
      id: meeting.id,
      tid: meeting.programId,
    }).then(() => {
      setIsDeleteRequest(false);
      removeItemFromArray(meeting.id);
      setIsDeleteModal(false);
    });
  }

  const programInformation = () => {
    callAPI(dispatch, "getProgramInformation", {
      id: params.selectedProgram,
    }).then((info) => {
      setProgram(info.data);
    });
  };

  React.useEffect(() => {
    programInformation();
  }, []);

  const resetFilters = () => {
    setRequestedBy("");
    setAcceptedBy("");
    setDateRange({ from: null, to: null });
    setFilteredRequests(requests);
  };

  if (requests === null) {
    return (
      <ProgramContainer>
        <Loading loadingItem="Activity Details" />
      </ProgramContainer>
    );
  }

  const closeModal = () => {
    setIsviewMore(false);
  };

  return (
    <ProgramContainer>
      <Body>
        <TableContainerOuter>
          <Labelize label={""}>
            <MeetingsHead>
              <BookingHeading>
                {currentPage === "UPCOMING"
                  ? "New Bookings"
                  : currentPage === "COMPLETE"
                  ? "Completed Bookings"
                  : "Unaccepted Requests"}
              </BookingHeading>
              <SearchBoxFilterContainer>
                <SearchParticipantsWrapper>
                  <Icon type="search" />
                  <TextInput
                    placeholder="Search for Participant"
                    value={searchParticipant}
                    setValue={setSearchParticipant}
                    isSearch={true}
                  />
                </SearchParticipantsWrapper>

                <FilterOption onClick={() => setFilterOptions(!filterOptions)}>
                  <Icon type="filter" />
                  <FilterText>Filter</FilterText>
                </FilterOption>
                {filterOptions && (
                  <FilterOptions>
                    <FilterHeadText>Filter By</FilterHeadText>
                    <FilterBy>
                      <FilterLabel>
                        <FilterLabelText>Requested by</FilterLabelText>
                        <FilterClear onClick={() => setRequestedBy("")}>
                          Clear
                        </FilterClear>
                      </FilterLabel>
                      <TextInput
                        maxWidth={"93%"}
                        isFilter={true}
                        placeholder="Search by name"
                        value={requestedBy}
                        setValue={setRequestedBy}
                      />
                    </FilterBy>
                    <FilterBy>
                      <FilterLabel>
                        <FilterLabelText>Accepted by</FilterLabelText>
                        <FilterClear onClick={() => setAcceptedBy("")}>
                          Clear
                        </FilterClear>
                      </FilterLabel>
                      <TextInput
                        maxWidth={"93%"}
                        isFilter={true}
                        placeholder="Search by name"
                        value={acceptedBy}
                        setValue={setAcceptedBy}
                      />
                    </FilterBy>
                    <FilterBy>
                      <FilterLabel>
                        <FilterLabelText>Date Range</FilterLabelText>
                        <FilterClear
                          onClick={() => setDateRange({ from: null, to: null })}
                        >
                          Clear
                        </FilterClear>
                      </FilterLabel>
                      <DateInputs>
                        <EnhancedDateRangePicker
                          dateRange={dateRange}
                          setDateRange={setDateRange}
                        />
                      </DateInputs>
                    </FilterBy>
                    <FilterButtons>
                      <ResetButton onClick={resetFilters}>
                        Reset All
                      </ResetButton>
                      <ApplyButton onClick={applyFilters}>
                        Apply Filter
                      </ApplyButton>
                    </FilterButtons>
                  </FilterOptions>
                )}
              </SearchBoxFilterContainer>
            </MeetingsHead>
            {filteredRequests.length === 0 ? (
              <NoprogramContainer>
                <RequestsKeyHeaders size={13} widthSize={300} marginSpace={30}>
                  <Link to={url}>
                    {" "}
                    {program?.title ? program?.title : "Program"}
                  </Link>
                  {"  "}
                  does not have any{" "}
                  {currentPage === "UPCOMING"
                    ? "New Bookings"
                    : currentPage === "COMPLETE"
                    ? "Completed Bookings"
                    : "Unaccepted Requests"}{" "}
                  .
                </RequestsKeyHeaders>
              </NoprogramContainer>
            ) : (
              <TableContainer>
                <PaginatedTable
                  meetingsDashboard={true}
                  meetings={true}
                  setExportCurrentPageData={setExportCurrentPageData}
                  columns={[
                    {
                      key: "name",
                      title: "Requested By",
                      checkbox: true,
                      sortable: true,
                      render: ({ row }) => {
                        return (
                          <RequestedByContainer>
                            <Checkbox
                              value={!!checkboxStates[row.id]}
                              setValue={() => handleCheckboxChange(row.id)}
                            />
                            <AvatarInfo>
                              <Info>
                                {
                                  <Avatar
                                    leftSpace={1}
                                    src={
                                      row?.user?.image
                                        ? row?.user?.image
                                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                    }
                                  />
                                }
                              </Info>
                              <p>
                                {row?.user.firstName + " " + row?.user.lastName}
                              </p>
                            </AvatarInfo>
                          </RequestedByContainer>
                        );
                      },
                    },
                    {
                      key: "reasonForMeeting",
                      title: "Purpose",
                      sortable: true,
                      render: ({ row }) => {
                        return row?.reasonForMeeting;
                      },
                    },

                    {
                      key: "programJoinDate",
                      title: "Days Pending",
                      sortable: true,
                      render: ({ row }) => {
                        if (
                          currentPage === "UPCOMING" ||
                          currentPage === "COMPLETE"
                        ) {
                          const days = row?.meetingScheduleDate
                            ? NumberOfDaysBetweenDates([
                                row?.acceptedOn,
                                dateToUnixTimeStamp(row?.createdAt),
                              ])
                            : NumberOfDaysBetweenDatesAndCurrentDate([
                                dateToUnixTimeStamp(row?.createdAt),
                              ]);
                          if (days >= 0) {
                            return days;
                          }
                          return "-";
                        } else if (
                          currentPage === "PAST" &&
                          row.meetingScheduleDate === null
                        ) {
                          const dates = [row?.date, row?.date2, row?.date3];
                          dates.sort();
                          dates.reverse();
                          const days = NumberOfDaysBetweenDates([
                            dates[0],
                            dateToUnixTimeStamp(row?.createdAt),
                          ]);
                          if (days >= 0) {
                            return days;
                          }
                          return "-";
                        }
                        return "-";
                      },
                    },

                    {
                      key: "date",
                      title: `${
                        currentPage === "UPCOMING" || currentPage === "COMPLETE"
                          ? "Date"
                          : "Requested On"
                      }`,
                      sortable: true,
                      render: ({ row }) => {
                        return formatDateForMeetings(row.createdAt);
                      },
                    },

                    {
                      key: "status",
                      title: "Accepted by",
                      sortable: true,
                      show:
                        currentPage === "UPCOMING" || currentPage === "COMPLETE"
                          ? true
                          : false,
                      render: ({ row }) => {
                        if (row.status === "ACCEPT")
                          return (
                            row.Mentor?.firstName + " " + row?.Mentor?.lastName
                          );
                        // return row.status[0] + row.status.slice(1).toLowerCase();
                      },
                    },
                    {
                      key: "",
                      title: "Actions",
                      sortable: true,
                      render: ({ row }) => {
                        return (
                          <TableActions>
                            <DropIcon
                              onClick={() =>
                                setActionButtons((prev) =>
                                  prev === row.id ? null : row.id
                                )
                              }
                            >
                              <Icon type="dropdown-dots" />
                            </DropIcon>
                            {actionButtons === row.id && (
                              <DropdownItems>
                                <DropdownButton
                                  meetings={true}
                                  onClick={() => {
                                    setIsviewMore(row);
                                  }}
                                >
                                  <Icon type={"view"} color="#302F2F" />
                                  View More
                                </DropdownButton>
                                <DropdownButton
                                  meetings={true}
                                  variant="secondary"
                                  onClick={() => {
                                    callAPI(
                                      dispatch,
                                      "initializeConversation",
                                      {
                                        id: row?.user?.id,
                                      }
                                    )
                                      .then((res) => {
                                        if (res) {
                                          navigate(
                                            `/messages/${row?.user?.uuid}`
                                          );
                                        }
                                      })
                                      .catch((error) => {
                                        console.error(error);
                                      });
                                  }}
                                >
                                  <Icon type={"chat"} color="#302F2F" />
                                  Send Message
                                </DropdownButton>

                                <DropdownButton
                                  meetings={true}
                                  variant="secondary"
                                  onClick={() => setIsDeleteModal(row)}
                                >
                                  <Icon type={"trash"} color="#302F2F" />
                                  Delete
                                </DropdownButton>
                              </DropdownItems>
                            )}
                            {isDeleteModal && (
                              <Modal
                                key={`deleteModal-${row.id}`}
                                title={
                                  "Are you sure you want to delete this Meeting"
                                }
                                onClose={() => setIsDeleteModal(false)}
                              >
                                <SectionActions>
                                  <Button
                                    key={row}
                                    icon={isDeleteRequest ? "loading" : "trash"}
                                    variant="danger"
                                    onClick={() => {
                                      deleteProgram(isDeleteModal);
                                    }}
                                    text="Yes"
                                  />
                                  <ButtonSpacer />
                                  <Button
                                    icon="tick"
                                    variant="primary"
                                    onClick={() => setIsDeleteModal(false)}
                                    text="No"
                                  />
                                </SectionActions>
                              </Modal>
                            )}
                          </TableActions>
                        );
                      },
                    },
                  ]}
                  rows={filteredRequests}
                />
              </TableContainer>
            )}
          </Labelize>
        </TableContainerOuter>
      </Body>

      {isViewMore && (
        <Modal
          title={"Meeting Information"}
          width={"90%"}
          fixedWidth={"600px"}
          isMeeting={true}
          isTask={true}
          isButtonCenter
          actions={[
            {
              text: "Cancel",
              onClick: closeModal,
              textColor: true,
              isNotRound: "5px",
            },
          ]}
        >
          <MeetingInfo data={isViewMore} />
        </Modal>
      )}
    </ProgramContainer>
  );
}
export default ActivityContent;
