import styled from "styled-components";

export const AddEditTaskContainer = styled.div`
    width:100%;
    @media(max-width:640px) {
        width: 100%;
    }
    padding:0 20px;
    box-sizing:border-box;
`;

export const AddEditModalHeader = styled.div`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-bottom:1px solid #EDEDED;
    padding:20px;
`;

export const InputTitle = styled.div`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;