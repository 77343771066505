import styled from "styled-components";
import colors from "../../../config/colors";
import { Scrollable, panelSpacing } from "../../../common/styles";

export const LoginContainer = styled.div`
    background-color: ${colors.text};
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
     @media (max-width:767px) {
        margin-top:-20px;
      }
`;

export const LoginForm = styled.div`
    width:30vw;
    background-color: ${colors.background};
    border-radius: 10px;
    padding:${panelSpacing}px;
    box-sizing: border-box;
    margin-bottom:2vw;
    max-height:50vh;
    ${Scrollable}

    @media (max-width:1366px) {
        width:40vw;
      }

      @media (max-width:1024px) {
        width:44vw;
      }
    @media (max-width: 767px) {
        ${'' /* width:47%; */}
        width:80vw;

      }
    @media (max-width: 480px) {
        width:80vw;
        max-height:60vh;
      }
`;
export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height:40vh;
    @media (max-width: 1366px) {
      height:30vh;
      }
      @media (max-width: 1024px) {
        width:60%;
      }
    @media (max-width: 767px) {
        width: 55%;   
        height: 28vh;     
      }
`;
export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${props => props.isSignUp ? "28px": "32px"};
`;
export const LoginPageOption = styled.div`
    color:${colors.secondary};
    cursor:pointer;
    font-size:14px;
    :hover{
        color:${colors.background};
    }
`;
export const Header = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    margin-bottom:15px;
`;
export const Heading = styled.div`
    font-size:24px;
    font-weight:bold;
    color:${colors.primary};
`;
export const DevString = styled.div`
    position:fixed;
    bottom:0;
    left:0;
    color:${colors.background}22;
    font-size:7px;
`;

export const PhoneNumberInputWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
`;

export const CountryCode = styled.div`
  margin-right: 8px;
`;

export const PhoneNumberInput = styled.input`
  border: none;
  flex: 1;
  outline: none;
`;


//

export const AuthContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background: #FAFAFA;
`;
export const FormContainer = styled.div`
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);
  padding: 24px 0 40px;
  max-width: 555px;
  width: 100%;
  max-height: 65vh;
  margin-top: 30px;
  text-align: center;
  ${Scrollable}
`;
export const AuthLogoContainer = styled.div`
`;
export const AuthLogo = styled.img`
`;
export const AuthTab = styled.div`
  display: flex;
  padding: 5px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: rgba(68, 68, 155, 0.10);
  max-width: 190px;
  width: 100%;
  margin: 0 auto;
`;
export const LoginTab = styled.div`
  padding: 7px 22px;
  border-radius: 5px;
  background: ${props => props.mode ? "#FFF" : "transparent"};
  color: #302F2F;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  font-family: "Nunito Sans";
`;
export const SignUpTab = styled.div`
  padding: 7px 16px;
  border-radius: 5px;
  background: ${props => props.mode ? "#FFF" : "transparent"};
  color: #302F2F;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  font-family: "Nunito Sans";
`;

export const AuthHeading = styled.div`
  color: #302F2F;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 30px;
  font-family: "Nunito Sans";
`;

export const AuthForm = styled.div`
    max-width: 322px;
    margin: 30px auto 0;
`;
export const SignUpButton = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 32px;
    color: #757575;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    font-family: "Nunito Sans";
`;
export const ForgotPasswordButton = styled.div`
    color: #757575;
    text-align: right;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    font-family: "Nunito Sans";
`;

export const SignUpLink = styled.p`
    margin: 0 0 0 5px;
    color: #386CFA;
    font-style: italic;
    font-weight: 500;
`;

export const InitialResetPara = styled.p`
    color: #757575;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 322px;
    margin: 32px auto 24px;
    text-align: left;
`;

export const VerificationMessage = styled.div`
`;
export const MessageImage = styled.img`
  margin-top: 20px;
  margin-bottom: 20px;
`;
export const MessageHeading = styled.h3`
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 322px;
  margin: 0 auto;
`;
export const MessageDescription = styled.p`
  color: #757575;
  text-align: center;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 322px;
  margin: 6px auto 0;
`;

