import styled from "styled-components";
import colors from "../../config/colors";
import { panelSpacing } from "../styles";

export const AccordianContainer = styled.div`
    /* border:solid 1px ${colors.secondary}; */
    border-radius: ${panelSpacing/2}px;
    border: ${(props) => props.border || "1px" };
    transition: all 0.1s ease-in-out;
    width: ${props => props.isTask ? "98.5%" : "100%"};
    height:fit-content;
    height: ${props=>props.isTask ?`` : !props.height ?  'fit-content' : '613px'};
    overflow-y: scroll;
    background-color: ${(props) => props.isTask ? "" : props.backgroundColor || "white"};
    padding: ${props=>props.isTask ? "10px" : props.padding || "10px"};
    box-shadow: ${props => props.isTask ? "0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25)": ""};
    ::-webkit-scrollbar {
        width: 5px;
    }
 
    @media (max-width:767px) {
    height: ${props=>props.isTask ?`calc(100vh - 350px)` : !props.height ?  'fit-content' : '613px'};
    }
`;
export const SectionContainer = styled.div`
    border: solid 1px ${colors.secondary};
    border-radius: ${panelSpacing/2}px;
    padding:${panelSpacing/2}px;
    transition: all 0.1s ease-in-out;
    margin:0 0 15px 0;
    border-bottom: solid 1px ${colors.secondary};
    padding:12px;
    transition: all 0.1s ease-in-out;
    border-radius: 10px;
    background: #FFF;
    box-shadow: ${props => props.isTask ? "" : "0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25)"};
    margin-top: 8px;
`;
export const SectionHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const SectionInfo = styled.div`
    width:${props=>props.isFullWidth ? "100%" : " calc(100% - 40px)"};
`;
export const SectionContent = styled.div`
    margin-top: ${panelSpacing/2}px;
    background-color:${colors.secondary};
    padding:${panelSpacing/2}px;
    border-radius:${panelSpacing/2}px;
    background-color:${(props) => props.backgroundColor || "#F7F7F7"}
    margin-top: 12px;
    padding: 10px 6px 16px 8px;
    border-radius: 5px;
    background: #FAFAFA;
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
export const ContentCard= styled.div`
    background-color:${colors.background};
    // padding:${panelSpacing/2}px;
    padding:15px;
    border-radius:${panelSpacing/2}px;
`;
export const ContentActions = styled.div`
    display:flex;
    margin-top:${panelSpacing/2}px;
    align-items:center;
    justify-content:flex-end;
    @media (max-width:640px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;