import colors from "../config/colors";

export const panelSpacing=20;
export const headerSize=45;
export const borderRadius=panelSpacing/2;

const scrollbarWidth=borderRadius;

export const Scrollable=`
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: ${scrollbarWidth}px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset ${scrollbarWidth/3}px 0 ${scrollbarWidth/2}px ${colors.darkBackground};
        border-radius: 0 ${scrollbarWidth}px ${scrollbarWidth}px 0;
        background: ${colors.background};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: ${colors.primary};
    border-radius: 0 ${scrollbarWidth}px ${scrollbarWidth}px 0;
    box-shadow: inset ${scrollbarWidth/3}px 0 ${scrollbarWidth/2}px #0004;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    box-shadow: inset ${scrollbarWidth/3}px 0 ${scrollbarWidth/2}px #0002;
    border-radius: 0 ${scrollbarWidth/1.5}px ${scrollbarWidth/1.5}px 0;
    
    }
    ::-webkit-resizer{
        background-color:${colors.secondary};
        border:solid 1px ${colors.darkBackground};
        border-radius:0px 0px 10px 0px;
    }
`;