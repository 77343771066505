import React from "react";
import {
  AddNewQuestionContainer,
  ApplicationEditorContainer,
  FormSection,
  QuestionNumber,
  QuestionSection,
  QuestionType,
  QuestionTypeLabel,
} from "./styles";
import DateLine from "../../../Messages/ChatWindow/DateLine";
import Button from "../../../../../common/Button";
import { ButtonSpacer } from "../../../../../common/Button/styles";
import Labelize from "../../../../../common/Labelize";
import TextInput from "../../../../../common/TextInput";
import { validateText } from "../../../../../common/utils";
import Pills from "../../../../../common/Pills";
import { getIsValid } from "./utils";

function ApplicationEditor({
  programForm,
  setProgramForm,
  isValid,
  setIsValid,
}) {
  const applicationForm = programForm.applicationForm;
  const setApplicationForm = (newApplicationForm) => {
    setProgramForm({
      ...programForm,
      applicationForm: newApplicationForm,
    });
  };
  React.useEffect(() => {
    setIsValid(getIsValid(applicationForm));
  }, [applicationForm]);
  return (
    <>
      {programForm?.programType !== "TEAM" &&
      applicationForm?.programType !== "TEAM" &&
      applicationForm ? (
        <ApplicationEditorContainer>
          {["mentor", "mentee"].map((role) => {
            let key = role.toLowerCase();
            return (
              <FormSection>
                <DateLine dateString={role + " Application"} />
                <ButtonSpacer />
                <Labelize
                  horizontal
                  label={"Allow New Members to Apply as " + role}
                >
                  <Pills
                    value={applicationForm.accepts[key]}
                    setValue={(newValue) => {
                      setApplicationForm({
                        ...applicationForm,
                        accepts: {
                          ...applicationForm.accepts,
                          [key]: newValue,
                        },
                      });
                    }}
                    options={[
                      {
                        label: "Yes",
                        value: true,
                      },
                      {
                        label: "No",
                        value: false,
                      },
                    ]}
                  />
                </Labelize>
                {applicationForm.accepts[key] &&
                  applicationForm[key].map((question, index) => {
                    return (
                      <QuestionSection>
                        <QuestionNumber>
                          Question {index + 1}
                          <Button
                            icon="cross"
                            onClick={() => {
                              let newApplicationForm = {
                                ...applicationForm,
                                [key]: applicationForm[key].filter(
                                  (_, cIndex) => {
                                    return cIndex !== index;
                                  }
                                ),
                              };
                              setApplicationForm(newApplicationForm);
                            }}
                          />
                        </QuestionNumber>
                        <ButtonSpacer />
                        <Labelize label="Question Text">
                          <TextInput
                            placeholder="Write here..."
                            value={question.question}
                            setValue={(newValue) => {
                              let newApplicationForm = {
                                ...applicationForm,
                                [key]: applicationForm[key].map((q, cIndex) => {
                                  if (cIndex === index) {
                                    return {
                                      ...q,
                                      question: newValue,
                                      questionFor: role.toLowerCase(),
                                    };
                                  } else {
                                    return q;
                                  }
                                }),
                              };
                              setApplicationForm(newApplicationForm);
                            }}
                            isValid={validateText(question.question)}
                            setIsValid={() => {}}
                          />
                        </Labelize>
                        <ButtonSpacer />
                        <QuestionType>
                          <QuestionTypeLabel>Question Type</QuestionTypeLabel>
                          <Pills
                            value={question.questionType}
                            setValue={(newValue) => {
                              setApplicationForm({
                                ...applicationForm,
                                [key]: applicationForm[key].map(
                                  (question, cIndex) => {
                                    if (cIndex === index) {
                                      return {
                                        ...question,
                                        questionType: newValue,
                                        questionFor: role.toLowerCase(),
                                      };
                                    } else {
                                      return question;
                                    }
                                  }
                                ),
                              });
                            }}
                            options={[
                              {
                                label: "Short Answer",
                                value: "short",
                              },
                              {
                                label: "Long Answer",
                                value: "descriptive",
                              },
                            ]}
                          />
                        </QuestionType>
                      </QuestionSection>
                    );
                  })}
                {applicationForm.accepts[key] && (
                  <AddNewQuestionContainer>
                    <Button
                      text={"Add New Question for " + role}
                      icon="add"
                      onClick={() => {
                        setApplicationForm({
                          ...applicationForm,
                          [key]: [
                            ...applicationForm[key],
                            {
                              id: applicationForm[key].length,
                              question: "",
                              questionType: "short",
                              questionFor: role.toLowerCase(),
                            },
                          ],
                        });
                      }}
                    />
                  </AddNewQuestionContainer>
                )}
              </FormSection>
            );
          })}
        </ApplicationEditorContainer>
      ) : (
        applicationForm && (
          <ApplicationEditorContainer>
            {["member"].map((role) => {
              let key = role.toLowerCase();
              return (
                <FormSection>
                  <DateLine dateString={role + " Application"} />
                  <ButtonSpacer />
                  <Labelize
                    horizontal
                    label={"Allow New Members to Apply as " + role}
                  >
                    <Pills
                      value={applicationForm.accepts[key]}
                      setValue={(newValue) => {
                        setApplicationForm({
                          ...applicationForm,
                          accepts: {
                            ...applicationForm.accepts,
                            [key]: newValue,
                          },
                        });
                      }}
                      options={[
                        {
                          label: "Yes",
                          value: true,
                        },
                        {
                          label: "No",
                          value: false,
                        },
                      ]}
                    />
                  </Labelize>
                  {applicationForm.accepts[key] &&
                    applicationForm[key].map((question, index) => {
                      return (
                        <QuestionSection>
                          <QuestionNumber>
                            Question {index + 1}
                            <Button
                              icon="cross"
                              onClick={() => {
                                let newApplicationForm = {
                                  ...applicationForm,
                                  [key]: applicationForm[key].filter(
                                    (_, cIndex) => {
                                      return cIndex !== index;
                                    }
                                  ),
                                };
                                setApplicationForm(newApplicationForm);
                              }}
                            />
                          </QuestionNumber>
                          <ButtonSpacer />
                          <Labelize label="Question Text">
                            <TextInput
                              placeholder="Type Your Question Here ..."
                              value={question.question}
                              setValue={(newValue) => {
                                let newApplicationForm = {
                                  ...applicationForm,
                                  [key]: applicationForm[key].map(
                                    (q, cIndex) => {
                                      if (cIndex === index) {
                                        return {
                                          ...q,
                                          question: newValue,
                                          questionFor: role.toLowerCase(),
                                        };
                                      } else {
                                        return q;
                                      }
                                    }
                                  ),
                                };
                                setApplicationForm(newApplicationForm);
                              }}
                              isValid={validateText(question.question)}
                              setIsValid={() => {}}
                            />
                          </Labelize>
                          <ButtonSpacer />
                          <QuestionType>
                            <QuestionTypeLabel>Question Type</QuestionTypeLabel>
                            <Pills
                              value={question.questionType}
                              setValue={(newValue) => {
                                setApplicationForm({
                                  ...applicationForm,
                                  [key]: applicationForm[key].map(
                                    (question, cIndex) => {
                                      if (cIndex === index) {
                                        return {
                                          ...question,
                                          questionType: newValue,
                                          questionFor: role.toLowerCase(),
                                        };
                                      } else {
                                        return question;
                                      }
                                    }
                                  ),
                                });
                              }}
                              options={[
                                {
                                  label: "Short Answer",
                                  value: "short",
                                },
                                {
                                  label: "Long Answer",
                                  value: "descriptive",
                                },
                              ]}
                            />
                          </QuestionType>
                        </QuestionSection>
                      );
                    })}
                  {applicationForm.accepts[key] && (
                    <AddNewQuestionContainer>
                      <Button
                        text={"Add New Question for " + role}
                        icon="add"
                        onClick={() => {
                          setApplicationForm({
                            ...applicationForm,
                            [key]: [
                              ...applicationForm[key],
                              {
                                id: applicationForm[key].length,
                                question: "",
                                questionType: "short",
                                questionFor: role.toLowerCase(),
                              },
                            ],
                          });
                        }}
                      />
                    </AddNewQuestionContainer>
                  )}
                </FormSection>
              );
            })}
          </ApplicationEditorContainer>
        )
      )}
    </>
  );
}
export default ApplicationEditor;
