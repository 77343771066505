import React from 'react';
import {
    DropDownContainer,
    DropDownButton,
    DropDownList,
    DropDownItem
} from './style'

const DropDown = ({
    title,
    options,
    handler,
    isActive,
    handleDropdown,
    selectedItemId,
  }) => {
    return (
      <DropDownContainer>
        <DropDownButton onClick={handleDropdown} isActive={isActive}>
          {title}
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M4 6l4 4 4-4" />
          </svg>
        </DropDownButton>
        
        {isActive && (
          <DropDownList>
            {options.map((option) => (
              <DropDownItem
                key={option.type}
                isSelected={option.type === selectedItemId}
                onClick={() => handler(option)}
              >
                {option.title}
              </DropDownItem>
            ))}
          </DropDownList>
        )}
      </DropDownContainer>
    );
  };
  
  export default DropDown;
  