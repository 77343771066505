import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DatePickerContainer } from "./style";

const EnhancedDateRangePicker = ({
  dateRange,
setDateRange,
}) => {
  return (
    <DatePickerContainer>
      <label>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.1667 1.6665C14.627 1.6665 15.0001 2.0396 15.0001 2.49984V3.33317H15.8334C17.2141 3.33317 18.3334 4.45246 18.3334 5.83317V15.8332C18.3334 17.2139 17.2141 18.3332 15.8334 18.3332H4.16675C2.78604 18.3332 1.66675 17.2139 1.66675 15.8332V5.83317C1.66675 4.45246 2.78604 3.33317 4.16675 3.33317H5.00008V2.49984C5.00008 2.0396 5.37318 1.6665 5.83342 1.6665C6.29365 1.6665 6.66675 2.0396 6.66675 2.49984V3.33317H13.3334V2.49984C13.3334 2.0396 13.7065 1.6665 14.1667 1.6665ZM3.33341 9.99984V15.8332C3.33341 16.2934 3.70651 16.6665 4.16675 16.6665H15.8334C16.2937 16.6665 16.6667 16.2934 16.6667 15.8332V9.99984H3.33341ZM3.33341 8.33317H16.6667V5.83317C16.6667 5.37293 16.2937 4.99984 15.8334 4.99984H15.0001V5.83317C15.0001 6.29341 14.627 6.6665 14.1667 6.6665C13.7065 6.6665 13.3334 6.29341 13.3334 5.83317V4.99984H6.66675V5.83317C6.66675 6.29341 6.29365 6.6665 5.83341 6.6665C5.37318 6.6665 5.00008 6.29341 5.00008 5.83317V4.99984H4.16675C3.70651 4.99984 3.33341 5.37293 3.33341 5.83317V8.33317Z"
            fill="#EDEDED"
          />
        </svg>
        <DatePicker
          selected={dateRange.from}
          onChange={(date) => setDateRange((prev) => ({ ...prev, from: date }))}
          placeholderText="From"
          selectsStart
          startDate={dateRange.from}
          endDate={dateRange.to}
        />
      </label>
      <label>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.1667 1.6665C14.627 1.6665 15.0001 2.0396 15.0001 2.49984V3.33317H15.8334C17.2141 3.33317 18.3334 4.45246 18.3334 5.83317V15.8332C18.3334 17.2139 17.2141 18.3332 15.8334 18.3332H4.16675C2.78604 18.3332 1.66675 17.2139 1.66675 15.8332V5.83317C1.66675 4.45246 2.78604 3.33317 4.16675 3.33317H5.00008V2.49984C5.00008 2.0396 5.37318 1.6665 5.83342 1.6665C6.29365 1.6665 6.66675 2.0396 6.66675 2.49984V3.33317H13.3334V2.49984C13.3334 2.0396 13.7065 1.6665 14.1667 1.6665ZM3.33341 9.99984V15.8332C3.33341 16.2934 3.70651 16.6665 4.16675 16.6665H15.8334C16.2937 16.6665 16.6667 16.2934 16.6667 15.8332V9.99984H3.33341ZM3.33341 8.33317H16.6667V5.83317C16.6667 5.37293 16.2937 4.99984 15.8334 4.99984H15.0001V5.83317C15.0001 6.29341 14.627 6.6665 14.1667 6.6665C13.7065 6.6665 13.3334 6.29341 13.3334 5.83317V4.99984H6.66675V5.83317C6.66675 6.29341 6.29365 6.6665 5.83341 6.6665C5.37318 6.6665 5.00008 6.29341 5.00008 5.83317V4.99984H4.16675C3.70651 4.99984 3.33341 5.37293 3.33341 5.83317V8.33317Z"
            fill="#EDEDED"
          />
        </svg>
        <DatePicker
          selected={dateRange.to}
          onChange={(date) => setDateRange((prev) => ({ ...prev, to: date }))}
          selectsEnd
          placeholderText="To"
          startDate={dateRange.from}
          endDate={dateRange.to}
          minDate={dateRange.from}
        />
      </label>
    </DatePickerContainer>
  );
};

export default EnhancedDateRangePicker;
