import styled from "styled-components";
import colors from "../../config/colors";

export const CheckboxContainer = styled.div`
    display:flex;
    transition: all 0.1s ease-in-out;
    font-size:${props =>props.isAgree ?'14px' : '16px'};
    margin-top:${props =>props.isModal ?'20px' : ''}
`;
export const TheBox = styled.div`
    height:${props =>props.isAgree ?'15px' : '20px'};
    width:${props =>props.isAgree ?'15px' : '20px'};
    display:flex;
    font-size: ${props =>props.isAgree ?'10px' : '14px'};
    align-items:center;
    justify-content:center;
    border:${props =>props.isBorder ?'solid 1px #a7a2a2' :'solid 1px ${colors.secondary}'};
    border-radius:5px;
    cursor:${props=>props.disabled?"not-allowed":"pointer"};
    background-color:${props=>props.checked?props.disabled ? colors.darkBackground:colors.primary:colors.background};
    :active{
        transform:scale(0.95);
    }
`;

export const LinkContent = styled.div`
    color: #757575;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const LinkText = styled.a`
    color: #386CFA;
    font-family: "Nunito Sans";
    font-weight: 600;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    cursor: pointer;
`;