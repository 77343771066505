export const data = {
  tasks: [
    {
      id: 1,
      name: "Task 1",
      dueDate: "2025-03-01",
      status: "Completed"
    },
    {
      id: 2,
      name: "Task 2",
      dueDate: "2026-03-01",
      status: "In Complete"
    },
  ],
  surveys: [
    {
      surveyTitle: "Product Experience",
      surveyCreated: "Survey Created at: 11:11 AM, 10/28/2024",
      surveyDueDate: "Due on: 1 March, 2025",
      surveyStatus: "Completed"
    },
    {
      surveyTitle: "Onemauve Review",
      surveyCreated: "Survey Created at: 11:11 AM, 10/28/2024",
      surveyDueDate: "Due on: 1 March, 2025",
      surveyStatus: "In Completed"
    },
    {
      surveyTitle: "Product Experience",
      surveyCreated: "Survey Created at: 11:11 AM, 10/28/2024",
      surveyDueDate: "Due on: 1 March, 2025",
      surveyStatus: "Completed"
    },
    {
      surveyTitle: "Product Experience",
      surveyCreated: "Survey Created at: 11:11 AM, 10/28/2024",
      surveyDueDate: "Due on: 1 March, 2025",
      surveyStatus: "Completed"
    }
  ],
};

export const surveyFormData = [
  { id: 1, label: "Question 1", text: "What is your preferred email address?", type: "Answer Option" },
  { id: 2, label: "Question 2", text: "What is your expected graduation year from DGSOM?", type: "Answer Option" },
  { id: 3, label: "Question 3", text: "Which year are you in?", type: "Answer Option" },
  {
      id: 4,
      label: "Question 4",
      text: "What medicine field are you looking to major in as a career?",
      type: "Checkboxes",
      options: ["Paediatrician", "Neurosurgeon", "Osteopathic", "Gastroenterologist"],
  },
]

export const answerDropdown = [
  { text: "Free Response", icon: 'freeresponse' },
  { text: "Multiple Choice", icon: "multichoice" },
  { text: "Checkboxes", icon: "checkboxes" },
  { text: "Dropdown", icon: "dropdown" },
];

export const oneMauvedata = {
  thumb: require("./table-thumb.png"),
  name: "Lolita James",
  role: "Mentee",
  email: "loljames@onemauve.com",
  university: "Massachusetts Institute of Technology",
  genderOptions: ["Male", "Female", "Transgender", "Binary", "Non-Binary", "Bisexual", "LGBTQI+2", "Others"],
  heardAboutOptions: ["Social Media", "Print Media", "Google", "Word of mouth"],
  recommendOptions: ["Yes", "No", "Maybe"],
  careerExperienceOptions: ["Consulting", "Finance", "Entertainment", "Entrepreneurship/VC", "Tech", "Marketing", "Education", "Non Profit", "HR", "Non profit", "Banking", "Others"],
  completedDate: "1:30 PM, 9/27/2024",
};

export const productExperience = [
  {
    reportTitle: "Are you satisfied with the quality of mentorship offered so far?",
    reportResponses: "8 responses",
    reportGraph: require("./report-graph.png"),
    reportFeatures: [
      { key: "Excellent" },
      { key: "Good" },
      { key: "Fair" }, // Corrected typo from "Far" to "Fair"
      { key: "Bad" },
      { key: "Unacceptable" }
    ]
  },
  {
    reportTitle: "Are you satisfied with the available resources in the program?",
    reportResponses: "3 responses",
    reportGraph: require("./report-graph.png")
  },
  {
    reportTitle: "Would you recommend your onemauve to your friends and colleagues?",
    reportResponses: "5 responses",
    reportGraph: require("./report-graph.png"),
    reportFeatures: [
      { key: "Yes" },
      { key: "Maybe" },
      { key: "No" },
    ]
  },
  {
    reportTitle: "What University do you attend",
    reportResponses: "12 responses",
    reportGraph: require("./report-graph.png")
  },
  {
    reportTitle: "Which email address do you actively use the most",
    reportResponses: "15 responses",
    reportEmails:[
      { key: "jaylen@hotmail.com" },
      { key: "pauline@yahoo.com" },
      { key: "paulxy@yahoo.com" },
      { key: "kdavidson@yahoo.com" },
      { key: "61andre@gmail.com" },
      { key: "aaron1@aol.com" },
      { key: "lylej@hotmail.com" },
      { key: "nikkynik@hotmail.com" },
      { key: "miranda@outlook.com" }
    ]
  }
];
