import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../../../../Store";
import callAPI from "../../../../api/apiCaller";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../../../common/Loading";
import MeetingInfo from "../../Programs/popups/MeetingInfo";
import {
  ProgramContainer,
  Avatar,
  NoprogramContainer,
  RequestsKeyHeaders,
  AvatarInfo,
  DropIcon,
  DropdownItems,
  DropdownButton,
  TableContainer
} from "../ActivityContent/styles";
import {
  NumberOfDaysBetweenDates,
  NumberOfDaysBetweenDatesAndCurrentDate,
  dateToUnixTimeStamp,
  formatDateForMeetings,
} from "../../../../common/utils";
import Table from "../../../../common/Table";
import { TableActions } from "../../../../common/Table/styles";
import { Info } from "../../Programs/ProgramContent/ProgramParticipants/ActiveParticipants/styles";
import Button from "../../../../common/Button";
import { ButtonSpacer, SectionActions } from "../../../../common/Button/styles";
import Modal from "../../../../common/Modal";
import Icon from "../../../../common/Icon";

const DashboardMeetings = ({ currentPage }) => {
  const params = useParams();
  const navigate = useNavigate();

  const { dispatch } = useContext(Context);
  const [requests, setRequests] = useState(null);
  const [program, setProgram] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isDeleteRequest, setIsDeleteRequest] = useState(false);
  const [isViewMore, setIsviewMore] = useState(false);
  const [actionButtons, setActionButtons] = useState(null);

  useEffect(() => {
    callAPI(
      dispatch,
      currentPage === "UPCOMING"
        ? "ProgramNewRequestForManager"
        : currentPage === "COMPLETE"
        ? "ProgramCompleteRequestForManager"
        : "ProgramUnFullFiledRequestForManager",
      { id: params.selectedProgram }
    ).then((res) => {
      if (res.isResponseOk) {
        if (currentPage === "UPCOMING") {
          setRequests(res.data.slice(0, 5));
        } else {
          setRequests(res.data.slice(0, 5));
        }
      } else setRequests([]);
    });
    return () => {
      setRequests(null);
    };
  }, [currentPage]);
  function removeItemFromArray(itemToRemove) {
    const updatedArray = requests.filter((item) => item.id !== itemToRemove);
    setRequests(updatedArray);
  }
  function deleteProgram(meeting) {
    setIsDeleteRequest(true);
    callAPI(dispatch, "deleteMeetingRequest", {
      rid: meeting.id,
      id: meeting.id,
      tid: meeting.programId,
    }).then(() => {
      setIsDeleteRequest(false);
      removeItemFromArray(meeting.id);
      setIsDeleteModal(false);
    });
  }
  const programInformation = () => {
    callAPI(dispatch, "getProgramInformation", {
      id: params.selectedProgram,
    }).then((info) => {
      setProgram(info.data);
    });
  };
  React.useEffect(() => {
    programInformation();
  }, []);
  let url =
    window.location.protocol +
    "//" +
    window.location.host +
    "/programs/" +
    program?.id +
    "/home";

  if (requests === null) {
    return (
      <ProgramContainer>
        <Loading loadingItem="Activity Details" />
      </ProgramContainer>
    );
  }

  const closeModal = () => {
    setIsviewMore(false);
  };
  return (
    <>
      {requests.length === 0 ? (
        <NoprogramContainer>
          <RequestsKeyHeaders size={13} widthSize={300} marginSpace={30}>
            <Link to={url}> {program?.title ? program?.title : "Program"}</Link>
            {"  "}
            does not have any{" "}
            {currentPage === "UPCOMING"
              ? "New Bookings"
              : currentPage === "COMPLETE"
              ? "Completed Bookings"
              : "Unaccepted Requests"}{" "}
            .
          </RequestsKeyHeaders>
        </NoprogramContainer>
      ) : (
        <TableContainer>
        <Table
          meetingsDashboard={true}
          isHeightInherited={currentPage === "UPCOMING" && true}
          columns={
            currentPage === "UPCOMING"
              ? [
                  {
                    key: "name",
                    title: "Requested By",
                    sortable: true,
                    render: ({ row }) => {
                      return (
                        <AvatarInfo>
                          <Info>
                            {
                              <Avatar
                                leftSpace={1}
                                src={
                                  row?.user?.image
                                    ? row?.user?.image
                                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                }
                              />
                            }
                          </Info>
                          <p>
                            {row?.user.firstName + " " + row?.user.lastName}
                          </p>
                        </AvatarInfo>
                      );
                    },
                  },
                  {
                    key: "reasonForMeeting",
                    title: "Purpose",
                    sortable: true,
                    render: ({ row }) => {
                      return row?.reasonForMeeting;
                    },
                  },

                  {
                    key: "programJoinDate",
                    title: "Days Pending",
                    sortable: true,
                    render: ({ row }) => {
                      if (
                        currentPage === "UPCOMING" ||
                        currentPage === "COMPLETE"
                      ) {
                        const days = row?.meetingScheduleDate
                          ? NumberOfDaysBetweenDates([
                              row?.acceptedOn,
                              dateToUnixTimeStamp(row?.createdAt),
                            ])
                          : NumberOfDaysBetweenDatesAndCurrentDate([
                              dateToUnixTimeStamp(row?.createdAt),
                            ]);
                        if (days >= 0) {
                          return days;
                        }
                        return "-";
                      } else if (
                        currentPage === "PAST" &&
                        row.meetingScheduleDate === null
                      ) {
                        const dates = [row?.date, row?.date2, row?.date3];
                        dates.sort();
                        dates.reverse();
                        const days = NumberOfDaysBetweenDates([
                          dates[0],
                          dateToUnixTimeStamp(row?.createdAt),
                        ]);
                        if (days >= 0) {
                          return days;
                        }
                        return "-";
                      }
                      return "-";
                    },
                  },

                  {
                    key: "status",
                    title: "Accepted by",
                    sortable: true,
                    render: ({ row }) => {
                      if (row.status === "ACCEPT")
                        return (
                          row.Mentor?.firstName + " " + row?.Mentor?.lastName
                        );
                      // return row.status[0] + row.status.slice(1).toLowerCase();
                    },
                  },
                  {
                    key: "",
                    title: "Actions",
                    sortable: true,
                    render: ({ row }) => {
                      return (
                        <TableActions>
                          <DropIcon
                            onClick={() =>
                              setActionButtons((prev) =>
                                prev === row.id ? null : row.id
                              )
                            }
                          >
                            <Icon type="dropdown-dots" />
                          </DropIcon>
                          {actionButtons === row.id && (
                            <DropdownItems>
                              <DropdownButton
                                meetings={true}
                                onClick={() => {
                                  setIsviewMore(row);
                                }}
                              >
                                <Icon type={"view"} color="#302F2F" />
                                View More
                              </DropdownButton>
                              <DropdownButton
                                meetings={true}
                                variant="secondary"
                                onClick={() => {
                                  callAPI(dispatch, "initializeConversation", {
                                    id: row?.user?.id,
                                  })
                                    .then((res) => {
                                      if (res) {
                                        navigate(
                                          `/messages/${row?.user?.uuid}`
                                        );
                                      }
                                    })
                                    .catch((error) => {
                                      console.error(error);
                                    });
                                }}
                              >
                                <Icon type={"chat"} color="#302F2F" />
                                Send Message
                              </DropdownButton>

                              <DropdownButton
                                meetings={true}
                                variant="secondary"
                                onClick={() => setIsDeleteModal(row)}
                              >
                                <Icon type={"trash"} color="#302F2F" />
                                Delete
                              </DropdownButton>
                            </DropdownItems>
                          )}
                          {isDeleteModal && (
                            <Modal
                              key={`deleteModal-${row.id}`}
                              title={
                                "Are you sure you want to delete this Meeting"
                              }
                              onClose={() => setIsDeleteModal(false)}
                            >
                              <SectionActions>
                                <Button
                                  key={row}
                                  icon={isDeleteRequest ? "loading" : "trash"}
                                  variant="danger"
                                  onClick={() => {
                                    deleteProgram(isDeleteModal);
                                  }}
                                  text="Yes"
                                />
                                <ButtonSpacer />
                                <Button
                                  icon="tick"
                                  variant="primary"
                                  onClick={() => setIsDeleteModal(false)}
                                  text="No"
                                />
                              </SectionActions>
                            </Modal>
                          )}
                        </TableActions>
                      );
                    },
                  },
                ]
              : [
                  {
                    key: "name",
                    title: "Requested By",
                    sortable: true,
                    render: ({ row }) => {
                      return (
                        <AvatarInfo>
                          <Info>
                            {
                              <Avatar
                                leftSpace={1}
                                src={
                                  row?.user?.image
                                    ? row?.user?.image
                                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                }
                              />
                            }
                          </Info>
                          <p>
                            {row?.user.firstName + " " + row?.user.lastName}
                          </p>
                        </AvatarInfo>
                      );
                    },
                  },
                  {
                    key: "programJoinDate",
                    title: "Days Pending",
                    sortable: true,
                    render: ({ row }) => {
                      if (
                        currentPage === "UPCOMING" ||
                        currentPage === "COMPLETE"
                      ) {
                        const days = row?.meetingScheduleDate
                          ? NumberOfDaysBetweenDates([
                              row?.acceptedOn,
                              dateToUnixTimeStamp(row?.createdAt),
                            ])
                          : NumberOfDaysBetweenDatesAndCurrentDate([
                              dateToUnixTimeStamp(row?.createdAt),
                            ]);
                        if (days >= 0) {
                          return days;
                        }
                        return "-";
                      } else if (
                        currentPage === "PAST" &&
                        row.meetingScheduleDate === null
                      ) {
                        const dates = [row?.date, row?.date2, row?.date3];
                        dates.sort();
                        dates.reverse();
                        const days = NumberOfDaysBetweenDates([
                          dates[0],
                          dateToUnixTimeStamp(row?.createdAt),
                        ]);
                        if (days >= 0) {
                          return days;
                        }
                        return "-";
                      }
                      return "-";
                    },
                  },
                  {
                    key: `${
                      currentPage === "COMPLETE" ? "status" : "createDate"
                    }`,
                    title: `${
                      currentPage === "COMPLETE"
                        ? "Accepted by"
                        : "Requested on"
                    }`,
                    sortable: true,
                    render: ({ row }) => {
                      if (currentPage === "COMPLETE") {
                        if (row.status === "ACCEPT")
                          return (
                            row.Mentor?.firstName + " " + row?.Mentor?.lastName
                          );
                      } else {
                        return formatDateForMeetings(row.createdAt);
                      }
                    },
                  },
                ]
          }
          rows={requests}
        />
        </TableContainer>

      )}
      {isViewMore && (
        <Modal
          title={"Meeting Information"}
          width={"90%"}
          fixedWidth={"600px"}
          isMeeting={true}
          isTask={true}
          isButtonCenter
          actions={[
            {
              text: "Cancel",
              onClick: closeModal,
              textColor: true,
              isNotRound: "5px",
            },
          ]}
        >
          <MeetingInfo data={isViewMore} />
        </Modal>
      )}
    </>
  );
};

export default DashboardMeetings;
