import styled from "styled-components";
import { Scrollable } from "../../../../../../common/styles";


export const MeetingOuterContainer = styled.div`
  ${Scrollable}
  height: 100%;
`;

export const Info = styled.div`
  margin-left: 16px;
`;
export const MeetingInformation = styled.div`
  display: flex;
`;

export const StatusContainer = styled.div`
`;
export const Name = styled.h3`
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 4px;
`;
export const ProgramTitle = styled.p`
  color: #C5C1C1;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;

export const MeetingDetailOuter = styled.div`
  margin-top: 12px;
`;

export const InfoLabel = styled.h3`
  color: #A6A6A6;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 0 8px;
`;

export const InfoValue = styled.span`
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 6px;
`;

export const MeetingInfo = styled.div`
  margin-top: 8px;
  display: flex;
`;

export const ImagePreview = styled.img`
  width: 65px;
  height: 65px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #5E99F9;
`;

export const MeetingInnerContainer = styled.div`
  background-color:#fff;
  box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);
  padding: 16px 24px 20px;
  margin-top: 16px;
  border-radius:10px;
  width:100%;
  box-sizing:border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const MeetingDetailCol = styled.div`
  display:flex;
  flex-wrap:wrap;
  max-width:750px;
  width:55%;
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 5px;
`;

export const MeetingPersonThumb = styled.div`
  display:flex;
  width:65px;
  height:65px;
  border-radius:50%;
  border:2px solid #5E99F9;
  overflow:hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const MeetingDetail = styled.div`
  display:flex;
  flex-wrap:wrap;
  max-width: 600px;
  margin-left:12px;
`;

export const MeetingPersonTitle = styled.div`
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 500;
`;

export const MeetingPersonProgram = styled.div`
  color: #A6A6A6;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
  display:block;
  width:100%;
  text-transform:capitalize;
  margin:5px 0 10px 0;
`;

export const MeetingDetailInfo = styled.div`
  display:flex;
  flex-wrap:wrap;
  align-items:flex-start;
  width:100%;
  margin-bottom:5px;
`;

export const MeetingDetailLabel = styled.div`
  color: #A6A6A6;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
  display:flex;
`;

export const MeetingDetailIcon = styled.div`
  margin-right:10px;
`;

export const MeetingDetailComment = styled.div`
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 500;
  display:flex;
  margin:0 0 0 5px;
`;

export const MeetingZoomLink = styled.div`
  color: #393CF6;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 500;
  text-decoration-line: underline;
  margin-left:5px;
  display:flex;
`;

export const MeetingRightCol = styled.div`
  display:flex;
  flex-wrap:wrap;
  max-width:400px;
  width:40%;
`;

export const MeetingTime = styled.div`
  color: #FF3030;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  text-align:right;
  width:100%;
`;

// Dynamic button styles based on props
export const MainMeetingButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self:end;
  width: 100%;
  gap: 10px;
`;

export const MeetingButton = styled.div`
  display: flex;
  height: 40px;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  width: ${({ variant }) => {
    switch (variant) {
      case 'cancel':
        return '50px'; // Width for Cancel button
      case 'message':
        return '90px'; // Width for Message button
      case 'calendar':
        return '150px'; // Width for Calendar button
      default:
        return 'auto'; // Default width
    }
  }};
  background: ${({ variant }) => {
    switch (variant) {
      case 'cancel':
        return 'transparent'; // Background for Cancel button
      case 'message':
        return 'rgba(79, 68, 155, 0.1)'; // Background for Message button
      case 'calendar':
        return '#4F449B'; // Background for Calendar button
      default:
        return 'transparent'; // Default background
    }
  }};
  color: ${({ variant }) => {
    switch (variant) {
      case 'cancel':
        return '#333232'; // Color for Cancel button
      case 'message':
        return '#4F449B'; // Color for Message button
      case 'calendar':
        return '#fff'; // Color for Calendar button
      default:
        return '#333232'; // Default color
    }
  }};
  border: ${({ variant }) => {
    switch (variant) {
      case 'cancel':
        return '1px solid #EDEDED'; // Border for Cancel button
      case 'message':
        return 'none'; // No border for Message button
      case 'calendar':
        return 'none'; // No border for Calendar button
      default:
        return '1px solid #EDEDED'; // Default border
    }
  }};
  
  span {
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: 500;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;