import styled from "styled-components";
import { Scrollable, headerSize, panelSpacing } from "../../../../../common/styles";
import colors from "../../../../../config/colors";


export const borderRadius=panelSpacing/2;
const scrollbarWidth=borderRadius;

export const RequestListContainer = styled.div`
  width: 95%;
  margin-left: 20px;
  margin-top: 20px;
  overflow: scroll;
`;

export const RequestsKeyHeader = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-align:${props=> props.textAlign && 'justify'};
    padding-right: ${props=>props.padding ? props.padding : '0'}rem;
  @media (max-width: 991px) {
    width: ${props=>props.widthSize ? props.widthSize : '200px'}
  text-overflow: ellipsis;
  }
  margin: 0;
`;
export const RequestsKeyHeaderlocation = styled.span`
  flex-shrink:0;
  overflow-wrap: break-word;
  font-size:16px;
  font-weight:400;
  color:#A6A6A6;
`;
export const RequestsKeyHeaderspan = styled.span`
  ${'' /* overflow-wrap: anywhere; */}
  max-height:60px;
  word-break: break-word;
  overflow-y:auto;
  width:400px;
  text-align:${props=> props.textAlign && 'justify'};
  font-weight:normal !important;
  margin-left:${props=> props.left && '5px'};

${props=>props.height  ? Scrollable :''};
 ::-webkit-scrollbar {
        background-color:${colors.secondary};
        border:solid 1px ${colors.darkBackground};
        width:5px;
        border-radius:0px 0px 10px 0px;

     
      }
       ::-webkit-scrollbar-thumb {
    background: ${colors.primary};
    border-radius: 0 ${scrollbarWidth}px ${scrollbarWidth}px 0;
    box-shadow: inset ${scrollbarWidth/3}px 0 ${scrollbarWidth/2}px #0004;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    }


`;
export const RequestsKeyHeaders = styled.p`
font-style:${props=>props.fontStyles ? props.fontStyles : ''} ;
margin-top:${props=>props.marginSpace ? props.marginSpace : ''}px ;
  white-space: nowrap;
  text-align:${props=> props.textAlign && 'justify'};
  font-weight: ${props=>props.isTeam ? '400': 'bold'};
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 991px) {
    width:${props=>props.widthSize ? props.widthSize : '200'}px ;
    font-size:${props=>props.size ? props.size : ''}px ;
  }
  margin: 0;
  padding-right: 5px;
`;

export const RequestsDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row !important; 
  text-align:left !important;
  max-width: ${props=>props.width ? props.width :''}rem;
  font-style:${props=>props.fontStyles ?props.fontStyles : ''} ;
  font-weight:${props=>props.isTeam && '400'} ;
  @media (max-width:767px) {

    font-size: 16px ;
  font-weight: 400 ;
  }
  
`;
export const RequestsDetailsWrapperForAdditional = styled.div`
  display: flex;
  flex-direction: column !important; 
  text-align:left !important;
  max-width: ${props=>props.width ? props.width :''}rem;
  font-style:${props=>props.fontStyles ?props.fontStyles : ''} ;
  font-weight:${props=>props.isTeam && '400'} ;
  @media (max-width:767px) {
    font-size: 16px ;
  font-weight: 400 ;
  }
`;

export const RequestsDetailsWrappers = styled.div`
  display: flex;
  flex-direction: column !important;
  max-width: ${props=>props.width ? props.width :''}rem;
  font-style:${props=>props.fontStyles ?props.fontStyles : ''} ;
  font-weight:${props=>props.isTeam && '400'} ;
  @media (max-width:767px) {

    font-size: 16px ;
  font-weight: 400 ;
  }
  
`;
export const TimeSection = styled.div`
  ${'' /* display: flex; */}
  @media (max-width:767px) {
  ${'' /* flex-direction: column; */}

  }
  
`;

export const Spacer = styled.div`
  margin-top: 0.5rem;
`;

export const RequestListWrapper = styled.div`
  width:100%;
  border: 2px solid #efefef;
  border-inline: none;
  padding: 40px 20px;
  margin-bottom: 7px;
  margin-top: 10px;
  display:flex;
  font-weight: 400;
  @media (max-width:1200px) {
        width: -webkit-fill-available;
    padding: 22px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap:5px;
    font-size: 14px;
    text-align: justify;
}
`;
export const ButtonWrapper = styled.div`
display: flex;
justify-content: flex-start;
gap: 15px;
margin-left:10px
`;
export const Clander = styled.div`
  position: relative;
  z-index: 9999; /* Adjust the z-index value as needed */
  margin-top:-7px;

  $ #atcb-btn-1{
    background: red !important;
  }
`;


export const PersonInfo = styled.div`
  justify-content: "flex-start";
  display: flex;
  gap: 15px;
  align-items: center;
 width:${props=>props.size}%;
  max-width:${props => props.isTeam ? '90%' :'500px'}; 
  @media (max-width:767px) {
    flex-direction: column;
    align-items: flex-start;
  }

`;

export const ImagePreview = styled.img`
  width:${props=>props.size}px;
  height:${props=>props.size}px;
  border-radius: 50%;
  object-fit: cover
`;
export const ImageContainer = styled.div`
  width:65px;
  height:65px;
  border-radius: 50%;
  border:2px solid #5E99F9;
`;

export const InfoWrapper = styled.div``;
export const StatusWraper = styled.div`
  font-weight: bold;
  
`;
export const HeadingWraper = styled.div`
  font-weight: normal;
  font-size: 16px;
`;

export const RequestHeading = styled.div`
  margin-left: 200px;
  font-size: 16px;
  font-weight: bold;
  @media (max-width:767px) {
    margin-left: 0;
  }
`;
export const OpenNetworkContainer = styled.div`
`;
export const RequestListsWrapper = styled.div`
    border: 2px solid #efefef;
    padding: 15px 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-left: none;
    border-right: none;
    text-align:left;


`;
export const CardsWraper= styled.div`

`
export const NoprogramContainer= styled.div`
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const NoProgramContainer= styled.div`
    display: flex;
    justify-content: ${props => props.isProgram ? "center" : ""};
    align-items: ${props => props.isProgram ? "center" : ""};
    height: ${props => props.isProgram ? "100%" : ""};
`

export const NoProgramContainerInner = styled.div`
    max-width: 684px;
    width: 100%;
    min-height: 517px;
    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 1600px) {
      max-width: 580px;
      min-height: 470px;
      margin: ${props => props.isProgram ? "" : "0 auto"};
    }
    @media (max-width: 1400px) {
      min-height: ${props => props.isProgram ? "470px": "360px"};
    }
    @media (max-width: 640px) {
        max-width: 85%;
        min-height: auto;
        padding: 35px 10px;
    }

    @media (max-width: 480px) {
        max-width: 90%;
        padding: 30px 10px;
    }
`

export const EmptyState = styled.img`
    @media (max-width: 1600px) {
      height: 270px;
    }
    @media (max-width: 640px) {
      height: 220px;
    }
    @media (max-width: 480px) {
      height: 190px;
    }
`
export const ButtonsWraper= styled.div`
  display:flex;
  padding-top: 20px;
  margin-inline: 8rem;
  justify-content: flex-start;
`
export const ImagePreviews= styled.img`
  width:${props=>props.size}px;
  height:${props=>props.size}px;
  margin-bottom:${props=>props.panelSpacing ?props.panelSpacing:panelSpacing}px;
  display:block;
  border-radius:${panelSpacing}px;
  object-fit:cover;
  padding-top: 40px;
`
export const NameContainer= styled.span`
  font-size:18px;
  font-weight:500;
  color:#302F2F;
  text-transform:capitalize;
`
export const ProgramInfo= styled.div`
  display:block;
`
export const ProgramLoaderContainer = styled.div`
    background-color:${colors.background};
    border-radius:${panelSpacing}px;
    box-sizing:border-box;
    height:calc(100vh - ${headerSize+2*panelSpacing}px);
`;

export const MeetingWith = styled.span`
      display: block;
      font-size: 14px;
      font-weight: 300;
`;


export const NoProgramText = styled.h3`
  color: #302F2F;
  text-align: center;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 32px 0 0;
  @media (max-width: 640px) {
    margin: 32px 0 5px;
    font-size: 16px;
  }
`
export const NoProgramDescription = styled.p`
  color: #A8A5A5;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  @media (max-width: 640px) {
    font-size: 14px;
  }
`
export const NoProgramButton = styled.div`
  color: #FFF;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 5px;
  background: #4D4D9F;
  cursor: pointer;
  padding: 9px 5px;
  margin-top: 30px;
  @media (max-width: 1600px) {
    padding: 9px 12px;
    margin-top: 25px;
  }
  @media (max-width: 640px) {
    padding: 9px 11px;
    margin-top: 20px;
    font-size: 16px;
  }
`
// new styles layout for PROGRAMOUTBOUNDS JS
export const MainMeetingProfile = styled.div`
  background-color:#fff;
  -webkit-box-shadow:2px 1px 14px 1px rgba(0,0,0,0.15);
  -moz-box-shadow:2px 1px 14px 1px rgba(0,0,0,0.15);
  box-shadow:2px 1px 14px 1px rgba(0,0,0,0.15);
  padding:15px 25px;
  margin:10px;
  border-radius:10px;
  margin-top:30px;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  width:100%;
  box-sizing:border-box;
`;

export const MeetingDetailCol = styled.div`
  display:flex;
  flex-wrap:wrap;
  max-width:750px;
  width:55%;
`;

export const MeetingPersonThumb = styled.div`
  display:flex;
  width:65px;
  height:65px;
  border-radius:50%;
  border:2px solid #5E99F9;
  overflow:hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const MeetingDetail = styled.div`
  display:flex;
  flex-wrap:wrap;
  max-width: 600px;
  margin-left:12px;
`;

export const MeetingPersonTitle = styled.div`
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 500;
`;

export const MeetingPersonProgram = styled.div`
  color: #A6A6A6;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
  display:block;
  width:100%;
  text-transform:capitalize;
  margin:5px 0 10px 0;
`;

export const MeetingDetailInfo = styled.div`
  display:flex;
  flex-wrap:wrap;
  align-items:flex-start;
  width:100%;
  margin-bottom:5px;
`;

export const MeetingDetailLabel = styled.div`
  color: #A6A6A6;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
  display:flex;
`;

export const MeetingDetailIcon = styled.div`
  margin-right:10px;
`;

export const MeetingDetailComment = styled.div`
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 500;
  display:flex;
  margin:0 0 0 5px;
`;

export const MeetingZoomLink = styled.div`
  color: #393CF6;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 500;
  text-decoration-line: underline;
  margin-left:5px;
  display:flex;
`;

export const MeetingRightCol = styled.div`
  display:flex;
  flex-wrap:wrap;
  max-width:400px;
  width:40%;
`;

export const MeetingTime = styled.div`
  color: #FF3030;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  text-align:right;
  width:100%;
`;

// Dynamic button styles based on props
export const MainMeetingButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self:end;
  width: 100%;
  gap: 10px;
`;

export const MeetingButton = styled.div`
  display: flex;
  height: 40px;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  width: ${({ variant }) => {
    switch (variant) {
      case 'cancel':
        return '50px'; // Width for Cancel button
      case 'message':
        return '90px'; // Width for Message button
      case 'calendar':
        return '150px'; // Width for Calendar button
      default:
        return 'auto'; // Default width
    }
  }};
  background: ${({ variant }) => {
    switch (variant) {
      case 'cancel':
        return 'transparent'; // Background for Cancel button
      case 'message':
        return 'rgba(79, 68, 155, 0.1)'; // Background for Message button
      case 'calendar':
        return '#4F449B'; // Background for Calendar button
      default:
        return 'transparent'; // Default background
    }
  }};
  color: ${({ variant }) => {
    switch (variant) {
      case 'cancel':
        return '#333232'; // Color for Cancel button
      case 'message':
        return '#4F449B'; // Color for Message button
      case 'calendar':
        return '#fff'; // Color for Calendar button
      default:
        return '#333232'; // Default color
    }
  }};
  border: ${({ variant }) => {
    switch (variant) {
      case 'cancel':
        return '1px solid #EDEDED'; // Border for Cancel button
      case 'message':
        return 'none'; // No border for Message button
      case 'calendar':
        return 'none'; // No border for Calendar button
      default:
        return '1px solid #EDEDED'; // Default border
    }
  }};
  
  span {
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: 500;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;
