import React from "react";
import Button from "../Button";
import { AccordianContainer, SectionContainer, SectionHeader, SectionInfo, SectionContent } from "./styles";

function Accordian(
  { 
    sections, 
    multiOpen = false, 
    defaultOpen = false, 
    collapsible = true,
    isTask=false, 
    height = true,
    backgroundColor = "white",
    padding = "10px",
    border= "0",
  }) {
  const [activeSections, setActiveSections] = React.useState(defaultOpen ? [...Array(sections.length).keys()]:[]);
  function switchToSection(index) {
    if(!multiOpen){
      if(index !== activeSections[0]){
        setActiveSections([index])
      }
      else{
        setActiveSections([])
      }
    }
    else{
      if(activeSections.includes(index)){
        setActiveSections(activeSections.filter(section=>section!=index))
      }
      else{
        setActiveSections([...activeSections,index])
      }
    }
  }
  return <AccordianContainer 
    isTask={isTask} 
    height={height}
    backgroundColor={backgroundColor}
    padding={padding}
    border={border}
  >
    {sections &&
      sections.map((section, index) => {
        let isActive = activeSections.includes(index)
        return <SectionContainer key={index} isTask={isTask}>
          <SectionHeader>
            <SectionInfo isFullWidth={!collapsible}>{section.title}</SectionInfo>
            {collapsible && <Button
              onClick={() => {
                switchToSection(index)
              }}
              variant="dark"
              icon={isActive ? "collapse" : "expand"}
            />}
          </SectionHeader>
          {isActive && <SectionContent>
            {section.content}
          </SectionContent>}
        </SectionContainer>
      })
    }
  </AccordianContainer>;
}
export default Accordian;