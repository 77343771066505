import React from 'react';
import {
    StatusContainer,
    Name,
    ProgramTitle,
} from './style';

const StatusSection = ({ item, isMyMente, isMyMentor, iamMentor, programOneOnOne, params }) => {
  const renderName = () => {
    if (item?.program?.programType !== "TEAM") {
      if (item?.meeting?.status === "ACCEPT") {
        if (programOneOnOne && params.connectionId && !iamMentor) {
          return `${item?.Mentor?.firstName} ${item?.Mentor?.lastName}`;
        }
        if (item?.meeting?.status === "ACCEPT" && (isMyMente || (programOneOnOne && params.connectionId))) {
          return `${item?.meeting?.user?.firstName} ${item?.meeting?.user?.lastName}`;
        }
        if (item?.meeting?.status === "ACCEPT" && !isMyMente) {
          return `${item?.Mentor?.firstName} ${item?.Mentor?.lastName}`;
        }
      }
    } else if(item?.program?.programType === "TEAM"){
        return;
    } else {
      if (item?.status === "ACCEPT" && isMyMente) {
        return `${item?.user?.firstName} ${item?.user?.lastName}`;
      }
      if (item?.status === "ACCEPT" && !isMyMente) {
        return `${item?.Mentor?.firstName} ${item?.Mentor?.lastName}`;
      }
    }
    return item?.meeting?.status &&  "Unaccepted";
  };

  return (
    <StatusContainer>
      <Name>{renderName()}</Name>
      <ProgramTitle>{item?.program?.title}</ProgramTitle>
    </StatusContainer>
  );
};

export default StatusSection;
