import React from "react";
import styled from "styled-components";
import Button from "../Button";
import colors from "../../config/colors";

const DropdownContainer = styled.div`
  position: relative;
`;

const DropwdownMenu = styled.div`
position: absolute;
bottom: 43px;
left: 0;
right: 0;
background: #FFFFFF; 
border-radius: 8px;
opacity: ${({active}) => active ? '1' : '0'};
pointer-events: ${({active}) => active ? 'auto' : 'none'} ;
overflow: hidden;
box-shadow:0 -2px 10px rgba(0,0,0,.2);
`;

const MenuItem = styled.div`
  padding: 12px;
  color: #000;
  cursor: pointer;
  &:hover {
    background-color: ${colors.secondary};
  }
`;

const DropDown = ({ title ,id, selectedItemId,  options, handler, icon, isActive= false, handleDropdown,variant,isDisable }) => {
  return (
    <>
      <DropdownContainer>
        <Button
          text={title}
          variant={variant ? "primary":"secondary"}
          isNotRound={true}
          icon={variant ? "tick" : icon}
          onClick={handleDropdown}
          disabled={isDisable}
          isTH={true}
        />
       
        { selectedItemId===id &&
        (<>
        <DropwdownMenu active={isActive} >
          {options?.length > 0 && 
            options.map((el, i) => (
              <MenuItem key={i} onClick={() => handler(el.type)}> 
                {el.title} 
              </MenuItem>
            ))}
        </DropwdownMenu>
        </>)
        }
      </DropdownContainer>
    </>
  );
};

export default DropDown;
