import React from "react";
import colors from "../../config/colors";
import Icon from "../Icon";
import { CheckboxContainer, TheBox, LinkContent, LinkText } from "./styles";
import { useNavigate } from "react-router-dom";

function Checkbox(
  {
    label,
    value,
    setValue,
    disabled = false,
    isLinkContent,
    link,
    size,
    isAgree,
    isModal,
  },
  isBorder = false
) {
  const navigate = useNavigate();
  return (
    <CheckboxContainer isAgree={isAgree} isModal={isModal}>
      <TheBox
        isBorder={isBorder}
        isAgree={isAgree}
        checked={value}
        disabled={disabled}
        onClick={() => {
          !disabled && setValue(!value);
        }}
      >
        <Icon
          type="tick"
          size={size ? `${size}px` : "14px"}
          color={colors.background}
        />
      </TheBox>
      {link ? (
        <>
          <LinkContent>
            {label}
            {link && <>&nbsp;</>}
            {link && (
              <LinkText
                onClick={() => {
                  navigate(link);
                  window.location.reload();
                }}
              >
                {isLinkContent}
              </LinkText>
            )}
          </LinkContent>
        </>
      ) : (
        <>
          {label}
          {link && <>&nbsp;</>}
          {link && (
            <LinkText
              onClick={() => {
                navigate(link);
                window.location.reload();
              }}
            >
              {isLinkContent}
            </LinkText>
          )}
        </>
      )}
    </CheckboxContainer>
  );
}
export default Checkbox;
