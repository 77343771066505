import React, { useState } from "react";
import {
  MeetingsRecordOuterContainer,
  MeetingRecordInnerContainer,
  MeetingUpperRow,
  MeetingUpperHead,
  MeetingHeadTitle,
  MeetingInfo,
  MeetingInfoInner,
  MeetingInner,
  MeetingRecord,
  RecordTitle,
  RecordValue,
  MeetingUpdated,
  MeetingBottomRow,
  NewMeetings,
  NewMeetingHead,
  NewMeetingTitle,
  NewMeetingButton,
  UnacceptedMeetings,
  CompletedMeetings,
} from "./style";
import Icon from "../../../common/Icon";
import DashboardMeetings from "./Tables/DashboardMeetings";
import Meetings from "./Meetings";
import { Context } from "../../../Store";
import callAPI from "../../../api/apiCaller";
import { useParams } from "react-router-dom";
import { formatDateForMeetings } from "../../../common/utils";

export default function Activity() {
  const [currentPage, setCurrentPage] = useState("UPCOMING");
  const [meetingsDetail, setMeetingDetail] = useState(true);
  const [upcomingMeetings, setUpcomingMeetings] = useState(null);
  const [completedMeetings, setCompletedMeetings] = useState(null);
  const [unacceptedMeetings, setUnacceptedMeetings] = useState(null);
  const { dispatch } = React.useContext(Context);
  const params = useParams();
  React.useEffect(() => {
    callAPI(dispatch, "ProgramNewRequestForManager", {
      id: params.selectedProgram,
    }).then((res) => {
      if (res.isResponseOk) {
        setUpcomingMeetings(res.data);
      }
    });
    return () => {
      setUpcomingMeetings(null);
    };
  }, []);
  React.useEffect(() => {
    callAPI(dispatch, "ProgramCompleteRequestForManager", {
      id: params.selectedProgram,
    }).then((res) => {
      if (res.isResponseOk) {
        setCompletedMeetings(res.data);
      }
    });
    return () => {
      setCompletedMeetings(null);
    };
  }, []);
  React.useEffect(() => {
    callAPI(dispatch, "ProgramUnFullFiledRequestForManager", {
      id: params.selectedProgram,
    }).then((res) => {
      if (res.isResponseOk) {
        setUnacceptedMeetings(res.data);
      }
    });
    return () => {
      setUnacceptedMeetings(null);
    };
  }, []);

  // const lastCompMeeting =
  //   completedMeetings &&
  //   formatDateForMeetings(
  //     completedMeetings[completedMeetings.length - 1].createdAt
  //   );

  // console.log(lastCompMeeting, " lastCompMeeting");
  return (
    <>
      <MeetingsRecordOuterContainer>
        <MeetingRecordInnerContainer>
          {meetingsDetail ? (
            <>
              <MeetingUpperRow>
                <MeetingUpperHead>
                  <MeetingHeadTitle>Meetings</MeetingHeadTitle>
                  {/* <MeetingExport>
                    <Icon type={"export"} />
                    <MeetingExportText>Export</MeetingExportText>
                    <Icon type={"chevron-down"} />
                  </MeetingExport> */}
                </MeetingUpperHead>
                <MeetingInfo>
                  <MeetingInfoInner>
                    <MeetingInner>
                      <Icon type={"new-meetings"} />
                      <MeetingRecord>
                        <RecordTitle>New Meetings</RecordTitle>
                        <RecordValue>
                          {upcomingMeetings && upcomingMeetings.length}
                        </RecordValue>
                      </MeetingRecord>
                    </MeetingInner>
                    <MeetingUpdated>
                      Updated:{" "}
                      {upcomingMeetings &&
                        upcomingMeetings.length > 0 ? 
                        formatDateForMeetings(
                          upcomingMeetings[upcomingMeetings.length - 1]
                            .createdAt
                        ): formatDateForMeetings(Date())}
                    </MeetingUpdated>
                  </MeetingInfoInner>
                  <MeetingInfoInner>
                    <MeetingInner>
                      <Icon type={"completed-meetings"} />
                      <MeetingRecord>
                        <RecordTitle>Completed Meetings</RecordTitle>
                        <RecordValue>
                          {completedMeetings && completedMeetings.length}
                        </RecordValue>
                      </MeetingRecord>
                    </MeetingInner>
                    <MeetingUpdated>
                      Updated:{" "}
                      {completedMeetings &&
                        completedMeetings.length > 0 &&
                        formatDateForMeetings(
                          completedMeetings[completedMeetings.length - 1]
                            .createdAt
                        )}
                    </MeetingUpdated>
                  </MeetingInfoInner>
                  <MeetingInfoInner>
                    <MeetingInner>
                      <Icon type={"unaccepted-meetings"} />
                      <MeetingRecord>
                        <RecordTitle>Unaccepted Meetings</RecordTitle>
                        <RecordValue>
                          {unacceptedMeetings && unacceptedMeetings.length}
                        </RecordValue>
                      </MeetingRecord>
                    </MeetingInner>
                    <MeetingUpdated>
                      Updated:{" "}
                      {unacceptedMeetings &&
                        unacceptedMeetings.length > 0 &&
                        formatDateForMeetings(
                          unacceptedMeetings[unacceptedMeetings.length - 1]
                            .createdAt
                        )}
                    </MeetingUpdated>
                  </MeetingInfoInner>
                </MeetingInfo>
              </MeetingUpperRow>
              <MeetingBottomRow>
                <NewMeetings>
                  <NewMeetingHead>
                    <NewMeetingTitle>New Meetings</NewMeetingTitle>
                    <NewMeetingButton
                      onClick={() => {
                        setMeetingDetail(false);
                        setCurrentPage("UPCOMING");
                      }}
                    >
                      See all
                    </NewMeetingButton>
                  </NewMeetingHead>
                  <DashboardMeetings currentPage={"UPCOMING"} />
                </NewMeetings>
                <CompletedMeetings>
                  <NewMeetingHead>
                    <NewMeetingTitle>Completed Meetings</NewMeetingTitle>
                    <NewMeetingButton
                      onClick={() => {
                        setMeetingDetail(false);
                        setCurrentPage("COMPLETE");
                      }}
                    >
                      See all
                    </NewMeetingButton>
                  </NewMeetingHead>
                  <DashboardMeetings currentPage={"COMPLETE"} />
                </CompletedMeetings>
                <UnacceptedMeetings>
                  <NewMeetingHead>
                    <NewMeetingTitle>Unaccepted Meetings</NewMeetingTitle>
                    <NewMeetingButton
                      onClick={() => {
                        setMeetingDetail(false);
                        setCurrentPage("PAST");
                      }}
                    >
                      See all
                    </NewMeetingButton>
                  </NewMeetingHead>
                  <DashboardMeetings />
                </UnacceptedMeetings>
              </MeetingBottomRow>
            </>
          ) : (
            <Meetings
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setMeetingDetail={setMeetingDetail}
            />
          )}
        </MeetingRecordInnerContainer>
      </MeetingsRecordOuterContainer>
    </>
  );
}
