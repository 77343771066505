import React from "react";
import Button from "../../../../../common/Button";
import { AddNewQuestionContainer } from "./styles";

const AddNewQuestionButton = ({ role, applicationForm, setApplicationForm }) => {
  const key = role.toLowerCase();

  const addNewQuestion = () => {
    setApplicationForm({
      ...applicationForm,
      [key]: [
        ...applicationForm[key],
        {
          id: applicationForm[key].length,
          question: "",
          questionType: "short",
          questionFor: role.toLowerCase(),
        },
      ],
    });
  };

  return (
    <AddNewQuestionContainer>
      <Button
        text={`Add New Question for ${role}`}
        icon="add"
        isNotRound
        onClick={addNewQuestion}
      />
    </AddNewQuestionContainer>
  );
};

export default AddNewQuestionButton;
