import React from "react";
import Button from "../../../../../../common/Button";
import DropDown from "../../../../../../common/drop-down/DropDown";
import { ActionButtonContainer } from "./style";
import { ButtonWrapper } from "../style";

const ActionButtons = ({
  item,
  navigate,
  iamMentor,
  handleAddToCalender,
  setShowForm,
  setIsDeleteModal,
  setSelectedMeeting,
  selectedItemId,
  isCalenderDropdownActive,
  setIsCalenderDropdownActive,
  isDeleteRequest,
  reassignMentee,
  disableButtonId,
  isLoading,
  isUpcoming,
}) => {
  return (
    <ActionButtonContainer>
      {!isUpcoming && item?.meeting?.status === "ACCEPT" && (
        <ButtonWrapper>
          <Button
            text={"Message"}
            variant="secondary"
            // disabled={!validateIsValid(isValid)}
            onClick={() => {
              navigate(
                `/messages/${
                  !iamMentor ? item?.Mentor?.uuid : item?.meeting?.user?.uuid
                }`
              );
            }}
            isNotRound={true}
            icon={"chat"}
          />
          {
            <Button
              text={"Track Hours"}
              variant={
                item?.meeting?.trackHourAssigned === true
                  ? "primary"
                  : "secondary"
              }
              disabled={
                item?.meeting?.trackHourAssigned === true ? true : false
              }
              isTH={true}
              onClick={() => reassignMentee(item)}
              isNotRound={true}
              icon={
                item.id === disableButtonId && isLoading
                  ? "loading"
                  : item?.meeting?.trackHourAssigned === true
                  ? "tick"
                  : "time"
              }
            />
          }
        </ButtonWrapper>
      )}
      {isUpcoming && (
        <>
          {item?.meeting?.status !== "PENDING" ? (
            <>
              <DropDown
                id={item.id}
                title={
                  item.addedToCalender ? "Added to Calendar" : "Add To Calendar"
                }
                options={[
                  { type: "google", title: "Google" },
                  { type: "ical", title: "iCal" },
                ]}
                icon="calendar"
                isActive={isCalenderDropdownActive}
                handleDropdown={() =>
                  setIsCalenderDropdownActive(!isCalenderDropdownActive)
                }
                selectedItemId={selectedItemId}
                handler={handleAddToCalender(item)}
                variant={item.addedToCalender}
                isDisable={item.addedToCalender}
              />
              <Button
                text={"Message"}
                variant="secondary"
                onClick={() =>
                  navigate(
                    `/messages/${
                      !iamMentor
                        ? item?.Mentor?.uuid
                        : item?.meeting?.user?.uuid
                    }`
                  )
                }
                isNotRound={true}
                icon={"chat"}
              />
            </>
          ) : (
            <Button
              text={"Edit"}
              variant="secondary"
              onClick={() => setShowForm(item)}
              isNotRound={true}
              icon={"edit"}
            />
          )}
          <Button
            text={"Cancel"}
            variant="secondary"
            onClick={() => {
              setIsDeleteModal(true);
              setSelectedMeeting(item);
            }}
            isNotRound={true}
            icon={"cross"}
          />
        </>
      )}
    </ActionButtonContainer>
  );
};

export default ActionButtons;
