import styled from "styled-components";

export const MainTabsOuter = styled.div`
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);
  margin:15px;
  padding:25px;
`;

export const TabsWrapper = styled.div`
  display: flex;
  justify-content:center;
  border-radius: 5px;
  background: rgba(68, 68, 155, 0.10);
  width:110px;
  min-height:36px;
  margin:20px auto 25px auto;
  padding:2px;
  `;

export const TabButton = styled.button`
  background-color: ${({ isActive }) => (isActive ? "#fff" : "")};
  // color: ${({ isActive }) => (isActive ? "#fff" : "#000")};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;

  // &:hover {
  //   color: white;
  // }
`;

export const SurveyWrapper = styled.div`
  margin: 20px 0;
`;

export const SurveyCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  h3 {
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 700;
    padding:0;
    margin:0 0 5px 0;
  }
  h4 {
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 400;
    padding:0;
    margin:0 0 5px 0;
  }
  h5 {
    color: #3DAE1A;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 500;
    padding:0;
    margin:0 0 5px 0;
  }
  span {
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 400;
    margin-top:30px;
    display:inline-block;
    span {
      font-weight:700;
    }
  }
`;

export const TaskWrapper = styled.div`
  margin: 20px 0;
`;

export const TaskCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
`;

export const Button = styled.button`
  background-color: #44449B;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width:190px;
  height:40px;
  display: flex;
  align-items: center;
  padding: 5px 8px;
  gap: 8px;
  margin-left:auto;
  svg {
  }
  span {
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 500;
  }
  &:hover {
    background-color: #514abf;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  border-radius: 20px;
  background: #FFF;
  border:0;
  width: 570px;
  min-height:460px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  position:relative;
    @media (max-width: 768px) {
      width:96%;
      margin:0 2%;
    }
  
  p {
    color: #302F2F;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 20px;
    font-weight: 600;
  }
`;

export const ModalHeader = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  width:100%;
  box-sizing:border-box;
  border-bottom:1px solid #ededed;
  padding:20px 25px 15px 25px;
  margin:0 0 32px 0;
`;

export const ModalCloseHeader = styled.div`
  display: flex;
  justify-content:center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color:#EDEDED;
  border-radius:50%;
  border:0;
  position:relative;
`;

export const CloseButton = styled.span`
  position: absolute;
  cursor: pointer;
`;

export const ModalTitle = styled.span`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 700;
      @media ( max-width: 767px ) {
        font-size:15px;
      }
`;

export const OptionsHeading = styled.div`
  display:inline-block;
  width:100%;
  margin-bottom:25px;

  span {
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 20px;
    font-weight: 600;
    text-align:center;
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  justify-content:space-between;
  flex-wrap:wrap;
  width:300px;
  margin:0 auto;
`;

export const ModalOption = styled.div`
  display: flex;
  flex-wrap:wrap;
  justify-content: center;
  padding:0;
  margin:0;
  width:140px;
`;

export const OptionBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  height: 140px;
  border-radius: 20px;
  border: 1px solid rgba(68, 68, 155, 0.15);
  background: #FFF;
  box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);
`;

export const ModalOptionTitle = styled.span`
  display:inline-block;
  width:100%;
  color: #302F2F;
  text-align: center;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 500;
  margin:12px 0 45px 0;
`;

export const Footer = styled.div`
  display: flex;
  flex-wrap:wrap;
  justify-content: space-between;
  width:200px;
  margin:0 auto;
`;

export const ModalButton = styled.button`
  display: flex;
  height: 40px;
  padding: 5px 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 5px;
  border:0;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  ${({ variant }) =>
    variant === "cancel"
      ? `
    border: 1px solid #EDEDED;
    background: transparent;
    color: #333232;
    width:80px;
  `
      : `
    background: #44449B;
    color: #fff;
    width:100px;
  `}
`;

export const SurveyFormContainer = styled.div`
  position:absolute;
  left:0;
  right:0;
  top:10%;
  margin:0 auto;
  width: 727px;
  height: 90%;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 36.571px 365.714px 0px rgba(221, 218, 218, 0.25);
  overflow-y:scroll;
  overflow-x:hidden;
    @media (max-width: 767px) {
      width:96%;
      margin:0 2%;
    }
`;

export const SurveyFormContent = styled.div`
  padding:0 25px 25px 25px;

  span {
    display:inline-block;
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin:0 0 10px 0;
  }
`;

export const SurveyInput = styled.input`
  display: flex;
  width: 350px;
  height:22px;
  padding: 8px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #ECECF5;
  background: #F8F8F8;
    @media (max-width: 767px) {
      width:100%;
    }
`;

export const SurveyToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin:35px 0 50px 0;
`;

export const SurveyToggleButtons = styled.div`
  display: flex;
  width: 90px;
  height: 40px;
  padding: 0 6px;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: rgba(68, 68, 155, 0.10);
`;

export const SurveyToggleButton = styled.button`
  width:40px;
  height:34px;
  padding: 5px 10px;
  border: none;
  border-radius: 80px;
  background: #FFF;
  background-color: ${({ isActive }) => (isActive ? "#FFF" : "transparent")};
  color: ${({ isActive }) => (isActive ? "white" : "#333")};
  cursor: pointer;
  span {
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 400;
    margin:0;
    padding:0;
  }
`;

export const SurveyButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width:160px;
  margin: 50px auto 0;
`;

export const SurveyButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size:16px;
  font-weight: 400;
  cursor: pointer;
  ${({ variant }) =>
    variant === "cancel"
        ? `
      background-color: #f0f0f0;
      color: #333;
    `
        : variant === "addNew"
        ? `
      border-radius: 3px;
      background: rgba(68, 68, 155, 0.10);
      color:#44449B;
      margin:0 auto;
      display:flex;
      justify-content:center;
      width:190px;
    `
        : `
      background-color: #6c63ff;
      color: white;
    `}

`;

export const QuestionContainer = styled.div`
  padding-bottom: 30px;
`;

export const QuestionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
    @media (max-width: 767px) {
      flex-wrap:wrap;
      width:100%;
    }
`;

export const QuestionCol = styled.div`
  display:block;
  width:100%;
  span {
    display:flex;
    flex-wrap:wrap;
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 600;
    svg {
      display: flex;
      justify-content:center;
      align-items: center;
      width: 20px;
      height: 20px;
      background-color:#EDEDED;
      border-radius:50%;
      border:0;
      position:relative;
      margin-left:5px;
    }
  }
`;

export const QuestionText = styled.input`
  display: flex;
  width: 360px;
  padding: 8px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid #ECECF5;
  background: #F8F8F8;
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
    @media (max-width: 767px) {
      width:100%;
    }
`;

export const Select = styled.select`
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width:158px;
    
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  height:24px;
  margin-bottom: 20px;
  span {
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 400;
    margin:0;
  }
`;

export const OptionInput = styled.input`
  margin-right: 10px;
  width:24px;
  height:24px;
  border:1px solid #d1d1d1;
  border-radius: 5px;
`;

export const DeleteButton = styled.button`
  color: #393CF6;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: none;
  border: none;
  cursor: pointer;
`;

export const DropdownContainer = styled.div`
  position: relative;
  width: 255px;
  @media (max-width: 767px) {
      margin-top:10px;
    }
`;

export const DropdownButton = styled.button`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
    svg {
      margin-right:5px;
    }
`;

export const DropdownArrow = styled.div`
  display:flex;
  justify-self:flex-end;
  margin-left:auto;
`;

export const DropdownMenu = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: white;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 10;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

export const DropdownMenuItem = styled.li`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content:flex-start;
  cursor: pointer;
    svg {
      width:20px;
      height:20px;
      margin-right:10px;
    }
    &:hover {
      background-color: #f0f0f0;
    }
`;

export const CompletedSurveyContainer = styled.div`
  position:absolute;
  left:0;
  right:0;
  top:10%;
  margin:0 auto;
  width: 727px;
  min-height:300px;
  max-height:80%;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 36.571px 365.714px 0px rgba(221, 218, 218, 0.25);
  overflow-y:scroll;
  overflow-x:hidden;
  z-index:99;
    @media ( max-width: 767px ) {
      width:96%;
      margin:0 2%;
    }
`;

export const CompletedSurveyContent = styled.div`
  padding-bottom:25px;
`;

export const SearchContainer = styled.div`
  display: flex;
  width: 340px;
  height: 40px;
  margin: 0px 15px 10px 15px;
  align-items: center;
  gap: 10px;
  position:relative;
    svg {
      position:absolute;
      left:5px;
      top:5px;
    }
`;
  
export const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #EDEDED;
  padding:8px 35px;
`;

export const SurveyTableOuter = styled.div`
  overflow-x:scroll;
`;

export const CompletedSurveyTable = styled.table`
  width: calc( 100% - 30px );
  border-collapse: collapse;
  margin: 0 15px;
  border-radius: 5px;
  border: 1px solid #EDEDED;
    @media ( max-width: 767px ) {
      width:800px;
    }
`;

export const CompletedSurveyTableHeader = styled.th`
  text-align: left;
  padding: 10px;
  border-radius: 5px 5px 0px 0px;
  border: 1px solid #EDEDED;
  background: #FBFBFB;
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 500;
`;

export const CompletedSurveyTableRow = styled.tr`
  // &:nth-child(even) {
  //   background-color: #f9f9f9;
  // }

  // &:hover {
  //   background-color: #f1f1f1;
  // }
`;

export const CompletedSurveyTableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #EDEDED;
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 16px;
  line-height:1;
  font-style: normal;
  font-weight: 400;
    img {
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
      padding:0 0 0 5px;
    }
    svg {
      padding: 5px 6px;
      border-radius:50%;
      background: rgba(76, 75, 154, 0.10);
      width:20px;
      height:20px;
      position:relative;
      top:8px;
      right:5px;
    }
`;

export const CompletedSurveyRoleBadge = styled.span`
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background:transparent;
  background-color:none;
`;

export const CompletedSurveyActionButton = styled.button`
  background-color: #4f46e5;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s ease;
  border-radius: 100px;
  background: #44449B;
  color: #FFF;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
    &:hover {
      background-color: #3b82f6;
    }
`;

export const CompletedSurveyCloseButton = styled.button`
  margin: 50px auto 25px auto;
  display:flex;
  justify-content:center;
  display: flex;
  width: 100px;
  line-height: 40px;
  border-radius: 5px;
  border: 1px solid #DCDCDC;
  background-color:transparent;
  cursor:pointer;
    &:hover {
      background-color: #d4d4d4;
    }
`;









export const OnemauveReviewModalContainer = styled.div`
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 400px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
`;

export const OnemauveReviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin:0 25px 20px;
`;

export const OnemauveReviewProfile = styled.div`
  display:flex;
  justify-content:space-between;
  flex-wrap:wrap;
  width:100%;
`;

export const OnemauveReviewProfileInfo = styled.div`
  display:flex;
  flex-wrap:wrap;
  align-items:flex-start;
  width:60%;
  margin:0 0 25px 0;
`;

export const OnemauveReviewProfileThumb = styled.div`
  width:50px;
`;

export const OnemauveReviewProfileTitle = styled.div`
  margin-left:15px;
    color: #302F2F;
    font-family: "Nunito Sans";
    h3 {
      font-size: 20px;
      font-weight: 500;
      margin:0;
    }
    p {
      font-size: 16px;
      font-weight: 300;
      margin:0;
    }
`;

export const ProfileInfo = styled.div`
  display:flex;
  flex-wrap:wrap;
  width:100%;
  margin:0 0 25px 0;
   span, a {
      display:inline-block;
      width:100%;
      color: #302F2F;
      font-family: "Nunito Sans";
      font-size: 16px;
      font-weight: 300;
      text-decoration: none;
    }
`;

export const OnemauvereviewDate = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-self:flex-start;
  width:calc( 100% - 65% );
  max-width:160px;
  small, span {
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 400;
  }
  span {
    font-weight: 600;
  }
`;

export const OnemauveReviewSection = styled.div`
  margin:0 25px 20px;
`;

export const OnemauveReviewLabel = styled.div`
  margin-bottom: 10px;
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 300;
`;

export const OnemauveReviewOptionGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
`;

export const OnemauveReviewOption = styled.label`
  display: flex;
  align-items: center;
  gap:20px;
    input {
      border-radius: 5px;
      border: 1px solid #D1D1D1;
      width:24px;
      height:24px;
    }
    span {
      color: #302F2F;
      font-family: "Nunito Sans";
      font-size: 16px;
      font-weight: 400;
    }
`;

export const OnemauveFooter = styled.div`
  text-align: right;
  margin-top: 20px;
`;

export const OnemauveReviewButton = styled.button`
  display: flex;
  justify-content:center;
  align-items:center;
  width: 110px;
  height: 40px;
  padding: 5px 16px;
  margin:50px auto 30px auto;
  border-radius: 5px;
  border: 1px solid #EDEDED;
  background-color: transparent;  
  cursor: pointer;
    &:hover {
      background: ${(props) => (props.variant === "close" ? "#bbb" : "#0056b3")};
    }
`;

export const ProductExportButton = styled.button`
  display: flex;
  justify-content:center;
  width: 110px;
  height: 40px;
  background-color:transparent;
  align-items: center;
  margin:0 25px 25px auto;
  cursor: pointer;
  color: #333232;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 400;
  gap:10px;
    svg {
      width:20px;
      height:20px;
    }
`;

export const ProductExperienceBox = styled.div`
  min-height: 250px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);
  margin:0 25px 25px 25px;
  padding:15px;
  box-sizing:border-box;
`;

export const ProductBoxDetail = styled.div`
  display:flex;
  flex-wrap:wrap;    
  justify-content:space-between;
`;

export const ProductBoxTitle = styled.h3`
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 400;
`;

export const ProductDownloadButton = styled.a`
  display: flex;
  width: 96px;
  padding: 5px 4px;
  align-items: center;
  gap: 6px;
  border-radius: 5px;
  border: 1px solid #EDEDED;
  cursor:pointer;
  color: #333232;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 400;
    svg {
      width:26px;
      height:26px;
    }
`;

export const ProductBoxInner = styled.div`
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  gap:55px;
    span {
      color: #302F2F;
      font-family: "Nunito Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
        @media ( max-width: 767px ) {
          margin-top:10px;
        }
    }
`;

export const PrdocutBoxGraph = styled.div`
  display:flex;
  align-items:center;
  gap:10px;
    @media ( max-width: 767px ) {
      flex-wrap:wrap;
      ul {
        margin-top:15px;
      }
    }
    li {
      color: #302F2F;
      font-family: "Nunito Sans";
      font-size: 14px;
      font-weight: 400;
      list-style-type:none;
      position:relative;
      padding-left:15px;
      margin-bottom:5px;
        &:before {
          content:'';
          position:absolute;
          left:0;
          top:5px;
          width:9px;
          height:9px;
          background-color:#4D4D9F;
          border-radius:50%;
        }
    }
`;

export const ProductBoxEmails = styled.div`
  ul {
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    margin:10px 0 0 0;
    padding:0;
    gap:10px;
  }
  li {
    flex: 1 1 calc(50% - 10px);
    border-radius: 5px;
    background: #F4F4F4;
    padding:10px;
    margin:0 0 10px 0;
    box-sizing:border-box;
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 700;
    list-style-type:none;
  }
`;