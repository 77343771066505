import React, { useState } from "react";
import {
  MeetingsWrapper,
  MeetingItem,
  AcceptButton,
  MeetingHeading,
  MeetingHead,
  MeetingDescription,
  Meetings,
} from "./style";
import { formatDateForMeetingsUnixTimestamp, formatTimeRange, dateToUnixTimeStamp } from "../utils";
import Icon from "../Icon";

const MeetingTable = ({
  eventData,
  acceptMeeting,
  isArchive,
  requestId,
  meetingId,
}) => {
  const getEasternTimeZoneLabel = () => {
    return "Eastern Time - US & Canada";
  };
  const timeZoneLabel = getEasternTimeZoneLabel();
  const today = new Date();
  return (
    <MeetingsWrapper>
      <MeetingHead border={eventData.length <= 0}>Available</MeetingHead>
      <Meetings>
        {eventData.map((meeting, index) => {
          const meetingDate = Number(meeting);
          return (
            <MeetingItem id={index}>
              <MeetingHeading>60 Minute Meeting</MeetingHeading>
              <MeetingDescription>
                <Icon type={"calendar"} />
                {formatDateForMeetingsUnixTimestamp(meeting)}
              </MeetingDescription>
              <MeetingDescription>
                🕒 {formatTimeRange(meeting)}
              </MeetingDescription>
              <MeetingDescription>🌐 {timeZoneLabel}</MeetingDescription>
              <AcceptButton
                onClick={() =>
                  acceptMeeting(
                    isArchive ? requestId : meetingId, meetingDate
                  )
                }
                disabled={dateToUnixTimeStamp(today) > meetingDate ? true : false}
              >
                Accept
              </AcceptButton>
            </MeetingItem>
          );
        })}
      </Meetings>
    </MeetingsWrapper>
  );
};

export default MeetingTable;
