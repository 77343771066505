import styled from "styled-components";

export const TooltipOuter = styled.div`
    border-radius: 10px;
    background: #302F2F;
    padding: 13px 15px;
    position: absolute;
    left: ${props => props.isStep === 2 ? "27px" : props.isStep === 3 ? "76px" : props.isStep === 4 ? "168px" : "-7px"};
    top: ${props => props.isStep === 4 ? "43px" : "36px"};
    width: 100%;
    z-index: 3;
    width: 256px;
    @media (max-width:1600px) {
        left: ${props => props.isStep === 2 ? "27px" : props.isStep === 3 ? "76px" : props.isStep === 4 ? "148px" : "-7px"};
    }
    @media (max-width:480px) {
        width: 170px;
        padding: 13px 12px;
        left: ${props => props.isStep === 2 ? "22px" : props.isStep === 3 ? "55px" : props.isStep === 4 ? "148px" : "-7px"};
    }
`;
export const TooltipCloseIcon = styled.div`
    display: flex;
    justify-content: flex-end;

`;

export const IconWrapper = styled.div`
    cursor: pointer; 
`;
export const TooltipText = styled.p`
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.08px;
    margin: 16px 0 22px;
    @media (max-width:480px) {
        margin: 5px 0 10px;
        font-size: 14px;
    }
`;
export const TooltipBottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const Number = styled.span`
    color: #FFF;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.07px;
`;
export const TooltipButton = styled.div`
    border-radius: 5px;
    background: #FFF;
    cursor: pointer;
    color: #4D4D9F;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    min-height: 30px;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TooltipNotch = styled.div`
    position: absolute;
    top: -10px;
    left: 6px;
    background: #302F2F;
    width: 30px;
    height: 20px;
    clip-path: polygon(50% 0%, 0 100%, 100% 100%);
`;