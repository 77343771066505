import React from "react";
import { Context } from "../../../../Store";
import {
  getProgramListRefreshToggle,
  getProgramsList,
  isProgramsListLoaded,
} from "../../../../Store/ProgramsList/accessors";
import {
  refreshProgramList,
  setProgramList,
  setProgramListAsLoading,
} from "../../../../Store/ProgramsList/actions";
import callAPI from "../../../../api/apiCaller";
// import Button from "../../../../common/Button";
import { ButtonSpacer } from "../../../../common/Button/styles";
import Labelize from "../../../../common/Labelize";
import Loading from "../../../../common/Loading";
import Modal from "../../../../common/Modal";
import SearchTextInput from "../../../../common/TextInput/SearchTextInput";
import TextInput from "../../../../common/TextInput";
import { validateUrl } from "../../../../common/utils";
import { SectionActions } from "../../Profile/LoginSecurity/styles";
import AddProgram from "../popups/AddProgram";
import AddFolder from "./AddFolder";
import ProgramsListSection from "./ProgramsListSection";
import { getUserInfo } from "../../../../Store/User/accessors";
import EditImage from "./edit.png";
import DropdownImage from "./dropdown.png";
import {
  Image,
  CrossButtonContainer,
  CrossButtonContainerSpan,
  HeaderLine,
  JoinPorgramContainer,
  ListActions,
  ProgramSections,
  ProgramsListContainer,
  ProgramsListHeader,
  Button,
  JoinButton,
  SampleFolderOuter,
  FolderDropDown,
  Text,
  FolderOptions,
  EditDeleteOption,
  OpenCloseOption,
} from "./styles";
import Add from "./add.png";
import Folder from "./folder.png";
import Tooltip from "../../../../common/Tooltip";

function ProgramsList(props) {
  const [searchText, setSearchText] = React.useState("");
  const [showAddFolder, setShowAddFolder] = React.useState(false);
  const { state, dispatch } = React.useContext(Context);
  const [showAddProgram, setShowAddProgram] = React.useState(false);
  const [refreshList, SetRefreshList] = React.useState(false);
  const [showJoinModal, setShowJoinModal] = React.useState(false);
  const [programUrl, setProgramUrl] = React.useState("");
  const [urlValid, setIsUrlValid] = React.useState(false);
  const [closeTour, setCloseTour] = React.useState(true);
  const [step, setStep] = React.useState(
    parseInt(getUserInfo(state).tourStepsCompleted, 10)
  );
  const StepOne = `Welcome ${getUserInfo(state).firstName} ${
    getUserInfo(state).lastName
  }, click here to create a new program.`;
  const StepTwo =
    "Click here to create a new folder that you can then add programs into.";
  const StepThree = "Click here to join an exciting mentorship program";
  const StepFour =
    "To edit/delete a folder, click the option icon and choose the action you want to perform.";

  function getPrograms() {
    dispatch(setProgramListAsLoading());
    callAPI(dispatch, "fetchPrograms").then((programs) => {
      dispatch(setProgramList(programs.data));
    });
  }
  React.useEffect(() => {
    dispatch(setProgramListAsLoading());
    getPrograms();
  }, [getProgramListRefreshToggle(state)]);

  function moveProgram(id, folder) {
    dispatch(setProgramListAsLoading());
    callAPI(dispatch, "moveProgram", {
      programId: +id,
      folderTitle: folder,
    }).then((response) => {
      getPrograms();
      // dispatch(refreshProgramList())
    });
  }
  function addFolder(name) {
    dispatch(setProgramListAsLoading());
    return callAPI(dispatch, "addFolder", { title: name });
  }

  const programs = getProgramsList(state);

  const updateSteps = () => {
    callAPI(dispatch, "updateStepCompleted", {
      id:getUserInfo(state).id,
      step: step + 1,
    });
  };

  const markTourComplete = () => {
    callAPI(dispatch, "markTourCompleted", {
      id:getUserInfo(state).id,
    }).then(() => {
      setCloseTour(false);
    });
  };

  return (
    <ProgramsListContainer hamburgerOpen={props.hamburgerOpen}>
      <CrossButtonContainer
        onClick={() => props.setHamburgerOpen(!props.hamburgerOpen)}
      >
        <CrossButtonContainerSpan></CrossButtonContainerSpan>
        <CrossButtonContainerSpan></CrossButtonContainerSpan>
      </CrossButtonContainer>
      <ProgramsListHeader>
        <HeaderLine>
          Programs
          <ListActions>
            <Button
              onClick={() => {
                setShowAddProgram(true);
              }}
            >
              <Image src={Add} alt="Add" />
            </Button>
            <Button onClick={() => setShowAddFolder(true)}>
              <Image src={Folder} alt="Folder" />
            </Button>
            <JoinButton onClick={() => setShowJoinModal(true)}>Join</JoinButton>
            {getUserInfo(state).isTourCompleted || step > 3 ? null : (
              <Tooltip
                text={step === 3 ? StepThree : step === 2 ? StepTwo : StepOne}
                step={step}
                buttonText={"Next"}
                isStep={step}
                setStep={setStep}
                onClick={updateSteps}
                onClose={markTourComplete}
              />
            )}
          </ListActions>
        </HeaderLine>
        <SearchTextInput
          value={searchText}
          setValue={setSearchText}
          placeholder="Search"
        />
      </ProgramsListHeader>
      {step === 4 && !getUserInfo(state).isTourCompleted ? (
        <SampleFolderOuter>
          <FolderDropDown>
            <Text>Sample Folder</Text>
            <FolderOptions>
              <EditDeleteOption src={EditImage} alt="Edit/Delete" />
              <OpenCloseOption src={DropdownImage} alt="Dropdown" />
            </FolderOptions>
          </FolderDropDown>
          {step === 4 && (
            <Tooltip
              text={StepFour}
              step={step}
              buttonText={"Got it!"}
              isStep={step}
              setStep={setStep}
              onClick={markTourComplete}
              onClose={markTourComplete}
            />
          )}
        </SampleFolderOuter>
      ) : null}
      <ProgramSections>
        {isProgramsListLoaded(state) &&
          Object.keys(programs)
            .filter((folder) => {
              return (
                searchText == "" ||
                programs[folder].some((programs) =>
                  programs.title
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
                )
              );
            })
            .map((folder) => {
              return (
                <ProgramsListSection
                  moveProgram={moveProgram}
                  key={folder}
                  searchText={searchText}
                  folder={folder}
                  programs={programs[folder]}
                  setHamburgerOpen={props.setHamburgerOpen}
                  hamburgerOpen={props.hamburgerOpen}
                />
              );
            })}
        {!isProgramsListLoaded(state) && <Loading loadingItem="Programs" />}
      </ProgramSections>
      {showAddFolder && (
        <AddFolder
          addFolder={addFolder}
          closeAddFolder={() => {
            setShowAddFolder(false);
          }}
        />
      )}

      {showAddProgram && (
        <AddProgram
          closeModal={(shouldRefresh) => {
            if (shouldRefresh) {
              dispatch(refreshProgramList());
            }
            setShowAddProgram(false);
          }}
        />
      )}
      {showJoinModal && (
        <Modal
          title={"Join a program"}
          isDelete={true}
          onClose={() => setShowJoinModal(false)}
        >
          <JoinPorgramContainer>
            <Labelize label="Paste the link for the program you would like to join space below">
              <TextInput
                placeholder="Paste your link here"
                value={programUrl}
                type="text"
                isValid={validateUrl["text"]}
                validator={validateUrl}
                setValue={(newValue) => {
                  setIsUrlValid(validateUrl(newValue));
                  setProgramUrl(newValue);
                }}
              />
            </Labelize>
            <SectionActions>
              <Button
                icon={"cross"}
                variant="secondary"
                onClick={() => setShowJoinModal(false)}
                text="Cancel"
              />
              <ButtonSpacer />
              <Button
                icon="tick"
                variant="primary"
                disabled={urlValid ? false : true}
                onClick={() => (window.location.href = programUrl)}
                text="Save"
              />
            </SectionActions>
          </JoinPorgramContainer>
        </Modal>
      )}
    </ProgramsListContainer>
  );
}
export default ProgramsList;
