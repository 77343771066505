import styled from "styled-components";
import { Scrollable } from "../../../common/styles";

export const ActivityContainer = styled.div`
display:flex;
justify-content:center;
align-items:center;
margin-top: 1rem;
`;
export const MeetingsRecordOuterContainer = styled.div`
    margin: 0 12px; 
`;

export const MeetingRecordInnerContainer = styled.div`
    height: calc(100vh - 165px);
    ${Scrollable}
`;
export const MeetingUpperRow = styled.div`
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);
    min-height: 229px;
    padding: 16px 24px;
    @media( max-width: 1500px ) {
        min-height: max-content;
        padding: 15px 12px;
    }
`;
export const MeetingUpperHead = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const MeetingHeadTitle = styled.h3`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
`;
export const MeetingExport = styled.button`
    height: 44px;
    padding: 5px 8px;
    display: flex;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid #EDEDED;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background: transparent;
`;

export const MeetingExportText = styled.h3`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
`;

export const MeetingInfo = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 28px;
    @media( max-width: 1500px ) {
        margin-top: 20px;
    }
    @media( max-width: 767px ) {
        flex-wrap: wrap;
    }
`;
export const MeetingInfoInner = styled.div`
    border-radius: 10px;
    border: 1px solid #DBDBDB;
    max-width: 260px;
    width: 100%;
    min-height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media( max-width: 1500px ) {
        max-width: 240px;
    }
    @media( max-width: 767px ) {
        max-width: 47%;
    }
    @media( max-width: 500px ) {
        max-width: 100%;
    }
`;
export const MeetingInner = styled.div`
    padding: 8px;
    display: flex;
    gap: 12px;
`;
export const MeetingRecord = styled.div`
    display: flex;
    flex-direction: column;
    @media( max-width: 1500px ) {
        margin-top: 5px;
    }
`;
export const RecordTitle = styled.h3`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    @media( max-width: 1500px ) {
        font-size: 16px;
    }
`;

export const RecordValue = styled.p`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    @media( max-width: 1500px ) {
        font-size: 35px;
    }
`;
export const MeetingUpdated = styled.h3`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border-top: 1px solid #DBDBDB;
    margin: 0;
    padding: 8px;
`;

export const MeetingBottomRow = styled.div`
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
`;
export const NewMeetings = styled.div`
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);
    min-height: 278px;
    padding: 15px 12px;
    max-width: 100%;
    width: 100%;
    @media( max-width: 1500px ) {
        min-height: max-content;
        max-width: 98%;
    }
    @media( max-width: 900px ) {
        max-width: calc(100% - 25px);
    }
`;
export const NewMeetingHead = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const NewMeetingTitle = styled.h3`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
`;
export const NewMeetingButton = styled.div`
    color: #386CFA;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
`;

export const UnacceptedMeetings = styled.div`
    max-width:48%;
    width: 100%;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);
    min-height: 290px;
    padding: 15px 12px;
    @media( max-width: 1500px ) {
        min-height: max-content;
        max-width: 98%;
    }
    @media( max-width: 900px ) {
        max-width: calc(100% - 25px);
    }
`;
export const CompletedMeetings = styled.div`
    max-width:48%;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);
    min-height: 290px;
    padding: 15px 12px;
    width: 100%;
    @media( max-width: 1500px ) {
        min-height: max-content;
        max-width: 98%;
    }
    @media( max-width: 900px ) {
        max-width: calc(100% - 25px);
    }
`;

export const ButtonWraper= styled.div`
visibility: hidden;
display: none;
` 

export const MeetingDetailContainer= styled.div`
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);
    padding: 16px 24px;
` 

export const MeetingDetailHead= styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    @media (max-width: 767px) {
        margin-bottom: 30px;
    }
` 
export const ButtonText= styled.h3`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
` 
export const BackButton= styled.div`
    border-radius: 5px;
    border: 1px solid #302F2F;
    display: flex;
    gap: 8px;
    width: 100px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
` 

export const MeetingExportContainer = styled.div`
    position: relative;
` 

export const ExportOptions = styled.div`
    position: absolute;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);
    width: 240px;
    right: 0;
    z-index: 11;
` 
export const Option = styled.button`
    background: transparent;
    border: none;
    color: #333232;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    padding: 12px;
    border-bottom: 1px solid #ddd;
    width: 100%;
    text-align: left;
`

export const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 12px;
` 


