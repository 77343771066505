import React from "react";
import {
  TooltipOuter,
  TooltipCloseIcon,
  TooltipText,
  TooltipBottom,
  Number,
  TooltipButton,
  TooltipNotch,
  IconWrapper,
} from "./style";
import Icon from "../Icon";

const Tooltip = ({
  text,
  step,
  buttonText,
  setStep,
  isStep,
  onClick,
  onClose,
}) => {
  return (
    <TooltipOuter isStep={isStep}>
      <TooltipCloseIcon>
        <IconWrapper
          onClick={() => {
            onClose();
            setStep(5);
          }}
        >
          <Icon type="cross" size="12" color="#FFFFFF" />
        </IconWrapper>
      </TooltipCloseIcon>
      <TooltipText>{text}</TooltipText>
      <TooltipBottom>
        <Number>Step {step} of 4</Number>
        <TooltipButton
          onClick={() => {
            onClick();
            setStep(step + 1);
          }}
        >
          {buttonText}
        </TooltipButton>
      </TooltipBottom>
      <TooltipNotch />
    </TooltipOuter>
  );
};

export default Tooltip;
