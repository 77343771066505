import styled from "styled-components";

export const CalendarWrapper = styled.div`
    max-width: 628px;
    min-width: 628px;
    width: 100%;

    .rbc-day-slot .rbc-time-slot{
        border-top: 0;
    }
    .rbc-allday-cell{
        display: none;
    }
    .rbc-time-content {
        border-top: none !important;
    }
    .rbc-time-header.rbc-overflowing {
        border-bottom: 1px solid #ddd;
    }
    .rbc-header{
        border-bottom: 0;
        height: 40px;
        width: 81px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .rbc-button-link{
        color: #302F2F;
        text-align: center;
        font-family: "Nunito Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .rbc-timeslot-group{
        color: #302F2F;
        font-family: "Nunito Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        .rbc-time-slot{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        div:nth-child(2) {
            display: none;
        }
    }
    .rbc-day-slot .rbc-event{
        border-radius: 3px;
        background: #EAE9F1;
        color: #45459C;
        text-align: center;
        font-family: "Nunito Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border: none;
    }
    .rbc-day-slot .rbc-event-content{
        display: none !important;
    }
    .rbc-month-header{
        border-bottom: 1px solid #ddd;
    }
    .rbc-header span{
        color: #302F2F;
        font-family: "Nunito Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .rbc-month-row .rbc-event{
        width: 5px;
        height:5px;
        border-radius: 50%;
        background-color: #4F449B;
        padding: 0;
        margin: 0 auto;
    }
    .rbc-date-cell {
        text-align: center;
        padding: 30px 0 13px 0;
        font-size: 14px;
        font-weight: 400;
    }
    .rbc-off-range {
        visibility: hidden;
    }
    .rbc-off-range-bg{
        background: #FFF;
    }
    .rbc-off-range:hover {
        background: none !important;
    }
`;

export const ToolbarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 16px;
`;

export const TopControls = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 35px;
`;

export const ViewSwitcher = styled.button`
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: transparent;
    padding: 4px;
    color: ${({ active }) => (active ? "#45459C" : "#302F2F")};
    cursor: pointer;
    transition: all 0.3s ease;
    border-bottom: ${({ active }) =>
      active ? "1px solid #45459C" : "1px solid transparent"};
    border-top:0;
    border-left: 0;
    border-right: 0;
`;

export const CurrentLabel = styled.span`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const BottomControls = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 10px;
`;

export const NavigationButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF; /* Blue background */
  border-radius: 5px;
  border: 1px solid #F4F4F4;
  padding: 5px 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  svg {
    font-size: 14px;
    fill: #45459C;
  }
`;

export const TimezoneDropdown = styled.div`
  select {
    border: none;
    cursor: pointer;
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &:focus {
      outline: none;
      border-color: #4a90e2;
    }
  }
`;

export const ControlsLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const CalenderHead = styled.div`
`;
export const HeadText = styled.h3`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
`;
export const HeadBottom = styled.div`
    border-radius: 3px;
    border: 1px solid #F2F2F2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 70px;
    padding: 5px 3px;
    margin: 6px 0;
`;