import styled from "styled-components";
import colors from "../../config/colors";

export const TableContainer = styled.table`
    width: 100%;
    border:${props => props.meetingsDashboard ? "1px solid #DBDBDB" :`solid 1px ${colors.secondary}`};
    height:${props => props.meetingsDashboard ? "max-content":"100%"};
    border-radius:${props => props.meetingsDashboard ? "5px" :"0px 0px 10px 10px"};
    border-spacing: 0;
    min-width: 670px;
    margin-top: ${props => props.meetingsDashboard ? "20px" : ""};
    @media (max-width: 991px) {
    min-width: 810px;
    }
`;
export const TableHead = styled.tr`
    background-color: ${props => props.meetingsDashboard ? "#FBFBFB" : `${colors.secondary}`};
    border-bottom: ${props => props.meetingsDashboard ? "1px solid #DBDBDB": ""}
`;
export const TableHeaderCell = styled.th`
    padding-top:${props => props.meetingsDashboard ? "8px" :"10px"};
    padding-bottom:${props => props.meetingsDashboard ? "8px" :"10px"};
    padding-right:${props => props.meetingsDashboard ? "8px" :"0"};
    padding-left:${props => props.meetingsDashboard ? "8px" :"0"};
    border-bottom: ${props => props.meetingsDashboard ? "1px solid #DBDBDB": ""};
    font-weight:700;
    &:first-child{
        padding-left:${props => props.meetingsDashboard ? "9px" :"25px"};
    }
    ${props=>props.leftHeader && "text-align:left;"};
    :last-child {
        padding-left:${props => props.meetingsDashboard ? "9px" :"20px"};
    }
`;
export const TableHeaderCellContent = styled.div`
    user-select: none;
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    text-align:left;
    color: ${props => props.meetingsDashboard ? "#302F2F":""};
    font-family: ${props => props.meetingsDashboard ? "Nunito Sans": ""};
    font-size: ${props => props.meetingsDashboard ? "16px": ""};
    font-weight:${props => props.meetingsDashboard ? " 500" : ""};
    gap: ${props => props.space && "20px"}
`;
export const TableBody = styled.tbody`
    :last-child {
        border-radius:0px 0px 10px 10px;
        height: ${props => props.isHeightInherited && "inherit"}
    }
`;
export const TableRow = styled.tr`
:last-child td{
    border:none;
}
`;
export const TableCell = styled.td`
    text-align:left;
    height:${props => props.meetings ? "60px" : "40px"};
    align-items:center;
    border-bottom:solid 1px ${colors.secondary};
    font-size:14px;
    width:10%;
    padding: ${props => props.meetingsDashboard ? "0 10px" : ""};
    color: ${props => props.meetingsDashboard ? "#302F2F" : ""};
    font-family:${props => props.meetingsDashboard ? "Nunito Sans": ""};
    font-size: ${props => props.meetingsDashboard ? "16px": ""};
    font-weight: ${props => props.meetingsDashboard ? "400" : ""};
`;
export const TableActions = styled.div`
    padding:0px 5px;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    position:relative;
`;

export const TablePagination = styled.div`
    display:flex;
    justify-content: space-between;
    margin-top: 30px;
`;

export const ResultsLabel = styled.label`
    color: #757575;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const ResultsSelect = styled.select`
    border: none;
    margin-left: 5px;
    outline: none;
`;

export const PaginationSpan = styled.span`
    color: #757575;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const PreviousButton = styled.div`
    color: ${props => props.disabled ? "#757575" : "#1E1E1E"};
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
`;
export const NextButton = styled.div`
    color: ${props => props.disabled ? "#757575" : "#1E1E1E"};
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
`;

export const PaginationButtons = styled.div`
    display: flex;
    gap: 8px;
`;

export const CurrentPage = styled.span`
    border-radius: 5px;
    border: 0.395px solid #9FABC1;
    padding: 2px 7px;
`;
export const AngleSpan = styled.span`
    margin-right: ${props => props.next ? "" : "5px"};
    margin-left: ${props => props.next ? "5px" : "0"};;
`;


