import React, { useContext, useEffect, useState } from "react";
import { formatMessageDate } from "../../../../common/utils";
import { useNavigate, useParams } from "react-router-dom";
import callAPI from "../../../../api/apiCaller";
import { Context } from "../../../../Store";
import { socket } from "../../../../common/chat-socket";
import Button from "../../../../common/Button";
import Modal from "../../../../common/Modal";
import TextInput from "../../../../common/TextInput";
import Labelize from "../../../../common/Labelize";
import { UserInfoContainer } from "../../Programs/ProgramContent/ProgramConnections/ProgramConnection/ConnectionProfile/Collaborate/style";
import { FixedSizeList } from "react-window";
import LazyLoad from "react-lazyload";

import {
  ContactMemberImages,
  ContactName,
  GroupMesssage,
  GroupMesssageWraper,
  ImageContainer,
  Indicator,
  MessageContact,
  MessageInfo,
  MessageListContainer,
  MessageSummary,
  MessageTime,
  TimeContainer,
  UserNotPartOfGroupMesssage,
} from "../MessageList/styles";
import { getUserInfo } from "../../../../Store/User/accessors";
import { markAsReadMessage } from "../../../../Store/UnreadMessage/actions";
import { getUnreadMsg } from "../../../../Store/UnreadMessage/accessors";

const GroupMessageList = ({
  searchText,
  groupMessageList,
  refresh,
  setRefresh,
  getGroupMessage,
  setHamburgerOpen,
  hamburgerOpen,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const { dispatch, state } = useContext(Context);
  const [showEditGroupName, setShowEditGroupName] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [unReadMessage, setunReadMessage] = React.useState(0);
  const [groupName, setGroupName] = useState({
    title: "",
  });

  const CurrentUserId = getUserInfo(state).id;
  function UpdateGroup() {
    setIsLoading(true);
    callAPI(dispatch, "updateGroupName", {
      id: +params.user,
      ...groupName,
    })
      .then((res) => {
        setIsLoading(false);
        setShowEditGroupName(false);
        getGroupMessage();
        setGroupName({
          title: "",
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error updating group:", error);
      });
  }

  const EditGroupName = (item) => {
    setShowEditGroupName(true);
    setGroupName({
      title: item.title ? item.title : item.team.name,
    });
  };

// for unread badge
function hasUnreadMessage(dispatch, setunReadMessage) {
  let unReadMessage, groupMessageIndicator;

  // First, fetch the total unread count of messages
  callAPI(dispatch, "unReadMessage").then((res) => {
      const totalUnreadCount = res.data.totalUnreadCount;
      setunReadMessage(totalUnreadCount);
      unReadMessage = totalUnreadCount; // Store the value for later use

      // After getting the unread count, check for unread group messages
      callAPI(dispatch, "getGroupMessageList").then((res) => {
          const hasUnreadGroupMessage = res.data.some(sms =>
              sms.messages.some(msg =>
                  msg?.userId !== getUserInfo(state)?.id && !msg?.readBy?.includes(getUserInfo(state).id)
              )
          );
          groupMessageIndicator = hasUnreadGroupMessage; // Store the value for later use

          // Dispatch the action after both asynchronous operations complete
          dispatch(markAsReadMessage(unReadMessage, groupMessageIndicator));
      }).catch(error => {
          console.error("Error fetching group messages:", error);
      });
  }).catch(error => {
      console.error("Error fetching unread message count:", error);
  });
}


  const handleInputChange = (key, value) => {
    setGroupName({
      ...groupName,
      [key]: value,
    });
  };

  useEffect(() => {
    socket.on("message", (msg) => {

      if(msg.room == params.user){
        callAPI(dispatch, "markConversationAsRead", {
          id: msg.room,
        }).then((res)=>{
        hasUnreadMessage(dispatch ,setunReadMessage,)
        });
      }
      setRefresh(!refresh);
      getGroupMessage();
    });
  }, [params.user]);

  return (
    <GroupMesssageWraper>
      {groupMessageList && groupMessageList.map((message) => {
          return (
            <div>
              <MessageContact
                onClick={() => {
                  navigate("/messages/group/" + message?.id);

                  // api call
                  const messages = message?.messages;
                  if (messages && messages?.length > 0) {
                    const unreadMessages = messages.filter(
                      (message) => !message?.readBy.includes(CurrentUserId)
                    );
                    
                    if (unreadMessages?.length > 0) {
                      // Call API to mark conversation as read
                      callAPI(dispatch, "markConversationAsRead", {
                        id: message.id,
                      }).then((res)=>{
                      hasUnreadMessage(dispatch ,setunReadMessage,)
                      });
                    }
                    
                  }

                  getGroupMessage();
                  setHamburgerOpen(!hamburgerOpen);
                }}
                active={params.user == message?.id}
                key={message.id}
              >
                <div style={{ display: "flex" }}>
                  <ImageContainer>
                    {message.members.slice(0, 3).map((member, i) => {
                      return (
                        <ContactMemberImages
                          key={i}
                          lastImage={i == 2 ? true : false}
                          width={message.members?.length < 2}
                          src={
                            !member.userLeft && member?.user?.image
                              ? member?.user?.image
                              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          }
                        />
                      );
                    })}
                  </ImageContainer>
                  <MessageInfo>
                    <ContactName>
                      {message.title
                        ? message?.title?.length > 12
                          ? message?.title.slice(0, 13) + "..."
                          : message?.title
                        : message?.team.name && message.team.name?.length > 12
                        ? message?.team.name.slice(0, 12) + "..."
                        : message?.team.name}
                    </ContactName>
                    {message?.userLeft && message?.userLeft ? (
                      <>
                        <UserNotPartOfGroupMesssage isList={true}>
                          {message?.messages
                            ? message?.messages[0]?.message
                            : ""}
                        </UserNotPartOfGroupMesssage>
                      </>
                    ) : (
                      <MessageSummary>
                        {message?.messages &&
                        message?.messages[0]?.message?.length > 28
                          ? message?.messages[0]?.message.slice(0, 27) + "..."
                          : message?.messages[0]?.message}
                      </MessageSummary>
                    )}
                  </MessageInfo>
                </div>
                <TimeContainer>
                  <MessageTime>
                    {message?.lastMessage
                      ? formatMessageDate(message.lastMessage.createdAt)
                      : message?.messages && message.messages[0]
                      ? formatMessageDate(message.messages[0].createdAt)
                      : "Just Now"}
                  </MessageTime>
                  {message.messages.some(
                    (msg) =>
                    msg?.userId !== getUserInfo(state)?.id &&
                      !msg?.readBy?.includes(getUserInfo(state).id)
                  ) && <Indicator />}
                </TimeContainer>
                <Button icon={"edit"} onClick={() => EditGroupName(message)} />
              </MessageContact>
            </div>
          );
        })}
      {showEditGroupName && (
        <Modal
          onClose={() => setShowEditGroupName(false)}
          title={"Update Group Name"}
          actions={[
            {
              text: "Cancel",
              icon: "cross",
              onClick: () => {
                setShowEditGroupName(false);
              },
            },
            {
              text: "Update",
              icon: isLoading === true ? "loading" : "tick",
              onClick: () => {
                UpdateGroup();
              },
            },
          ]}
        >
          <UserInfoContainer>
            <Labelize label="Group Name">
              <TextInput
                value={groupName.title}
                placeholder="Group Name"
                setValue={(value) => handleInputChange("title", value)}
              />
            </Labelize>
          </UserInfoContainer>
        </Modal>
      )}
    </GroupMesssageWraper>
  );
};

export default GroupMessageList;
