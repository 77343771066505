import styled from "styled-components";
import { Scrollable, headerSize, panelSpacing } from "../../../../common/styles";
import colors from "../../../../config/colors";

export const ProgramContainer = styled.div`
    padding-top:${props=> props.Top ? '60%' :'' };
    padding-left:${props=> props.Top ? '10%' :'' };
    ${'' /* background-color:${colors.background}; */}
    ${'' /* border-radius:${panelSpacing}px; */}
    box-sizing:border-box;
    ${'' /* height:calc(100vh - ${headerSize+2*panelSpacing}px); */}
`;

export const Header = styled.div`
    display:flex;
    padding:${panelSpacing}px;
    align-items:center;
    justify-content:space-between;
`;
export const Section = styled.div`
    display:flex;
    align-items:center;
`;
export const Title = styled.div`
    font-size:24px;
    margin-left:20px;
    font-weight:bold;
`;
export const Body = styled.div`
    ${'' /* border-top:solid 1px ${colors.secondary}; */}
    ${'' /* height:calc(100vh - ${headerSize + 2*panelSpacing + 2*panelSpacing + 40}px); */}
    box-sizing:border-box;
    ${'' /* overflow-x:hidden;
    overflow-y:scroll; */}

  ${'' /* @media (max-width: 991px) {
    height:calc(100vh - ${headerSize + 2*panelSpacing + 2*panelSpacing + 290}px);
  } */}
`;
export const TableContainerOuter = styled.div`
    ${'' /* ${Scrollable};
    height: 100%; */}
`;
export const Image = styled.img`
    width: 250px;
    height: 250px;
    object-fit:cover;
    margin-left:${panelSpacing}px;
    border-radius:${panelSpacing}px;
`;
export const Form = styled.div`
    width:calc(100% - 250px - ${panelSpacing}px);
`;

export const Avatar= styled.img`
    width: 24px;
    height: 24px;
    object-fit:cover;
    border-radius:${panelSpacing}px;
`

export const  BookingHeading = styled.div`
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const NoprogramContainer= styled.div`
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const ButtonsWraper= styled.div`
display:flex;
padding-top: 20px;
margin-inline: 8rem;
justify-content: flex-start;
margin-bottom:${prop=>prop.bottom}px;
`

export const RequestsKeyHeaders = styled.p`
font-style:${props=>props.fontStyles ? props.fontStyles : ''} ;
margin-top:${props=>props.marginSpace ? props.marginSpace : ''}px ;
  white-space: nowrap;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: 991px) {
    width:${props=>props.widthSize ? props.widthSize : '200'}px ;
    font-size:${props=>props.size ? props.size : ''}px ;
  }
  margin: 0;
  padding-right: 5px;
  a {
    color: #000; 
    text-transform: capitalize;
    
  }
`;

export const AvatarInfo= styled.div`
  display:flex;
  align-items: center;
  gap: 10px;
  p{
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }
`

export const  MeetingsHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: center;
  @media (max-width: 767px) {
   flex-direction: column;
    gap: 20px;
    margin-top: 30px;
  }
`;

export const  FilterOption = styled.div`
  border-radius: 3px;
  background: #44449B;
  display: flex;
  gap: 12px;
  width:100px;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  cursor: pointer;
`;

export const  FilterText = styled.h3`
  color: #FFF;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;

export const  SearchBoxFilterContainer = styled.div`
  display: flex;
  gap: 11px;
  position: relative;
`;


export const  DropIcon = styled.div`
  cursor: pointer;
`;
export const  DropdownItems = styled.div`
  position: absolute;
  top: 25px;
  left: -135px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);
  z-index: 2;
`;

export const DropdownButton = styled.button`
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ddd;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
    svg {
      margin-right:8px;
    }
`;

export const  RequestedByContainer = styled.div`
    display: flex;
    gap: 20px;
`;

export const  FilterOptions = styled.div`
  position: absolute;
  right: 10px;
  top: 50px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 4px 50px 0px rgba(151, 140, 140, 0.20);
  padding: 10px 12px;
  z-index: 3;
  width: 250px;
`;
export const  FilterHeadText = styled.h3`
  color: #757575;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 10px;
`;
export const  FilterBy = styled.div`
  border-top: 1px solid #E2EBF3;
  border-bottom:  1px solid #E2EBF3;
  padding-bottom: 16px;
`;
export const  FilterLabel = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0 8px;
`;
export const  FilterLabelText = styled.h3`
  color: #757575;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
`;
export const  FilterClear = styled.div`
  color: #757575;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
`;

export const  FilterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;
export const  ResetButton = styled.div`
  border-radius: 5px;
  border: 1px solid #EDEDED;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333232;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 8px;
  cursor: pointer;
`;
export const  ApplyButton = styled.div`
  border-radius: 5px;
  border: 1px solid #EDEDED;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333232;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 8px;
  background: #45459B;
  color: #FFFFFF;
  cursor: pointer;
`;


export const  DateInputs = styled.div`
  display: flex;
`;

export const SearchParticipantsWrapper = styled.div`
  position: relative;
  input{
    padding: 9px 10px 9px 35px;
  }
  svg{
    position: absolute;
    top: 8px;
    left: 5px;
    z-index: 5;
  }
`;

export const TableContainer = styled.div`
  overflow-x: auto;
  overflow-y: auto;
` 
