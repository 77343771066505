import React from "react";
import Modal from "../../../../../common/Modal";
import callAPI from "../../../../../api/apiCaller";
import { Context } from "../../../../../Store";
import { DeleteTaskContainer, DeleteTaskTitle, DeleteTaskInfo } from "./styles";

const TaskDeleteModal = ({ setOpenDeleteModal, taskId, setRefresh }) => {
  const { dispatch } = React.useContext(Context);
  const DeleteTask = () => {
    callAPI(dispatch, "deleteTask", { id: taskId }).then((res) => {
      if (res) {
        setOpenDeleteModal(false);
        setRefresh(true);
      }
    });
  };
  return (
    <Modal
      minWidth={"470px"}
      width={"470px"}
      onClose={() => {
        setOpenDeleteModal(false);
      }}
      actions={[
        {
          text: "Cancel",
          variant: "pending",
          isNotRound: "5px",
          textColor: true,
          onClick: () => {
            setOpenDeleteModal(false);
          },
        },
        {
          text: "Delete",
          variant: "Save",
          isNotRound: "5px",
          onClick: () => {
            DeleteTask();
          },
        },
      ]}
      isButtonCenter
    >
      <DeleteTaskContainer>
        <DeleteTaskTitle>Delete Task</DeleteTaskTitle>
        <DeleteTaskInfo>
          Are you sure you want to delete this task?
        </DeleteTaskInfo>
      </DeleteTaskContainer>
    </Modal>
  );
};

export default TaskDeleteModal;
