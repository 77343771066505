import React from "react";
import Button from "../Button";
import Icon from "../../common/Icon";
import AddEditModalHeader from "../../pages/App/Programs/popups/AddEditTask";
import { ButtonSpacer } from "../Button/styles";
import {
  ModalContainer,
  ModalBackground,
  ModalContent,
  ModalTitle,
  ModalActions,
  IconWrapper,
} from "./styles";

function Modal({
  children,
  title,
  actions,
  onClose,
  fixedWidth,
  width,
  minWidth,
  isWidth,
  isHeight,
  noPadding,
  paddingBottom,
  className,
  actionClass,
  boardView,
  isTask,
  isButtonCenter,
  isMeeting
}) {
  const isAddEditHeader =
    React.isValidElement(title) && title.type === AddEditModalHeader;
  return (
    <ModalContainer
      isWidth={isWidth}
      noPadding={noPadding}
      paddingBottom={paddingBottom}
    >
      <ModalBackground
        onClick={() => {
          onClose();
        }}
      >
        <ModalContent
          className={className}
          isButtonCenter={isButtonCenter}
          onClick={(e) => {
            e.stopPropagation();
          }}
          fixedWidth={fixedWidth}
          width={width}
          minWidth={minWidth}
          isHeight={isHeight}
          noPadding={noPadding}
          paddingBottom={paddingBottom}
          boardView
          isMeeting={isMeeting}
        >
          <ModalTitle isMeeting={isMeeting}>
            {onClose && (
              <IconWrapper
                onClick={() => {
                  onClose();
                }}
              >
                <Icon type="cross" size="12" color="#252421" />
              </IconWrapper>
            )}
            {title}
          </ModalTitle>
          {children}
          {actions && (
            <ModalActions className={actionClass} isMeeting={isMeeting} isButtonCenter={isButtonCenter}>
              {actions.map((action) => {
                return (
                  <>
                    <Button isTask={isTask} {...action} />
                  </>
                );
              })}
            </ModalActions>
          )}
        </ModalContent>
      </ModalBackground>
    </ModalContainer>
  );
}
export default Modal;
