import styled from "styled-components";

// Wrapper for the meetings list
export const MeetingsWrapper = styled.div`
  padding: 75px 0 0 0;
  width: 375px;
  font-family: "Nunito Sans", sans-serif;
`;

export const MeetingHead = styled.h3`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 16px;
    margin: 0;
    border-bottom: ${(props) => props.border && "1px solid #ddd"}
`;

export const MeetingItem = styled.div`
  border-top: 1px solid #ddd;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
`;

// Accept button
export const AcceptButton = styled.button`
    border-radius: 3px;
    background: ${(props) => (props.disabled ? "#757575" : "#4F449B")};
    height: 30px;
    padding: 5px 10px;
    border: none;
    width: 64px;
    color: #FFF;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: ${props => props.disabled ? "" : "pointer"};
    margin-top: 20px;
`;

export const MeetingHeading = styled.h3`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
`;

export const MeetingDescription = styled.div`
    margin-top: 10px;
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    gap: 8px;
`;

export const Meetings = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 656px;
    overflow: auto;
`;
