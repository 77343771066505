import React, { useState } from "react";
import {
  QuestionSection,
  QuestionNumber,
  QuestionType,
  CrossButton,
  QuestionHeader,
  QuestionDropdownOuter,
  DropdownOptionsOuter,
  DropdownOptions,
  DropdownDelete,
  DropdownAdd,
  MultipleChoiceOptions,
  MultipleChoiceRadio,
  MultipleChoiceDelete,
  MultipleChoiceAdd,
  CheckBoxOptions,
  CheckBoxOptionsCheckbox,
  CheckBoxOptionsDelete,
  CheckBoxOptionsAdd,
} from "./styles";
import TextInput from "../../../../../common/TextInput";
import { validateText } from "../../../../../common/utils";
import Icon from "../../../../../common/Icon";
import DropDown from "../../../../../common/drop-down/QuestionDropDown";

const QuestionForm = ({
  role,
  question,
  index,
  applicationForm,
  setApplicationForm,
}) => {
  const key = role.toLowerCase();
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [selectedType, setSelectedType] = useState(question?.multipleQuestion?.type || "Free Response");
  const [options, setOptions] = useState(question?.multipleQuestion?.options || []);

  const handleDropdownToggle = () => {
    setIsDropdownActive((prevState) => !prevState);
  };

  const handleItemClick = (item) => {
    setSelectedType(item.title);
    setIsDropdownActive(false);
    handleQuestionTypeChange(item.title);
    updateForm("multipleQuestion", {
      type: item.title,
      options,
    });
  };

  const questionTypes = [
    { title: "Free Response" },
    { title: "Multiple Choice" },
    { title: "Checkboxes" },
    { title: "Dropdown" },
  ];

  const handleQuestionChange = (newValue) => {
    updateForm("question", newValue);
  };

  const handleQuestionTypeChange = (newValue) => {
    updateForm("questionType", newValue);
  };

  const updateForm = (field, value) => {
    let updatedQuestion = {
      ...applicationForm[key][index],
      [field]: value,
    };

    if (
      (field === "questionType" &&
        ["Multiple Choice", "Checkboxes", "Dropdown"].includes(value)) ||
      ["Multiple Choice", "Checkboxes", "Dropdown"].includes(
        applicationForm[key][index].questionType
      )
    ) {
      updatedQuestion = {
        ...updatedQuestion,
        questionType: updatedQuestion.questionType.toLowerCase().replace(' ', '_'),
        multipleQuestion: {
          type: updatedQuestion.questionType.toLowerCase().replace(' ', '_'),
          options,
        },
      };
    }

    let newApplicationForm = {
      ...applicationForm,
      [key]: applicationForm[key].map((q, cIndex) =>
        cIndex === index ? updatedQuestion : q
      ),
    };

    setApplicationForm(newApplicationForm);
  };

  const handleRemoveQuestion = () => {
    let newApplicationForm = {
      ...applicationForm,
      [key]: applicationForm[key].filter((_, cIndex) => cIndex !== index),
    };
    setApplicationForm(newApplicationForm);
  };

  const handleAddOption = () => {
    const updatedOptions = [...options, ""];
    setOptions(updatedOptions);
    updateForm("multipleQuestion", {
      type: selectedType,
      options: updatedOptions,
    });
  };

  const handleRemoveOption = (i) => {
    const updatedOptions = options.filter((_, index) => index !== i);
    setOptions(updatedOptions);
    updateForm("multipleQuestion", {
      type: selectedType,
      options: updatedOptions,
    });
  };

  const handleOptionChange = (i, value) => {
    const updatedOptions = options.map((opt, index) =>
      index === i ? value : opt
    );
    setOptions(updatedOptions);
    updateForm("multipleQuestion", {
      type: selectedType,
      options: updatedOptions,
    });
  };

  return (
    <QuestionSection>
      <QuestionHeader>
        <QuestionNumber>Question {index + 1}</QuestionNumber>
        <CrossButton onClick={handleRemoveQuestion}>
          <Icon type={"cross"} size="10" color="#302F2F" />
        </CrossButton>
      </QuestionHeader>

      <QuestionType>
        <TextInput
          placeholder="Write your question here..."
          value={question.question}
          setValue={handleQuestionChange}
          isValid={validateText(question.question)}
          setIsValid={() => {}}
        />

        <QuestionDropdownOuter>
          <DropDown
            title={selectedType}
            options={questionTypes}
            handler={handleItemClick}
            isActive={isDropdownActive}
            handleDropdown={handleDropdownToggle}
          />
        </QuestionDropdownOuter>
      </QuestionType>

      {selectedType === "Dropdown" && (
        <DropdownOptionsOuter>
          {options.map((option, i) => (
            <DropdownOptions key={i}>
              <TextInput
                placeholder="Option..."
                value={option}
                setValue={(value) => handleOptionChange(i, value)}
              />
              <DropdownDelete onClick={() => handleRemoveOption(i)}>
                Delete
              </DropdownDelete>
            </DropdownOptions>
          ))}
          <DropdownAdd onClick={handleAddOption}>Add Dropdown Item</DropdownAdd>
        </DropdownOptionsOuter>
      )}
      {selectedType === "Multiple Choice" && (
        <div>
          {options.map((option, i) => (
            <MultipleChoiceOptions key={i}>
              <MultipleChoiceRadio type="radio" disabled />
              <TextInput
                placeholder="Option text..."
                value={option}
                setValue={(value) => handleOptionChange(i, value)}
              />
              <MultipleChoiceDelete onClick={() => handleRemoveOption(i)}>
                Delete
              </MultipleChoiceDelete>
            </MultipleChoiceOptions>
          ))}
          <MultipleChoiceAdd onClick={handleAddOption}>
            Add Option
          </MultipleChoiceAdd>
        </div>
      )}

      {selectedType === "Checkboxes" && (
        <div>
          {options.map((option, i) => (
            <CheckBoxOptions key={i}>
              <CheckBoxOptionsCheckbox type="checkbox" disabled />
              <TextInput
                placeholder="Checkbox text..."
                value={option}
                setValue={(value) => handleOptionChange(i, value)}
              />
              <CheckBoxOptionsDelete onClick={() => handleRemoveOption(i)}>
                Delete
              </CheckBoxOptionsDelete>
            </CheckBoxOptions>
          ))}
          <CheckBoxOptionsAdd onClick={handleAddOption}>
            Add Checkbox
          </CheckBoxOptionsAdd>
        </div>
      )}
    </QuestionSection>
  );
};

export default QuestionForm;
