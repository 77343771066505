import React from "react";
import { useParams } from "react-router-dom";
import { Context } from "../../../../../Store";
import callAPI from "../../../../../api/apiCaller";
import Loading from "../../../../../common/Loading";
import Pills from "../../../../../common/Pills";
import Tabs from "../../../../../common/Tabs";
import { PillsContainer } from "../ProgramConnections/ProgramConnection/ConnectionProfile/styles";
import ProgramInBounds from "../ProgramInBounds";
import ProgramOutBonds from "../ProgramOutBounds";
import { ProgramLoaderContainer } from "../ProgramOutBounds/style";
import { OpenNetworkProgramTabs } from "../ProgramRequests/config";
import Modal from "../../../../../common/Modal";
import {
  CardsWrapper,
  RequestListContainer,
  Requestcontainer,
  TabsContainer,
} from "./styles";
import ActionMenu from "../../../../../common/ActionMenu";
import { getUnacceptedMeetingsCount } from "../../../../../Store/ProgramMeetingCount/accessors";

function RequestList() {
  const [program, setProgram] = React.useState(null);
  const [programMeetingCount, setProgramMeetingCount] = React.useState([]);
  const [MeetingTabCount, setMeetingTabCount] = React.useState([]);
  const params = useParams();
  const [currentTab, setCurrentTab] = React.useState("Upcoming");
  const { state, dispatch } = React.useContext(Context);
  const [showUnfulfilled, setShowUnfulfilled] = React.useState(false);
  const [countUnfulfilled, setCountUnfulfilled] = React.useState(
    getUnacceptedMeetingsCount(state).count
  );

  function retrieveProgramInformation() {
    setProgram(null);
    params.selectedProgram &&
      callAPI(dispatch, "getProgramInformation", {
        id: params.selectedProgram,
      }).then((info) => {
        setProgram(info.data);
        if (info?.data.role === "mentor") {
          setCurrentTab("Requests");
        } else if (info?.data.role === "mentor_manager") {
          setCurrentTab("Requests");
        }
      });
  }

  function specificProgramMeetingsStatistics() {
    callAPI(dispatch, "specificProgramMeetingsCount", {
      id: params.selectedProgram,
    }).then((info) => {
      setProgramMeetingCount(info.data);
    });
  }

  function allProgramMeetingStatistics() {
    callAPI(dispatch, "allProgramsMeetingsCount", {}).then((info) => {
      setMeetingTabCount(info.data);
      setCountUnfulfilled(info.data?.unfulfilledRequests);
    });
  }

  React.useEffect(() => {
    if (params.selectedProgram) {
      specificProgramMeetingsStatistics();
      retrieveProgramInformation();
    } else {
      allProgramMeetingStatistics();
    }
    return () => {
      setProgram(null);
    };
  }, []);

  let options = "";
  if (params.selectedProgram) {
    if (program?.role === "mentor") {
      options = OpenNetworkProgramTabs["OPEN_NETWORK"]["mentor_dashboard"];
    }
    if (program?.role === "mentee") {
      options = OpenNetworkProgramTabs["OPEN_NETWORK"]["mentee_dashboard"];
    }
    if (program?.role === "mentor_manager") {
      options = OpenNetworkProgramTabs["OPEN_NETWORK"]["mentor_manager"];
    }
  } else options = OpenNetworkProgramTabs["OPEN_NETWORK"]["dashboard"];

  const RenderComponent = (currentTab) => {
    // eslint-disable-next-line default-case
    switch (currentTab) {
      case "Requests":
        return (
          <ProgramInBounds
            currentTab={currentTab}
            requests={true}
            allProgramMeetingStatistics={allProgramMeetingStatistics}
            specificProgramMeetingsStatistics={
              specificProgramMeetingsStatistics
            }
          />
        );
      case "Upcoming":
        return (
          <ProgramOutBonds
            isUpcoming={true}
            currentTab={currentTab}
            allProgramMeetingStatistics={allProgramMeetingStatistics}
            specificProgramMeetingsStatistics={
              specificProgramMeetingsStatistics
            }
          />
        );
      case "Past":
        return (
          <ProgramOutBonds
            currentTab={currentTab}
            isPast={true}
            isUnfulfilled={false}
            allProgramMeetingStatistics={allProgramMeetingStatistics}
            specificProgramMeetingsStatistics={
              specificProgramMeetingsStatistics
            }
          />
        );
      case "unfulfilled":
        return (
          <ProgramOutBonds
            currentTab={currentTab}
            isPast={true}
            isUnfulfilled={true}
            allProgramMeetingStatistics={allProgramMeetingStatistics}
            specificProgramMeetingsStatistics={
              specificProgramMeetingsStatistics
            }
          />
        );
      case "Archived":
        return (
          <ProgramInBounds
            isArchive={true}
            currentTab={currentTab}
            allProgramMeetingStatistics={allProgramMeetingStatistics}
            specificProgramMeetingsStatistics={
              specificProgramMeetingsStatistics
            }
          />
        );
    }
  };

  if (!options?.length) {
    return (
      <ProgramLoaderContainer>
        {" "}
        <Loading loadingItem="" />{" "}
      </ProgramLoaderContainer>
    );
  }
  return (
    <>
      {options.length && (
        <>
          <Requestcontainer>
            <RequestListContainer margin={true}>
              {params.selectedProgram ? (
                <PillsContainer meetings={true} isConnection={true}>
                  <Pills
                    meetings={true}
                    isWidth={true}
                    value={currentTab}
                    setValue={setCurrentTab}
                    options={options}
                    count={programMeetingCount}
                  />
                </PillsContainer>
              ) : (
                <TabsContainer>
                  <Tabs
                    value={currentTab}
                    setValue={(newTab) => {
                      setCurrentTab(newTab);
                    }}
                    options={options}
                    count={MeetingTabCount}
                  />
                  <ActionMenu
                    onClick={setShowUnfulfilled}
                    count={countUnfulfilled}
                  />
                </TabsContainer>
              )}
              <CardsWrapper isHome={!params.selectedProgram} >
                {RenderComponent(currentTab)}
              </CardsWrapper>
            </RequestListContainer>
            {/* {currentTab==='Inbound' &&  <NewRequest />} */}
            {showUnfulfilled && (
              <Modal onClose={() => setShowUnfulfilled(false)} isHeight={"70%"}>
                <ProgramOutBonds
                  currentTab={currentTab}
                  isPast={true}
                  isUnfulfilled={true}
                  allProgramMeetingStatistics={allProgramMeetingStatistics}
                  specificProgramMeetingsStatistics={
                    specificProgramMeetingsStatistics
                  }
                />
              </Modal>
            )}
          </Requestcontainer>
        </>
      )}
    </>
  );
}

export default RequestList;
