import styled from "styled-components";
import { borderRadius, panelSpacing } from "../../../../common/styles";
import colors from "../../../../config/colors";

export const ProgramContentContainer = styled.div`
    background-color: #FAFAFA;
    border-radius: ${borderRadius}px;
    width: calc(100vw - 270px);
    @media (max-width: 1600px) {
        width: calc(100vw - 250px);
    }
    @media(max-width:991px) {
        width: 100%;
    }
`;
export const SelectContainer = styled.div`
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const JoinOrApplyButtonContainer = styled.div`
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
`;

export const LabelProgramDashboard = styled.p`
    justify-content: center;
    align-items: center;
    font-weight: 600;
    margin-top:30px;
`;

export const LabelButton = styled.button`
    justify-content: center;
    align-items: center;
    font-weight: 600;
`;

export const JoinPorgramContainer= styled.div`
width: 80vh;
@media (max-width:767px){
width: 100%;
}

`;

export const TabsContainer= styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    flex-direction: row-reverse;
    order-radius: 10px 10px 0px 0px;
    background: #FFF;
    margin: 16px 12px;
    @media (max-width:991px) {
        flex-direction: row;
    }
`

export const HamburgerIconContainer=styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    @media (max-width:991px) {
        margin-top: ${props => props.isProgram ? "" : "12px"};
        margin-right: ${props => props.isProgram ? "25px" : "0"};
        order: ${props => props.isProgram ? "2" : ""};
    }

    @media (max-width:480px) {
        margin-top: 5px;
    }
    
`

export const NoProgramContainer=styled.div`
   padding: 20px;
   position: absolute;
    top: 55px;
    right: 11px;
    @media (max-width:991px) {
        top: 42px;
        right: 40px;
    }
    @media (max-width:480px) {
        right: 0;
    }
`

export const HamburgerIconContainerSpan=styled.span`
  width: 30px;
  height: 4px;
  background-color: #44449B;
  display: none;
  @media (max-width:991px) {
    display: block;
  }
`
export const ProgramTabContainer=styled.div`
    overflow-x: auto;
    margin-left: 20px;
  @media (max-width:991px) {

  }
`