import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {
  ToolbarWrapper,
  TopControls,
  BottomControls,
  NavigationButton,
  ViewSwitcher,
  CurrentLabel,
  TimezoneDropdown,
  ControlsLeft,
} from "./style";

const CustomToolbar = ({ label, view, onNavigate, onView, toolbar }) => {
  const goToBack = () => {
    onNavigate("PREV");
  };

  const goToNext = () => {
    onNavigate("NEXT");
  };

  const goToToday = () => {
    onNavigate("TODAY");
  };

  const onViewChange = (view) => {
    onView(view);
  };

  return (
    <ToolbarWrapper>
      <TopControls>
        <ViewSwitcher
          active={view === "month"}
          onClick={() => onViewChange("month")}
        >
          Month
        </ViewSwitcher>
        <ViewSwitcher
          active={view === "week"}
          onClick={() => onViewChange("week")}
        >
          Week
        </ViewSwitcher>
      </TopControls>

      <BottomControls>
        <ControlsLeft>
          <NavigationButton onClick={goToBack}>
            <FaChevronLeft />
          </NavigationButton>
          <NavigationButton onClick={goToNext}>
            <FaChevronRight />
          </NavigationButton>
          <NavigationButton onClick={goToToday}>Today</NavigationButton>
          <CurrentLabel>{label}</CurrentLabel>
        </ControlsLeft>
        <TimezoneDropdown>
          <select>
            <option value="GMT-4">US Eastern Time (GMT-4)</option>
            <option value="GMT-5">US Central Time (GMT-5)</option>
            <option value="GMT-6">US Mountain Time (GMT-6)</option>
            <option value="GMT-7">US Pacific Time (GMT-7)</option>
          </select>
        </TimezoneDropdown>
      </BottomControls>
    </ToolbarWrapper>
  );
};

export default CustomToolbar;
