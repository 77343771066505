import styled from "styled-components";
import { isMobile } from "../../../common/utils";
import colors from "../../../config/colors";
import { ButtonContainer } from "../../../common/Button/styles";
import { panelSpacing } from "../../../common/styles";

export const HomePanelContainer = styled.div`
    display:flex;
    align-items:flex-end;
    justify-content:space-between;
    height:calc(100vh - 100px);
    ${isMobile() && `
        flex-direction:column;
    ` }
`;

export const DescriptionPanel = styled.div`
    padding:100px;
    width:50%;
    box-sizing: border-box;
    display:flex;
    align-items:flex-end;
    justify-content:center;
    flex-direction:column;
    height:100%;
    ${isMobile() && `
        width:100%;
        padding:${panelSpacing}px;
    ` }
`;
export const Title = styled.div`
    font-size:48px;
    font-weight:bold;
    color:${colors.primary};
    width:100%;
    
`;
export const Description = styled.div`
    font-size:24px;
    margin:50px 0px;
    width:100%;
`;

export const ImagePanel = styled.div`
    background-color:${colors.primary}11;
    height:80%;
    width:40%;
    border-radius:300px 0px 0px 0px;
    display:flex;
    align-items:flex-end;
    justify-content:flex-end;
`;
export const Image = styled.img`
    width:98%;
`;
export const BigButton = styled(ButtonContainer)`
    background-color:${colors.primary};
    padding:${panelSpacing}px
    border-radius:10px;
    color:${colors.background};
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:20px;
`;

export const HomePanelOuterContainer = styled.div`
    background: #FFFFFF;
    padding-top: 165px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1366px) {
        padding: 165px 20px 0;
    }
    @media screen and (max-width: 992px) {
        padding: 140px 20px 0;
    }
    @media screen and (max-width: 767px) {
        padding: 109px 20px 0;
    }
`;

export const BannerHeading = styled.h1`
    font-size: 50px;
    line-height: 56px;
    max-width: 1133px;
    margin: 0 auto;
    text-align: center;
    color:${colors.primary};
    @media screen and (max-width: 1366px) {
        font-size: 38px;
        line-height: 44px;
        max-width: 80%;
        margin: 0 auto;
    }
    @media screen and (max-width: 992px) {
        font-size: 30px;
        line-height: 38px;
    }
    @media screen and (max-width: 767px) {
        max-width: 100%;
    }
`;

export const BannerDescription = styled.p`
    font-size: 24px;
    line-height: 32px;
    max-width: 610px;
    margin: 0 auto;
    text-align: center;
    color:${colors.textBlack};
    margin-top: 24px;
    @media screen and (max-width: 1366px) {
        font-size: 20px;
        line-height: 28px;
        margin-top: 20px;
    }
    @media screen and (max-width: 992px) {
        font-size: 18px;
        line-height: 26px;
        max-width: 80%;
        margin: 20px auto 0;
    }
    @media screen and (max-width: 767px) {
        max-width: 100%;
    }
`;

export const BannerButton = styled.div`
    padding: 17px 13px;
    border-radius: 5px;
    background: #4F449B;
    display: flex;
    color: #FFF;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 40px;
    margin-bottom: 50px;
    cursor: pointer;
    @media screen and (max-width: 1366px) {
        font-size: 18px;
        line-height: 26px;
        padding: 15px 11px;
        margin-top: 25px;
        margin-bottom: 30px;
    }
    @media screen and (max-width: 992px) {
        font-size: 16px;
        line-height: 24px;
        padding: 12px 14px;
        margin-top: 30px;
        margin-bottom: 20px;
    }
`

export const BannerImage = styled.div`
    margin-bottom: -49px;
    @media screen and (max-width: 1600px) {
        max-width: 89%;
        width: 100%;
        margin-bottom: -53px;
    }
    @media screen and (max-width: 1366px) {
        max-width: 85%;
        margin-bottom: -45px;
    }
    @media screen and (max-width: 1366px) {
        max-width: 100%;
    }
    @media screen and (max-width: 990px) {
        margin-bottom: -32px;
    }
    @media screen and (max-width: 767px) {
        margin-bottom: -28px;
    }
    @media screen and (max-width: 640px) {
        margin-bottom: -21px;
    }
    @media screen and (max-width: 480px) {
        margin-bottom: -18px;
    }
`

export const HomePanelImage = styled.img`
    height: 100%;
    width: 100%;
`;