import React from "react";
import Checkbox from "../Checkbox";
import Labelize from "../Labelize";
import { validateArray } from "../utils";
import { CheckboxGroupContainer, CheckboxContainer, CheckboxLabel } from "./styles";

function CheckboxGroup({ value, setValue, label, options, isValid, setIsValid = () => { }, validator = validateArray() }) {
  React.useEffect(() => {
  }, []);
  return <CheckboxGroupContainer>
    <Labelize label={label}>
      {
        options.map((option, index) => {

          return (
            <CheckboxContainer key={index}>
              <Checkbox
                isValid={isValid}
                label={
                  <CheckboxLabel>{option.label}</CheckboxLabel>
                }
                value={value.includes(option.value)}
                setValue={(shouldBePresent) => {
                  let finalValue = []
                  if (!shouldBePresent) {
                    finalValue = value.filter(item => item !== option.value)
                  }
                  else {
                    finalValue = [...value, option.value]
                  }
                  setValue(finalValue)
                  setIsValid(validator(finalValue))
                }} />
            </CheckboxContainer>
          )
        })
      }
    </Labelize>
  </CheckboxGroupContainer>;
}
export default CheckboxGroup;