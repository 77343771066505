import styled from "styled-components";
import colors from "../../../../../../config/colors";
import { panelSpacing } from "../../../../../../common/styles";

export const AnnouncementsContainer = styled.div`
`;

export const AnnouncementHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const Announcer = styled.div`
    display:flex;
    align-items: center;
`;
export const AnnouncerImage = styled.img`
    width:40px;
    height:40px;
    border-radius:50%;
    object-fit:cover;
    border: 2px solid #4F449B;
`;
export const AnnouncementInfo = styled.div`
    margin-left: 10px;
`;
export const AnnouncerName = styled.div`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
export const AnnouncementTime = styled.div`
    color: #757575;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
`;
export const AnnouncementEdit = styled.div`
    margin-left:20px;
`;
export const AnnouncementContent = styled.div`
word-break: break-word;
`;

export const ViewMoreSpan = styled.span`
cursor:pointer;
color: ${colors.primary}
`;