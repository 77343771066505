import styled from "styled-components";
import { Scrollable, panelSpacing } from "../../../../../../../../common/styles";

export const TasksContainer = styled.div`
    padding:${panelSpacing}px;
    display:flex;
    width:100%;
    height:calc(100vh - 340px - ${panelSpacing*2}px);
    ${Scrollable}
    box-sizing:border-box;
`;
export const TaskTitle = styled.div`
    
`;
export const TaskName = styled.div`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 700;
`;

export const CreateTaskDate = styled.div`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 400;
    margin-top:5px;
`;

export const TaskDate = styled.div`
    color: #3DAE1A;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 500;
    color:${props=>props.isDue ? "#3DAE1A" : "green"};
    margin-top:5px;
`;

export const TaskContent = styled.div`
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 400;
`;

export const TaskDescription = styled.div`
    
`;

export const TaskActions = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-top:20px;
`;
export const TaskStatus = styled.div`
    color:${props=>props.isCompleted? "green" : "grey"};
`;