import styled from "styled-components";
import { Scrollable } from "../../../../../common/styles";

export const ApplicationEditorModalContainer = styled.div`
width: 727px;
max-height:70vh;
${Scrollable}
padding-right:20px;
@media(max-width:991px){
    width: 100%;
        height: calc(100% - 100px);

  
}
`;