import styled from "styled-components";
import { panelSpacing, Scrollable } from "../../../../../common/styles";

export const RequestListContainer = styled.div`
width:95%;
margin-left:20px;
margin-top:20px;
overflow:scroll;
`;

export const RequestsKeyHeader = styled.p`
    white-space: nowrap;
    text-overflow:ellipsis;
    overflow: hidden;
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 10px;
    @media (max-width:991px){
        ${'' /* width: 200px; */}
    }
    margin:0;
    padding-right: 5px
`;
export const RequestContent = styled.p`
    overflow: hidden;
    font-weight:normal !important;
    @media (max-width:991px){
        ${'' /* width: 200px; */}
    }
    margin:0;
    padding-right: 5px
`;

export const RequestsDetailsWrapper = styled.div`
display: flex;
margin-top : ${props => props.meeting ? "16px":"8px"};
`;
export const RequestsDetailsComment = styled.div`
display: flex;
flex-direction: column; 
text-align:left !important;
margin-top : 16px;
`;

export const Spacer = styled.div`  
margin-top:2rem; 
`;

export const RequestListWrapper  = styled.div`
width:${props => props.isMeetingTab ?'95%':'90%'};
border: 2px solid #efefef;
border-radius: 10px;
padding:${props => props.isMeetingTab ? "32px 24px": "10px"};
margin-bottom:1rem;
margin: 10px auto;
border-radius: 10px;
background: #FFF;
box-shadow: 0px 3.143px 31.429px 0px rgba(182, 174, 174, 0.25);
position: relative;
@media (max-width: 991px) {
  width:85%;

  }


`
export const ButtonWrapper= styled.div`
  justify-content:'flex-start';
  display:flex;
  align-items: center;
  margin-top: 12px;
  @media (max-width: 767px) {
    flex-direction:column;
  }

`



export const NoprogramContainer= styled.div`
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;

`
export const ButtonsWraper= styled.div`
display:flex;
padding-top: 20px;
margin-inline: 8rem;
justify-content: flex-start;
`
export const ImagePreviews= styled.img`
width:${props=>props.size}px;
height:${props=>props.size}px;
margin-bottom:${props=>props.panelSpacing ?props.panelSpacing:panelSpacing}px;
display:block;
border-radius:${panelSpacing}px;
object-fit:cover;
padding-top: 40px;


`
export const NameContainer= styled.span``
export const ProgramInfo= styled.div`
display:block
`

export const RequestsKeyHeaders = styled.p`
font-style:${props=>props.fontStyles ? props.fontStyles : ''} ;
margin-top:${props=>props.marginSpace ? props.marginSpace : ''}px ;
  white-space: nowrap;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 991px) {
    width:${props=>props.widthSize ? props.widthSize : '200'}px ;
    font-size:${props=>props.size ? props.size : ''}px ;
  }
  margin: 0;
  padding-right: 5px;
`;

export const RequestedHeaderLine= styled.h3`
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
`;

export const CalendarContainer= styled.div`
  display: flex;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 50px 0px rgba(156, 150, 150, 0.20);
  max-width: 1035px;
`
export const AvailabilityContainer = styled.div`
`

export const CalendarLeft = styled.div`
  padding: 16px 16px 50px;
  border-right: 1px solid #ddd;

`
export const DateWrapper = styled.div`
  display: flex;
  gap: 12px;
  position: relative;
  flex-wrap: wrap;
`

export const CalendarOuterContainer = styled.div`
  position: absolute;
  top: 35px;
  z-index: 10;
`
export const PaginationSpan = styled.span`
    color: #757575;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: ${props => props.leftSpace && "8px"}
`;

export const PreviousButton = styled.button`
    color: ${props => props.disabled ? "#757575" : "#1E1E1E"};
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: ${props => props.disabled ? "" : "pointer"};
    background: transparent;
    border: none;
    padding: 0;
`;
export const NextButton = styled.button`
    color: ${props => props.disabled ? "#757575" : "#1E1E1E"};
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
`;

export const PaginationButtons = styled.div`
    display: flex;
    gap: 8px;
`;

export const CurrentPage = styled.span`
    border-radius: 5px;
    border: 0.395px solid #9FABC1;
    padding: 2px 7px;
`;
export const AngleSpan = styled.span`
    margin-right: ${props => props.next ? "" : "5px"};
    margin-left: ${props => props.next ? "5px" : "0"};;
`;

export const Pagination = styled.div`
    display:flex;
    justify-content: space-between;
    margin-top: 30px;
`;

export const ResultsLabel = styled.label`
    color: #757575;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const ResultsSelect = styled.select`
    border: none;
    margin-left: 5px;
    outline: none;
`;

export const ProgramInBoundsContainer = styled.div`
    height: 100%;
    ${Scrollable}
`;
