import styled from "styled-components";
import colors from "../../config/colors";
export const TabsContainer = styled.div`
    display:flex;
    min-width:${props=>props.isWidth && '530px'};

`;
export const Tab = styled.div`
    padding:15px 20px;
    cursor:pointer;
    transition: all 0.1s ease-in-out;
    color: #302F2F;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    user-select: none;
    display: flex;
    align-items: center;
    gap: 10px;
    ${props=>props.active && `
        padding-bottom:10px;
        border-bottom:3px solid ${colors.primary};
        color: #44449B;
    `}
    :hover{
        color: ${colors.primary};
    }
    :active{
        transform: scale(0.9);
    }

    @media (max-width:640px) {
        padding:15px 5px;
        width:${props=>props.isWidth ? '160px':'auto'};
        font-size: 16px;
        justify-content:${props=>props.isWidth && 'center'};
    }

    @media (max-width: 480px) {
        padding:15px 9px;
        font-size: 14px;
    }
    
`;
export const ItemNumber = styled.span`
    color: white;
    font-size: 12px;
    background: #44449B;
    width: 21px;
    height: 21px;
    display: grid;
    place-items: center;
    flex-shrink: 0;
    border-radius: 100%;
    
   
 
`;