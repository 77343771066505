import React from "react";
import { useParams } from "react-router-dom";
import callAPI from "../../../../../api/apiCaller";
import Accordian from "../../../../../common/Accordian";
import Button from "../../../../../common/Button";
import { ButtonSpacer } from "../../../../../common/Button/styles";
import Loading from "../../../../../common/Loading";
import {
  DisplayDate,
  formatOnlyDate,
} from "../../../../../common/utils";
import AddEditTask from "../../popups/AddEditTask";
import TaskMembers from "../../popups/TaskMembers";
import {
  CreateTaskDate,
  TaskContent,
  TaskDate,
  TaskName,
  TaskTitle,
} from "../ProgramConnections/ProgramConnection/ConnectionProfile/Tasks/styles";
import {
  ProgramTasksContainer,
  TasksActions,
  NoTaskContainer,
  NoTaskInner,
  NoTaskImageWrapper,
  Image,
  NoTaskTitle,
  NoTaskDescription,
  NoTaskButtons,
  CreateSurvey,
  CreateTask,
} from "./styles";
import { Context } from "../../../../../Store";
import {
  ContentActions,
  ContentCard,
} from "../../../../../common/Accordian/styles";
import NoTask from "./NoTasks.png";
import TaskDeleteModal from "./taskDeleteModal";

function ProgramTasks() {
  const params = useParams();
  const [tasks, setTasks] = React.useState(null);
  const [showAddEditTask, setShowAddEditTask] = React.useState(false);
  const [showTaskMembers, setShowTaskMembers] = React.useState(false);
  const { dispatch } = React.useContext(Context);
  const [refresh, setRefresh] = React.useState(false);
  const [program, setProgram] = React.useState(null);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [getTaskId , setGetTaskId] = React.useState(null);
  const programInformation = () => {
    callAPI(dispatch, "getProgramInformation", {
      id: params.selectedProgram,
    }).then((info) => {
      setProgram(info.data);
    });
  };

  function getCurrentProgramTask() {
    setTasks(null);
    callAPI(dispatch, "getProgramTasks", { id: params.selectedProgram }).then(
      (res) => {
        if (res.isResponseOk) {
          setTasks(res.data);
        }
      }
    );
  }

  React.useEffect(() => {
    getCurrentProgramTask();
    programInformation();
  }, [refresh]);
  
  return (
    <ProgramTasksContainer>
      {tasks === null && <Loading loadingItem="Tasks" />}
      {tasks?.length === 0 && (
        <>
          <NoTaskContainer>
            <NoTaskInner>
              <NoTaskImageWrapper>
                <Image src={NoTask} alt="No Task" />
              </NoTaskImageWrapper>
              <NoTaskTitle>
                You do not currently have any active tasks or surveys...
              </NoTaskTitle>
              <NoTaskDescription>
                Click the button below to create a new task and survey.
              </NoTaskDescription>
              <NoTaskButtons>
                <CreateSurvey>Create a Survey</CreateSurvey>
                <CreateTask
                  onClick={() => {
                    setShowAddEditTask(true);
                  }}
                >
                  Create Task
                </CreateTask>
              </NoTaskButtons>
            </NoTaskInner>
          </NoTaskContainer>
        </>
      )}
      {tasks != null && tasks.length > 0 && (
        <>
          <TasksActions>
            <Button
              text="Add New Task"
              icon="add"
              variant="primary"
              isNotRound="5px"
              onClick={() => {
                setShowAddEditTask(true);
              }}
            />
          </TasksActions>
          <Accordian
            isTask={true}
            sections={tasks.map((task) => {
              return {
                title: (
                  <TaskTitle>
                    <TaskName>{task.title}</TaskName>
                    <CreateTaskDate>
                      Task Created at: {DisplayDate(task.createdAt)}
                    </CreateTaskDate>
                    <TaskDate
                      isDue={
                        new Date().getTime() >
                        new Date(task.dueDate + "t00:00:00")
                      }
                    >
                      Due on: {formatOnlyDate(task.dueDate)}
                    </TaskDate>
                  </TaskTitle>
                ),
                content: (
                  <>
                    <TaskContent>
                      <ContentCard>{task.description}</ContentCard>
                      {/* {program?.programType === 'TEAM' &&<TaskStatus isCompleted={task.teamTask[0].status === "completed"}>Status: <b>{task.teamTask[0].status && task.teamTask[0].status }</b></TaskStatus>} */}
                    </TaskContent>
                    <ContentActions>
                      <Button
                        text="View Pending"
                        icon={"pendingIcon"}
                        variant="pending"
                        isNotRound="10px"
                        hasShadow={true}
                        onClick={() => {
                          setShowTaskMembers({
                            id: task.id,
                            mode: "pending",
                          });
                        }}
                      />
                      <ButtonSpacer />
                      <Button
                        text="View Completed"
                        icon={"darkViewIcon"}
                        variant="Completed"
                        isNotRound="10px"
                        hasShadow={false}
                        onClick={() => {
                          setShowTaskMembers({
                            id: task.id,
                            mode: "complete",
                          });
                        }}
                      />
                      <ButtonSpacer />
                      <Button
                        text="Edit Task"
                        icon={"edit"}
                        variant="primary"
                        isNotRound="10px"
                        hasShadow={false}
                        onClick={() => {
                          setShowAddEditTask(task.id);
                          setGetTaskId(task.id);
                        }}
                      />
                    </ContentActions>
                  </>
                ),
              };
            })}
          />
        </>
      )}
      {program && showAddEditTask && (
        <AddEditTask
          setOpenDeleteModal={setOpenDeleteModal}
          programType={program.programType}
          getCurrentProgramTask={getCurrentProgramTask}
          editTask={showAddEditTask}
          setShowAddEditTask={setShowAddEditTask}
        />
      )}
      {showTaskMembers && (
        <TaskMembers
          programType={program?.programType}
          taskId={showTaskMembers.id}
          mode={showTaskMembers.mode}
          setShowTaskMembers={(refresh) => {
            if (refresh) {
              setRefresh(!refresh);
            }
            setShowTaskMembers(false);
          }}
        />
      )}
      {openDeleteModal && (
        <TaskDeleteModal
          setOpenDeleteModal={setOpenDeleteModal}
          taskId={getTaskId}
          setRefresh={setRefresh}
        />
      )}
    </ProgramTasksContainer>
  );
}
export default ProgramTasks;
