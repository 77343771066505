import React from "react";
import { PendingRequestsContainer, FilterContainer } from "./styles";
import Loading from "../../../../../../common/Loading";
import callAPI from "../../../../../../api/apiCaller";
import { Context } from "../../../../../../Store";
import { useNavigate, useParams } from "react-router-dom";
import Accordian from "../../../../../../common/Accordian";
import {
  Actions,
  Image,
  Info,
  MentorHeader,
  Meta,
  Name,
} from "../ActiveParticipants/styles";
import { formatDate } from "../../../../../../common/utils";
import { ButtonSpacer } from "../../../../../../common/Button/styles";
import Button from "../../../../../../common/Button";
import Labelize from "../../../../../../common/Labelize";
import {
  ContentActions,
  ContentCard,
} from "../../../../../../common/Accordian/styles";
import Pills from "../../../../../../common/Pills";
import { InviteEmpty } from "../Invites/styles";

function PendingRequests({ searchText, program }) {
  const [requests, setRequests] = React.useState(null);
  const { dispatch } = React.useContext(Context);
  const params = useParams();
  const navigate = useNavigate();
  const [refreshRequests, setRefreshRequests] = React.useState(false);
  const [roleFilter, setRoleFilter] = React.useState("");
  const [isProcessingRequest, setIsProcessingRequest] = React.useState({
    type: "none",
    userId: "",
  });
  React.useEffect(() => {
    setRequests(null);
    callAPI(dispatch, "getProgramParticipants", {
      id: params.selectedProgram,
    }).then((retrievedRequests) => {
      if (retrievedRequests.isResponseOk) {
        setRequests(retrievedRequests.data.applications);
      } else setRequests(null);
    });
  }, [refreshRequests]);

  function approveRequest(userId) {
    setIsProcessingRequest({
      type: "approve",
      userId,
    });
    callAPI(dispatch, "acceptRequest", {
      userId,
      programId: params.selectedProgram,
    }).then(() => {
      setRefreshRequests(!refreshRequests);
      setIsProcessingRequest({
        type: "none",
        userId: "",
      });
    });
  }
  function denyRequest(userId) {
    setIsProcessingRequest({
      type: "deny",
      userId,
    });
    callAPI(dispatch, "denyRequest", {
      userId,
      programId: params.selectedProgram,
    }).then(() => {
      setRefreshRequests(!refreshRequests);
      setIsProcessingRequest({
        type: "none",
        userId: "",
      });
    });
  }
  if (requests === null) {
    return (
      <PendingRequestsContainer>
        <Loading loadingItem="Applications" />
      </PendingRequestsContainer>
    );
  }
  return (
    <PendingRequestsContainer>
      <FilterContainer>
        {program.programType !== "TEAM" && (
          <Pills
            options={
              program.programType === "TEAM"
                ? []
                : [
                    {
                      label: "All",
                      value: "",
                    },
                    {
                      label: "Mentors",
                      value: "mentor",
                    },
                    {
                      label: "Mentees",
                      value: "mentee",
                    },
                  ]
            }
            value={roleFilter}
            setValue={setRoleFilter}
          />
        )}
      </FilterContainer>
      {!requests.length ? (
        <PendingRequestsContainer>
          <InviteEmpty>{"There are no outstanding applications."}</InviteEmpty>
        </PendingRequestsContainer>
      ) : (
        <Accordian
          sections={requests
            .filter((user) => {
              if (roleFilter !== "" && roleFilter != user.role) {
                return false;
              }
              return user.firstName
                .toLowerCase()
                .includes(searchText.toLowerCase());
            })
            .map((user) => {
              return {
                title: (
                  <MentorHeader>
                    <Info>
                      {
                        <Image
                          src={
                            user?.image
                              ? user?.image
                              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          }
                        />
                      }
                      <Name>
                        {user?.firstName + " " + user?.lastName}
                        <Meta>{user?.role.toUpperCase()}</Meta>
                      </Name>
                    </Info>
                    <Actions>
                      <Meta>
                        Application Time : {formatDate(user?.createdAt)}
                      </Meta>
                      <ButtonSpacer />
                      <Button
                        icon="chat"
                        onClick={() => {
                          callAPI(dispatch, "initializeConversation", {
                            id: user?.id,
                          })
                            .then((res) => {
                              if (res) {
                                navigate(`/messages/${user?.uuid}`);
                              }
                            })
                            .catch((error) => {
                              console.error(error);
                            });
                        }}
                      />

                      <ButtonSpacer />
                      <Button
                        icon={
                          user?.id === isProcessingRequest.userId &&
                          isProcessingRequest.type === "approve"
                            ? "loading"
                            : "tick"
                        }
                        variant="primary"
                        onClick={() => {
                          approveRequest(user?.id);
                        }}
                      />
                      <ButtonSpacer />
                      <Button
                        icon={
                          user?.id === isProcessingRequest.userId &&
                          isProcessingRequest.type === "deny"
                            ? "loading"
                            : "cross"
                        }
                        variant="danger"
                        onClick={() => {
                          denyRequest(user?.id);
                        }}
                      />
                    </Actions>
                  </MentorHeader>
                ),
                content: (
                  <>
                    <ContentCard>
                      <ButtonSpacer />
                      {user?.applicationFrom.length
                        ? user?.applicationFrom.map((qa) => {
                            return (
                              <Labelize label={qa.questions.question}>
                                {qa.answer && typeof qa.answer === "string"
                                  ? qa.answer
                                      .replace(/[{}"]/g, "")
                                      .split(",")
                                      .join(", ")
                                  : qa.answer}
                              </Labelize>
                            );
                          })
                        : "Current Application don't have application question"}
                    </ContentCard>
                    <ContentActions>
                      <Button
                        icon={
                          user?.id === isProcessingRequest.userId &&
                          isProcessingRequest.type === "approve"
                            ? "loading"
                            : "tick"
                        }
                        text="Approve"
                        variant="primary"
                        onClick={() => {
                          approveRequest(user?.id);
                        }}
                      />
                      <ButtonSpacer />
                      <Button
                        icon={
                          user?.id === isProcessingRequest.userId &&
                          isProcessingRequest.type === "deny"
                            ? "loading"
                            : "cross"
                        }
                        text="Deny"
                        variant="danger"
                        onClick={() => {
                          denyRequest(user?.id);
                        }}
                      />
                    </ContentActions>
                  </>
                ),
              };
            })}
        />
      )}
    </PendingRequestsContainer>
  );
}
export default PendingRequests;
