import { React, useState, useEffect, useContext } from "react";
import {
  AuthContainer,
  AuthLogoContainer,
  AuthLogo,
  AuthTab,
  LoginTab,
  SignUpTab,
  FormContainer,
  AuthHeading,
  AuthForm,
  ButtonContainer,
  SignUpButton,
  ForgotPasswordButton,
  SignUpLink,
  InitialResetPara,
  VerificationMessage,
  MessageImage,
  MessageHeading,
  MessageDescription,
} from "./styles";
import Button from "../../../common/Button";
import Logo from "./one.png";
import { LoginPageConfigurations } from "./config";
import Labelize from "../../../common/Labelize";
import { Context } from "../../../Store";
import { useNavigate } from "react-router-dom";
import Checkbox from "../../../common/Checkbox";
import TextInput from "../../../common/TextInput";
import { validateIsValid } from "../../../common/utils";
import MessageIcon from "./message.png";
import TickIcon from "./tick.png";
import callAPI from "../../../api/apiCaller";

const Auth = () => {
  const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate();
  const getInitialMode = () => {
    const pathname = window.location.pathname;

    if (pathname.includes("forget-password")) {
      return "resetPassword";
    }
    if (pathname.includes("verification")) {
      return "verification";
    }

    return "login";
  };

  const [mode, setMode] = useState(getInitialMode);

  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [isValid, setIsValid] = useState({
    email: null,
    password: null,
  });

  useEffect(() => {
    let initialState = LoginPageConfigurations[mode].initialState;
    let initialIsValid = {};
    initialState &&
      Object.keys(initialState).forEach((key) => {
        initialIsValid[key] = null;
        if (key === "id") {
          initialIsValid[key] = true;
          initialState[key] = window.location.pathname.split("/").slice(-1)[0];
        }
      });
    setIsValid(initialIsValid);
    setForm(initialState);
    if (
      window.location.pathname.includes("verification") &&
      window.location.pathname.split("/").slice(-1)[0] &&
      mode === "verification"
    ) {
      config.action(
        setIsProcessing,
        { id: window.location.pathname.split("/").slice(-1)[0] },
        dispatch,
        setMode,
        navigate
      );
    }
  }, [mode]);

  const { dispatch } = useContext(Context);
  let config = LoginPageConfigurations[mode];

  const handleChangeTerms = (newValue) => {
    setIsChecked(newValue);
  };

  const sendVerificationEmail = () => {
    setIsProcessing(true);
    const payload = {
      token: form.id,
      email: form.email?.toLowerCase(),
    };
    callAPI(dispatch, payload.email && "resendEmail", payload)
      .then(() => {
        setIsProcessing(false);
      })
      .catch((err) => {
        console.error(err);
        setIsProcessing(false);
      });
  };

  return (
    <AuthContainer>
      <AuthLogoContainer>
        <AuthLogo src={Logo} alt="Auth Logo" />
      </AuthLogoContainer>
      <FormContainer>
        {mode === "login" || mode === "createUser" ? (
          <AuthTab>
            <LoginTab onClick={() => setMode("login")} mode={mode === "login"}>
              Log in
            </LoginTab>
            <SignUpTab
              onClick={() => setMode("createUser")}
              mode={mode === "createUser"}
            >
              Sign up
            </SignUpTab>
          </AuthTab>
        ) : (
          <></>
        )}
        <AuthHeading>{config.title}</AuthHeading>
        {mode === "inializeResetPassword" && (
          <InitialResetPara>
            Enter the email address associated with your account and we’ll send
            you a link to reset your password.
          </InitialResetPara>
        )}
        {mode === "verificationSuccessful" && (
          <VerificationMessage>
            <MessageImage src={TickIcon} alt="Tick Icon" />
            <MessageDescription>
              You have been successfully verified Welcome to One Mauve! 🙌.
            </MessageDescription>
          </VerificationMessage>
        )}
        {mode === "verificationMessage" && (
          <VerificationMessage>
            <MessageImage src={MessageIcon} alt="Message Icon" />
            <MessageHeading>Check your inbox!</MessageHeading>
            <MessageDescription>
              We have sent a verification link to the email you signed up with.
              Please check your inbox and confirm.
            </MessageDescription>
          </VerificationMessage>
        )}
        {mode === "passwordResetLink" && (
          <VerificationMessage>
            <InitialResetPara>
              We have sent a verification link to the email you signed up with.
              Please check your inbox and confirm.
            </InitialResetPara>
            <MessageDescription>Did not get an email?</MessageDescription>
          </VerificationMessage>
        )}
        {mode === "passwordResetConfirmation" && (
          <InitialResetPara>
            You can now use your new password to login to your account. 🙌
          </InitialResetPara>
        )}
        <AuthForm>
          {config?.form &&
            config.form.map((element) => {
              return (
                <>
                  {" "}
                  <Labelize
                    label={element.label}
                    mode={mode}
                    labelkey={element.key}
                    isValid={isValid[element.key]}
                  >
                    {element.key === "agreeTermCond" ? (
                      <>
                        <Checkbox
                          label="I acknowledge and agree to the"
                          isLinkContent="Terms and Services"
                          isAgree={true}
                          link="terms-and-services"
                          size={10}
                          value={isChecked}
                          setValue={(newValue) => {
                            handleChangeTerms(newValue);
                            setForm({
                              ...form,
                              [element.key]: newValue,
                            });
                            setIsValid({ ...isValid, agreeTermCond: newValue });
                          }}
                        />
                      </>
                    ) : (
                      <TextInput
                        placeholder={element.placeholder}
                        isValid={isValid[element.key]}
                        setIsValid={(newIsValid) => {
                          setIsValid({ ...isValid, [element.key]: newIsValid });
                        }}
                        validator={(newValue) =>
                          element.validator(newValue, form)
                        }
                        hideInstruction={element.hideInstruction}
                        value={form ? form[element.key] : ""}
                        type={element.type}
                        mode={mode}
                        setValue={(newValue) => {
                          setForm({
                            ...form,
                            [element.key]: newValue,
                          });
                        }}
                      />
                    )}
                  </Labelize>
                </>
              );
            })}
          {mode === "login" && (
            <>
              <ForgotPasswordButton
                onClick={() => {
                  setMode("inializeResetPassword");
                }}
              >
                Forget Password?
              </ForgotPasswordButton>
            </>
          )}
          <ButtonContainer isSignUp={mode === "createUser" ? true : false}>
            {mode === "verificationSuccessful" ||
            mode === "passwordResetConfirmation" ||
            mode === "verificationMessage" ? (
              <Button
                text={config.actionText}
                variant="primary"
                disabled={false}
                onClick={() => {
                    setIsProcessing(false);
                    setMode("login");
                    navigate("/")
                }}
                icon={isProcessing ? "loading" : ""}
                width={
                  mode === "resetPassword" ||
                  mode === "verificationMessage" ||
                  mode === "verificationSuccessful" ||
                  mode === "verification"
                    ? 48
                    : mode === "passwordResetLink"
                    ? 35
                    : 27.5
                }
                isLogin={true}
                height={40}
              ></Button>
            ) : (
              <Button
                text={config.actionText}
                variant="primary"
                disabled={!validateIsValid(isValid)}
                onClick={() =>
                  config.action(
                    setIsProcessing,
                    form,
                    dispatch,
                    setMode,
                    navigate
                  )
                }
                icon={isProcessing ? "loading" : ""}
                width={
                  mode === "resetPassword" ||
                  mode === "verificationMessage" ||
                  mode === "verificationSuccessful" ||
                  mode === "verification"
                    ? 48
                    : mode === "passwordResetLink"
                    ? 35
                    : 27.5
                }
                isLogin={true}
                height={40}
              />
            )}
          </ButtonContainer>
          {mode === "login" || mode === "inializeResetPassword" ? (
            <SignUpButton
              onClick={() => {
                setMode("createUser");
              }}
            >
              New User? <SignUpLink>Sign up here...</SignUpLink>
            </SignUpButton>
          ) : (
            <></>
          )}
          {mode === "verificationMessage" && (
            <SignUpButton onClick={() => {}}>
              Didn’t get email?{" "}
              <SignUpLink onClick={sendVerificationEmail}>
                Send it again...
              </SignUpLink>
            </SignUpButton>
          )}
        </AuthForm>
      </FormContainer>
    </AuthContainer>
  );
};

export default Auth;
