import styled from "styled-components";
import { headerSize } from "../../../../common/styles";
import colors from "../../../../config/colors";

export const NotificationsBox = styled.div`
    position: fixed;
    background-color:${colors.text}33;
    height: calc(100vh - ${20+headerSize}px);
    padding:10px;
    box-sizing: border-box;
    top: 63px;
    right:${props => props.isOpen ? "0" : "-500px"};
    backdrop-filter: blur(5px);
    transition: all 0.2s ease-${props => props.isOpen ? "out" : "in"};
    overflow-y:hidden;
    overflow:scroll;    
    &::-webkit-scrollbar {
        width: 8px;
      };
      &::-webkit-scrollbar{
        -ms-overflow-style: 8px; 
        scrollbar-width: 80x; 
      };
      &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 4px; 
      };

      &::-webkit-scrollbar-thumb:hover {
        background-color: #555; 
      };
    
      scrollbar-width: thin;
      scrollbar-color: #888 #eee; 
      z-index: 10;


      @media (max-width:640px) {
    height: calc(100vh - ${110+headerSize}px);}


`;
export const Notification = styled.div`
    padding:10px;
    background-color:${colors.background};
    display:flex;
    border-radius:10px;
    margin-bottom:10px;
    box-sizing: border-box;
    width:350px;
    opacity: ${props => props.hasRead ? "0.5" : "1"};

`;
export const NotificationImage = styled.img`
    height:40px;
    width:40px;
    border-radius:50%;
    object-fit:cover;
    margin-right:10px;
`;
export const NotificationBox = styled.div`
    width:300px;
`;
export const NotificationHeader = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
`;
export const NotificationTitle = styled.div`
    font-size:14px;
    font-weight:bold;
    margin-bottom:5px;
`;
export const NotificationTime = styled.div`
    font-size:12px;
`;
export const NotificationContent = styled.div`
font-size:12px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  padding-top:10px;
  align-items: center;
`;
export const ClearButtonBox = styled.div`
 padding:10px 0px;
`;