import React, { useEffect, useState } from "react";

import {
  ApplicationEditorContainer,
  TabTile,
  TabsTile,
  TabLists,
  TabPanelContent,
} from "./styles";
import RoleTab from "./RoleTab";
import { getIsValid } from "./utils";

function ApplicationEditor({
  programForm,
  setProgramForm,
  isValid,
  setIsValid,
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const applicationForm = programForm.applicationForm;
  const setApplicationForm = (newApplicationForm) => {
    setProgramForm({
      ...programForm,
      applicationForm: newApplicationForm,
    });
  };

  useEffect(() => {
    setIsValid(getIsValid(applicationForm));
  }, [applicationForm]);

  return (
    <ApplicationEditorContainer>
      <TabsTile
        selectedIndex={selectedIndex}
        onSelect={(index) => setSelectedIndex(index)}
      >
        {programForm?.programType !== "TEAM" &&
        applicationForm?.programType !== "TEAM" &&
        applicationForm ? (
          <TabLists>
            <TabTile isActive={selectedIndex === 0}>Mentor Application</TabTile>
            <TabTile isActive={selectedIndex === 1}>Mentee Application</TabTile>
          </TabLists>
        ) : (
          applicationForm && (
            <TabLists isMember={true}>
              <TabTile isActive={selectedIndex === 0}>
                Member Application
              </TabTile>
            </TabLists>
          )
        )}
        <div>
          {programForm?.programType !== "TEAM" &&
          applicationForm?.programType !== "TEAM" &&
          applicationForm
            ? ["mentor", "mentee"].map((role) => (
                <TabPanelContent key={role}>
                  <RoleTab
                    role={role}
                    applicationForm={applicationForm}
                    setApplicationForm={setApplicationForm}
                  />
                </TabPanelContent>
              ))
            : applicationForm &&
              ["member"].map((role) => (
                <TabPanelContent key={role}>
                  <RoleTab
                    role={role}
                    applicationForm={applicationForm}
                    setApplicationForm={setApplicationForm}
                  />
                </TabPanelContent>
              ))}
        </div>
      </TabsTile>
    </ApplicationEditorContainer>
  );
}

export default ApplicationEditor;
