import React from "react";
import { MeetingDetailOuter, MeetingInfo, InfoValue, InfoLabel, } from "./style";
import Icon from "../../../../../../common/Icon";

const MeetingDetails = ({ item, isTeam, unixStampToDate }) => {
  const renderDate = (date) => {
    const splitDate = unixStampToDate(date)?.split(" at ");
    return splitDate ? splitDate[0] : null;
  };
  const renderTime = (date) => {
    const splitDate = unixStampToDate(date)?.split(" at ");
    return splitDate ? splitDate[1] : null;
  };

  return (
    <MeetingDetailOuter>
      <MeetingInfo>
        <Icon type="interview-icon" size="15" color="#000" />
        <InfoLabel>Program:{" "}</InfoLabel>
        <InfoValue>
        {item?.program?.programType === "TEAM"
          ? item?.reasonForMeeting
          : item?.meeting?.reasonForMeeting}
          </InfoValue>
      </MeetingInfo>
      {item?.meeting?.meetingScheduleDate !== null ? (
        <MeetingInfo>
          <Icon type="calander-icon" size="15" color="#000" />
          <InfoLabel>Date:{" "}</InfoLabel>
          <InfoValue>
          {renderDate(
            item.program?.programType === "TEAM"
              ? item?.meetingScheduleDate
              : item?.meeting?.meetingScheduleDate
          )}
          </InfoValue>
        </MeetingInfo>
      ) : (
        <MeetingInfo>
          <Icon type="calander-icon" size="15" color="#000" />
          <InfoValue>
          <InfoLabel>Date:</InfoLabel>
          {renderDate(item?.meeting?.date)}
          {renderDate(item?.meeting?.date2)}
          {renderDate(item?.meeting?.date3)}
          </InfoValue>
        </MeetingInfo>
      )}
      <MeetingInfo>
        <Icon type="clock-icon" size="15" color="#000" />
        <InfoLabel>Time:{" "}</InfoLabel>
        <InfoValue>
        {renderTime(
          item.program?.programType === "TEAM"
            ? item?.meetingScheduleDate
            : item?.meeting?.meetingScheduleDate
        )}
        </InfoValue>
      </MeetingInfo>
      <MeetingInfo>
        <Icon type="location-icon" size="15" color="#000" />
        <InfoLabel>Location:{" "}</InfoLabel>
        <InfoValue>
        {item.program?.programType === "TEAM"
          ? item?.location
          : item?.meeting?.location}
          </InfoValue>
      </MeetingInfo>
      {item?.meeting?.meetingLink ? (
        <MeetingInfo>
          {" "}
          <Icon type="link-icon" size="15" color="#000" />
          <InfoLabel>Meeting Link:</InfoLabel>   <InfoValue>{item?.meeting?.meetingLink}</InfoValue>
        </MeetingInfo>
      ) : null}
      <MeetingInfo>
        {" "}
        <Icon type="comments-icon" size="15" color="#000" />
        <InfoLabel>Additional Comments:{" "}</InfoLabel>
      </MeetingInfo>
      <MeetingInfo>
        {" "}
        <InfoValue>
        {item.program?.programType === "TEAM"
          ? item.additionalInfo
          : item?.meeting?.additionalInfo}
          </InfoValue>
      </MeetingInfo>
    </MeetingDetailOuter>
  );
};

export default MeetingDetails;
