import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import callAPI from "../../../../../api/apiCaller";
import Button from "../../../../../common/Button";
import { ButtonSpacer } from "../../../../../common/Button/styles";
import Loading from "../../../../../common/Loading";
import Modal from "../../../../../common/Modal";
import TextInput from "../../../../../common/TextInput";
import Icon from "../../../../../common/Icon";

import {
  TaskMembersContainer,
  MemberRow,
  MemberInfo,
  MemberImage,
  MemberName,
  MemberActions,
  AddEditModalHeader,
  HeaderRow,
  HeaderCell,
  ModalInputField,
  ModalButton,
  MemberDate,
} from "./styles";
import { Context } from "../../../../../Store";
import {
  formatISODateForTasks,
} from "../../../../../common/utils";

function TaskMembers({ programType, mode, taskId, setShowTaskMembers }) {
  const [members, setMembers] = React.useState(null);
  const [isMarkingAsCompleted, setIsMarkingAsCompleted] = React.useState(false);
  const [isReminding, setIsReminding] = React.useState(false);
  const { dispatch } = React.useContext(Context);
  const [searchTerm, setSearchTerm] = React.useState("");
  const params = useParams();
  const navigate = useNavigate();

  const completeAndNOtCompletedTasks = (data) => {
    const notCompletedTasks = [];
    if (programType === "TEAM") {
      data.forEach((task) => {
        notCompletedTasks.push(...task.completedTaskByParticipants);
        notCompletedTasks.push(...task.notCompletedTaskByParticipants);
      });
      return notCompletedTasks;
    } else {
      data.forEach((task) => {
        if (
          mode === "pending" &&
          task.notCompletedTaskByParticipants.length > 0
        ) {
          notCompletedTasks.push(...task.notCompletedTaskByParticipants);
        } else if (
          mode === "complete" &&
          task.completedTaskByParticipants.length > 0
        ) {
          notCompletedTasks.push(...task.completedTaskByParticipants);
        }
      });
      return notCompletedTasks;
    }
  };

  function getProgramTaskParticipients() {
    callAPI(dispatch, "getProgramTaskMembers", {
      id: taskId,
    }).then((res) => {
      setMembers(completeAndNOtCompletedTasks([res.data]));
    });
  }
  React.useEffect(() => {
    getProgramTaskParticipients();
  }, []);
  function remindUser(userId) {
    setIsReminding(userId);
    if (programType !== "TEAM") {
      return callAPI(dispatch, "remindUser", {
        id: +params.selectedProgram,
        taskId,
        participantId: userId,
      }).then((res) => {
        setIsReminding(false);
        setIsMarkingAsCompleted(false);
      });
    } else {
      return callAPI(dispatch, "remindUser", {
        id: +params.selectedProgram,
        taskId,
        teamId: userId,
      }).then((res) => {
        setIsReminding(false);
        setIsMarkingAsCompleted(false);
      });
    }
  }

  function MarkTeamTaskCompleted(teamId) {
    setIsMarkingAsCompleted(teamId);
    callAPI(dispatch, "markTeamTaskAsComplete", {
      id: teamId,
      status: "completed",
    }).then((updatedTasks) => {
      setIsMarkingAsCompleted(false);
      getProgramTaskParticipients();
    });
  }

  function markTaskAsComplete(userId) {
    setIsMarkingAsCompleted(userId);
    return callAPI(dispatch, "markTaskAsCompleteForUser", {
      id: params.selectedProgram,
      tid: taskId,
      rid: userId,
    }).then((res) => {
      setIsMarkingAsCompleted(false);
      getProgramTaskParticipients();
    });
  }
  // console.log(members, "members DATA Check")
  function remindAll() {
    const participants = members.map((member) => {
      if (programType === "TEAM") {
        return {
          taskId: taskId,
          teamId: member?.teamId,
        };
      } else {
        return {
          taskId: taskId,
          participantId: member?.participant?.id,
        };
      }
    });
    setIsReminding("all");
    return callAPI(dispatch, "remindAll", {
      id: params.selectedProgram,
      participants,
    }).then((res) => {
      setIsReminding(false);
      setShowTaskMembers(false);
    });
  }

  const filteredMembers = members && members.filter((member) => {
    const firstName =
      member.participant?.firstName ||
      member.participant?.participant?.firstName ||
      "";
    const lastName =
      member.participant?.lastName ||
      member.participant?.participant?.lastName ||
      "";

    const fullName = `${firstName} ${lastName}`.toLowerCase();
    return searchTerm === "" || fullName.includes(searchTerm.toLowerCase());
  });

  function markTeamAllAsComplete() {
    setIsMarkingAsCompleted("all");
    const ids = members.map((member) => member.id);
    // console.log(ids,"<<Ids");
    return callAPI(dispatch, "markTeamAllTaskAsComplete", {
      ids,
      status: "completed",
    }).then(() => {
      setIsMarkingAsCompleted(false);
      setShowTaskMembers(false);
    });
  }

  function markAllAsComplete() {
    setIsMarkingAsCompleted("all");
    const participants = members.map((member) => {
      return {
        taskId: taskId,
        participantId: member?.participant?.id,
      };
    });

    return callAPI(dispatch, "markAllTaskAsComplete", {
      id: params.selectedProgram,
      participants,
    }).then(() => {
      setIsMarkingAsCompleted(false);
      setShowTaskMembers(false);
    });
  }

  function getTitle() {
    if (mode === "pending" && programType !== "TEAM") {
      return "Incomplete Task";
    } else if (mode !== "pending" && programType !== "TEAM") {
      return "Completed Task";
    } else if (mode === "pending" && programType === "TEAM") {
      return "Incomplete Task";
    } else {
      return "Teams who have completed the task";
    }
  }

  if (members === null) {
    return (
      <Modal
        title={<AddEditModalHeader>{getTitle()}</AddEditModalHeader>}
        onClose={() => {
          setShowTaskMembers(false);
        }}
      >
        <Loading item="Members" />
      </Modal>
    );
  }
  return (
    <Modal
      minWidth="0"
      fixedWidth="818px"
      width="100%"
      className="layout-styles"
      actionClass="actionbtns3"
      title={
        <AddEditModalHeader>
          <span>{getTitle()}</span>
        </AddEditModalHeader>
      }
      isButtonCenter={mode !== "pending" ? true : false}
      onClose={() => {
        setShowTaskMembers(false);
      }}
      isTask
      actions={
        mode === "pending"
          ? [
              {
                text: "Cancel",
                variant: "pending",
                isNotRound: "5px",
                hasShadow: true,
                onClick: () => {
                  setShowTaskMembers(false);
                },
              },
              {
                text: "Remind All",
                variant: "Completed",
                isNotRound: "5px",
                onClick: remindAll,
                icon: isReminding === "all" ? "loading" : "notification",
              },
              {
                text: "Mark All As Complete",
                isNotRound: "5px",
                onClick:
                  programType === "TEAM"
                    ? markTeamAllAsComplete
                    : markAllAsComplete,
                variant: "primary",
                icon: isMarkingAsCompleted === "all" ? "loading" : "tick",
              },
            ]
          : [
              {
                text: "Cancel",
                onClick: () => {
                  setShowTaskMembers(false);
                },
                variant: "pending",
                isNotRound: "5px",
              },
            ]
      }
    >
      <TaskMembersContainer>
        {programType === "TEAM" ? (
          <>
            {members.map((member) => {
              if (mode === "pending" && member.status === "pending") {
                return (
                  <MemberRow key={member?.team?.id}>
                    <MemberInfo>
                      <MemberImage
                        src={
                          member?.team?.media ||
                          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                        }
                      />
                      <MemberName>{member?.team?.name}</MemberName>
                    </MemberInfo>
                    <MemberActions>
                      <ButtonSpacer />
                      <Button
                        onClick={() => remindUser(member?.teamId)}
                        icon={
                          isReminding === member?.teamId
                            ? "loading"
                            : "notification"
                        }
                        text="Remind"
                      />
                      <ButtonSpacer />
                      <Button
                        icon={
                          isMarkingAsCompleted === member?.taskId
                            ? "loading"
                            : "tick"
                        }
                        text="Mark as Completed"
                        onClick={() => MarkTeamTaskCompleted(member?.id)}
                      />
                    </MemberActions>
                  </MemberRow>
                );
              } else if (mode === "complete" && member.status === "completed") {
                return (
                  <MemberRow key={member?.team?.id}>
                    <MemberInfo>
                      <MemberImage
                        src={
                          member?.team?.media ||
                          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                        }
                      />
                      <MemberName>{member?.team?.name}</MemberName>
                    </MemberInfo>
                    <MemberActions />
                  </MemberRow>
                );
              }
              return null;
            })}
          </>
        ) : (
          <>
            <ModalInputField>
              <Icon type="search" />
              <TextInput
                value={searchTerm}
                setValue={setSearchTerm}
              />
            </ModalInputField>
            <HeaderRow>
              <HeaderCell>Full Name</HeaderCell>
              {members.some(
                (member) => mode === "complete" && member.status === "completed"
              ) && (
                <HeaderCell>
                  <span>Date Completed</span>
                </HeaderCell>
              )}
              <HeaderCell>Actions</HeaderCell>
            </HeaderRow>
            {filteredMembers.map((member) => {
              const firstName =
                member.participant?.firstName ||
                member.participant?.participant?.firstName ||
                "";
              const lastName =
                member.participant?.lastName ||
                member.participant?.participant?.lastName ||
                "";
              const fullName = `${firstName} ${lastName}`;
              const dateCompleted = member?.updatedAt || "N/A";
              return (
                <MemberRow key={member.participant.id}>
                  <MemberInfo>
                    <MemberImage
                      src={
                        member.participant?.image ||
                        member.participant?.participant?.image ||
                        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                      }
                    />
                    <MemberName>{fullName}</MemberName>
                  </MemberInfo>
                  {mode === "complete" && (
                    <MemberDate>
                      <span>{formatISODateForTasks(dateCompleted)}</span>
                    </MemberDate>
                  )}
                  <MemberActions>
                    <ModalButton>
                      <Button
                        icon="chat"
                        onClick={() => {
                          callAPI(dispatch, "initializeConversation", {
                            id: member.participant.uuid
                              ? member.participant.id
                              : member.participant.participant.id,
                          })
                            .then((res) => {
                              if (res) {
                                navigate(
                                  `/messages/${
                                    member.participant.uuid
                                      ? member.participant.uuid
                                      : member.participant.participant.uuid
                                  }`
                                );
                              }
                            })
                            .catch((error) => console.error(error));
                        }}
                      />
                    </ModalButton>
                    <ButtonSpacer />
                    {members.some(
                      (member) =>
                        mode === "complete" && member.status === "completed"
                    ) && (
                      <Button icon="tick" text="Completed" variant="primary" />
                    )}
                    {mode === "pending" && (
                      <>
                        <ButtonSpacer />
                        <ModalButton>
                          <Button
                            onClick={() => remindUser(member.participant.id)}
                            icon={
                              isReminding === member.participant.id
                                ? "loading"
                                : "notification"
                            }
                            text="Remind"
                          />
                        </ModalButton>
                      </>
                    )}
                    {mode === "pending" && (
                      <>
                        <ButtonSpacer />
                        <ModalButton>
                          <Button
                            icon={
                              isMarkingAsCompleted === member.participant.id
                                ? "loading"
                                : "tick"
                            }
                            text="Mark as Completed"
                            onClick={() =>
                              markTaskAsComplete(member.participant.id)
                            }
                          />
                        </ModalButton>
                      </>
                    )}
                  </MemberActions>
                </MemberRow>
              );
            })}
          </>
        )}
      </TaskMembersContainer>
    </Modal>
  );
}
export default TaskMembers;
