import {
  attachPromiseToToast,
  toastTrackedMessage,
} from "../Store/Toasts/actions";
import endpoints from "./endpoints";
import mockAPI from "./mockAPI";
function callAPI(dispatch, apiName, data = {}) {
  let apiInfo = endpoints[apiName];
  if (!apiInfo || apiInfo.useMock) {
    let id = Math.random().toString();
    // dispatch(toastTrackedMessage(id,apiName+" in progress","inProgress"));
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        try {
          // dispatch(toastTrackedMessage(id,apiName+" success","success"));
          resolve(mockAPI[apiName](data));
        } catch (err) {
          // dispatch(toastTrackedMessage(id,apiName+" failed","failure"));
          reject(err);
        }
      }, Math.random() * 3000);
    });
  }
  let apiRequest = apiInfo.formatRequest ? apiInfo.formatRequest(data) : data;
  let url = apiInfo.url;
  for (let key in data) {
    if (url.includes(`:${key}`)) {
      url = url.replace(`:${key}`, data[key]);
    }
  }  
  if(apiName==='approveTrackHours' || apiName==='deleteTrackHours')
  {
    url= apiName==='approveTrackHours' ?`${data?.body?.approved}`:`${data?.body?.rejected}`
  }
  const token = localStorage.getItem("accessToken");
  const headers = {
    "Content-Type": "application/json",
    Authorization: token && `Bearer ${token}`,
  };

  let promise = fetch(url, {
    ...apiInfo,
    headers,
    ...(apiInfo.method === "POST" ||
    apiInfo.method === "PATCH" ||
    apiInfo.method === "DELETE"
      ? {
          body: JSON.stringify(apiRequest),
        }
      : {}),
  })
    .then(async (response) => {
      const isResponseOk = response.ok;
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        response = await response.json();
        response["isResponseOk"] = isResponseOk;
        return response;
      } else {
        return response.text();
      }
    }) 

    .then((response) => {
      return apiInfo.formatResponse
        ? apiInfo.formatResponse(response)
        : response;
    });

  attachPromiseToToast(dispatch, promise, apiInfo.info);
  return promise;
}

export default callAPI;
