import styled from "styled-components";
import colors from "../../config/colors";

export const ModalContainer = styled.div`
    width=${props=>props.isWidth && "300px" }
    max-width=${props=>props.isWidth && "300px" }
`;

export const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
`;
export const ModalContent = styled.div`
    background-color: ${colors.background};
    padding: ${(props) => (props.isMeeting ? "30px 40px" : props.noPadding ? "0" : "20px")};
    padding-bottom: ${(props) => (props.paddingBottom ? "0" : "25px") };
    width: ${props=>props.fixedWidth && props.fixedWidth};
    min-width:${props=>props.minWidth ?props.minWidth :'30vw'};
    max-width:${props=>props.width ? props.width : ''} !important;
    height:${props=>props.isHeight ? props.isHeight : props.isMeeting ? "100%" : ''};
    min-height: ${props => props.isMeeting && "500px"};
    max-height: ${props => props.isMeeting && "fit-content"};
    border-radius:10px;
    position: relative;
    overflow-y: auto;
        @media( max-width: 992px ) {
            width:${props=>props.fixedWidth && "700px"};
        }
        &.layout-styles {
            padding: 0;
            @media(max-width:767px){
                > div {
                    width: 640px !important;
                }
            }
            .actionbtns3 {
                div {
                    font-family: "Nunito Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    box-shadow: none;
                }
                div:nth-child(1) {
                    margin-right: ${props=>props.isButtonCenter ? "" : "auto"};
                    @media(max-width:767px){
                        margin:0;
                    }
                }
            }
        }
        @media( max-width: 992px ) {
            min-width:initial !important;
        } 
        @media(max-width:767px){
            align-self: self-start;
            max-height: 76vh;
            width: ${props=>props.fixedWidth && "auto"};
        }
`;

export const ModalTitle = styled.div`
    font-size: ${props => props.isMeeting ? "22px" : "24px"};
    font-weight: bold;
    color: ${props => props.isMeeting && "#222121"};
    font-family: ${props => props.isMeeting && "Nunito Sans"};
    margin-bottom: ${props => props.isMeeting ? "5px" : "20px"};
    position:relative;
        svg {

        }
`;
export const ModalActions = styled.div`
    display: flex;
    align-items: center;
    justify-content:${props => props.isButtonCenter ? "center" : "flex-end"};
    margin: 45px 20px 20px;
    gap: 12px;
        @media (max-width:480px){
            justify-content: center; 
            flex-wrap: wrap;
            gap: 10px;
        }
        &.custom-modal {
            display:flex;
            justify-content:center;
            margin:20px auto 30px auto;
        }
`;

export const IconWrapper = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 10;
    width:30px;
    height:30px;
    border-radius:50%;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:7px 6px;
    box-sizing:border-box;
    background: rgba(219, 219, 219, 0.30);
`;