import React from "react";
import { FormSection } from "./styles";
import Labelize from "../../../../../common/Labelize";
import Pills from "../../../../../common/Pills";
import AddNewQuestionButton from "./AddNewQuestionButton";
import QuestionForm from "./QuestionForm";

const RoleTab = ({ role, applicationForm, setApplicationForm }) => {
  const key = role.toLowerCase();
  return (
    <FormSection>
      <Labelize
        horizontal
        applicationLabel={true}
        label={`Allow New Members to Apply as ${role}?`}
      >
        <Pills
          value={applicationForm.accepts[key]}
          applicationPills={true}
          setValue={(newValue) => {
            setApplicationForm({
              ...applicationForm,
              accepts: {
                ...applicationForm.accepts,
                [key]: newValue,
              },
            });
          }}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
      </Labelize>

      {applicationForm.accepts[key] &&
        applicationForm[key].map((question, index) => (
          <QuestionForm
            key={index}
            role={role}
            question={question}
            index={index}
            applicationForm={applicationForm}
            setApplicationForm={setApplicationForm}
          />
        ))}

      {applicationForm.accepts[key] && (
        <AddNewQuestionButton
          role={role}
          applicationForm={applicationForm}
          setApplicationForm={setApplicationForm}
        />
      )}
    </FormSection>
  );
};

export default RoleTab;
