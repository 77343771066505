import styled from "styled-components";
import colors from "../../config/colors";

export const PillsContainer = styled.div`
    display:flex;
    padding:5px;
    box-sizing: border-box;
    background-color:${colors.secondary};
    width:${props =>props.isMeetingTab ? '100%' :'fit-content'};
    border-radius:${props =>props.meetings ? "5px" : props.isMeetingTab ? '10px':'100px'};
    flex-direction:${props =>props.isMeetingTab && 'column'};
     @media (max-width:991px) {
    flex-wrap:${props=>props.isWidth && 'wrap'};
    justify-content:${props=>props.isWidth && 'center'};
    border-radius:${props=> props.isWidth && '50px'};

    }
     @media (max-width:767px) {
    border-radius:${props=>props.isWidth && '10px'};
    margin:${props=>props.isWidth && '10px'};
    flex-direction:${props =>props.isMeetingTab && 'column'};
    width:${props =>props.isMeetingTab && '100%'};
    border-radius:${props =>props.isMeetingTab && '10px'};

    }
`;
export const Pill = styled.div`
    padding: ${props => props.meetings ? "10px 17px":"10px 10px"};
    user-select:none;
    background-color: ${props => props.active &&  props.meetings ? "#FFFFFF" : props.active && props.applicationPills ? "#FFFFFF" : props.active ? colors.primary : colors.secondary};
    color:${props => props.meetings ? "#302F2F" : props.active && props.applicationPills ? "#302F2F" : props.active ? colors.secondary : colors.text};
    display:flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    transition:all 0.1s ease-in-out;
    font-size:12px;
    border-radius:${props => props.meetings ? "5px" : "100px"};  
    

    ${props => props.active && props.meetings ? `font-weight: 400;` :props.active &&  props.applicationPills ? `
        font-weight: 400;
    `: props.active && `
        font-weight:bold;
    `}
    :hover{
        color:${props=> props.meetings ? "#302F2F": props.active &&  props.applicationPills ? "#302F2F" : props.active ? colors.background : colors.primary};
    }
    :active{
        transform:scale(0.95);
    }

    @media (max-width:991px) {
        max-width:${props=>props.isWidth && '150px'};
        width:${props=>props.isWidth && '100%'};
        padding:${props=>props.isWidth && '5px 20px'} ;
    }
    @media (max-width:767px) {
        max-width:${props=>props.isWidth && '100%'};
        width:${props=>props.isWidth && '100%'};
        padding:${props=>props.isWidth && '5px 20px'} ;
    }
    
`;
export const ItemNumber = styled.span`
margin-left: 5px;
    border-radius: 40px;
    padding: 3px;
    min-width: 15px;
    text-align: center;
    color: '#fff';
    color: white;
    font-size:12px;
background: ${props => props.isActive ? colors.secondary : colors.primary};

 
`;