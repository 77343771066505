import Activity from "../../Activity";
import MyTeams from "./MyTeams/index";
import ProgramConnections from "./ProgramConnections";
import ProgramHome from "./ProgramHome";
import ProgramMentors from "./ProgramMentors";
import ProgramParticipants from "./ProgramParticipants";
import ProgramTasks from "./ProgramTasks";
import Survey from "../survey";
import RequestList from "./RequestList";

export const programContentConfig={
    // meetings is for team not 1-on-1
    
    "1_1_NETWORK":{
        "mentor":[
            { label: "Home", value: "home",component:ProgramHome },
            { label: "My Mentees", value: "connections",component:ProgramConnections },
            // { label: "My Meetings", value: "my-connections",component: RequestList },
            
        ],
        "mentee":[
            { label: "Home", value: "home",component:ProgramHome },
            { label: "My Mentors", value: "connections",component:ProgramConnections },
            // { label: "My Meetings", value: "my-connections",component: RequestList },

        ],
        "manager":[
            { label: "Home", value: "home",component:ProgramHome }, 
            { label: "Participants", value: "participants",component:ProgramParticipants },
            { label: "Tasks", value: "tasks",component:ProgramTasks },
        ],
        "mentor_manager":[
            { label: "Home", value: "home",component:ProgramHome }, 
            { label: "Participants", value: "participants",component:ProgramParticipants },
            { label: "Tasks", value: "tasks",component:ProgramTasks },
            { label: "My Mentees", value: "connections",component:ProgramConnections },
            // { label: "My Meetings", value: "my-connections",component: RequestList },
        ],

        "mentee_manager":[
            { label: "Home", value: "home",component:ProgramHome }, 
            { label: "Participants", value: "participants",component:ProgramParticipants },
            { label: "Tasks", value: "tasks",component:ProgramTasks },
            { label: "My Mentors", value: "connections",component:ProgramConnections },
            // { label: "My Meetings", value: "my-connections",component: RequestList },
        ],
       
    },

    "OPEN_NETWORK":{
        "mentor":[
            { label: "Home", value: "home",component:ProgramHome },
            // { label: "My Mentees", value: "connections",component:ProgramConnections },
            { label: "My Connections", value: "my-connections",component: RequestList },
        ],
        "mentee":[
            { label: "Home", value: "home",component:ProgramHome },
            // { label: "My Mentors", value: "connections",component:ProgramConnections },
            { label: "My Connections", value: "my-connections",component: RequestList },
        ],
        "manager":[
            { label: "Home", value: "home",component:ProgramHome }, 
            { label: "Participants", value: "participants",component:ProgramParticipants },
            { label: "Meetings", value: "Meetings",component: Activity },
        ],
        "mentee_manager":[
            { label: "Home", value: "home",component:ProgramHome }, 
            { label: "Participants", value: "participants",component:ProgramParticipants },
            { label: "Meetings", value: "Meetings",component: Activity },
        ],
        "mentor_manager":[
            { label: "Home", value: "home",component:ProgramHome }, 
            { label: "Participants", value: "participants",component:ProgramParticipants },
            { label: "Meetings", value: "Meetings",component: Activity },
            { label: "My Connections", value: "my-connections",component: RequestList },
        ],    
    },
    "TEAM":{

        "manager":[
            { label: "Home", value: "home",component:ProgramHome }, 
            { label: "Participants", value: "participants",component:ProgramParticipants },
            { label: "Tasks", value: "tasks",component:ProgramTasks },
        ],
            "mentor":[
                { label: "Home", value: "home",component:ProgramHome },
                { label: "My Team", value: "my-teams",component:MyTeams },
              
            ],
            "member":[
                { label: "Home", value: "home",component:ProgramHome },
                { label: "My Team", value: "my-teams",component:MyTeams },
             
    
            ],

            "member_manager":[
                { label: "Home", value: "home",component:ProgramHome }, 
                { label: "Participants", value: "participants",component:ProgramParticipants },
                { label: "Tasks", value: "tasks",component:ProgramTasks },
            ],

    },
    "fullservice":{
        "mentor":[
            { label: "Home", value: "home",component:ProgramHome },
            { label: "My Mentees", value: "connections",component:ProgramConnections },
        ],
        "mentee":[
            { label: "Home", value: "home",component:ProgramHome },
            { label: "My Mentors", value: "connections",component:ProgramConnections },
        ],
        "manager":[
            { label: "Home", value: "home",component:ProgramHome }, 
            { label: "Participants", value: "participants",component:ProgramParticipants },
            { label: "Tasks", value: "tasks",component:ProgramTasks },
        ],
    },

        "openNetwork":[
            { label: "Inbound", value: "Inbound", component:ProgramHome },
            { label: "Outbound", value: "Outbound" , component:ProgramHome},
            { label: "Upcoming", value: "Upcoming", component:ProgramHome },
            { label: "Past", value: "Past", component:ProgramHome },
            { label: "Archived", value: "Archived",component:ProgramHome },

        ],
        
}