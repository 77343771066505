import React from "react";
import { SearchTextInputContainer, SearchInput, IconContainer } from "./styles";
import Icon from "../Icon";

function SearchTextInput({
  value,
  setValue,
  placeholder = "Placeholder",
  disabled = false,
  isValid = null,
  isTextArea = false,
  isNotRound = false,
  isHeight = false,
}) {
  return (
    <>
      <SearchTextInputContainer>
        <SearchInput
          isValid={isValid}
          value={value}
          placeholder={isNotRound ? "" : placeholder}
          type={"text"}
          isTextArea={isTextArea}
          isHeight={isHeight}
          disabled={disabled}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          rows={4}
          cols={50}
          min={new Date().toISOString().slice(0, 16)}
        />
        <IconContainer>
          <Icon type={`search`} size="17px" color="#211717a6" />
        </IconContainer>
      </SearchTextInputContainer>
    </>
  );
}

export default SearchTextInput;
