import React, { useEffect, useState } from "react";
import {
  RequestsKeyHeaders,
  ImagePreviews,
  ButtonsWraper,
  NoprogramContainer,
  NoProgramContainer,
  NoProgramContainerInner,
  ProgramLoaderContainer,
  EmptyState,
  NoProgramText,
  NoProgramDescription,
  NoProgramButton,
} from "./style";
import { Context } from "../../../../../Store";
import callAPI from "../../../../../api/apiCaller";
import NoProgramImage from "../home-program.svg";
import Modal from "../../../../../common/Modal";
import NewRequestForm from "../NewRequest";
import {
  addHourToUnixTimeStamp,
  convertDateToCustomFormat,
} from "../../../../../common/utils";
import { useNavigate, useParams } from "react-router";
import Loading from "../../../../../common/Loading";
import { getUserInfo } from "../../../../../Store/User/accessors";
import {
  UpcomingCount,
  CompletedCount,
  UnacceptedCount,
  UpcomingTeamCount,
  CompletedTeamCount,
} from "../../../../../Store/ProgramMeetingCount/actions";
import EmptyStateImage from "./emptyState.png";
import AddProgram from "../../popups/AddProgram";
import { refreshProgramList } from "../../../../../Store/ProgramsList/actions";
import Meetings from "./Meetings";

function ProgramOutBonds({
  isUpcoming,
  isPast,
  currentTab,
  allProgramMeetingStatistics,
  specificProgramMeetingsStatistics,
  isTeam = false,
  isUnfulfilled,
}) {
  const navigate = useNavigate();
  const params = useParams();
  const { dispatch, state } = React.useContext(Context);
  const [openNetworkProgramsMeetings, setOpenNetworkProgramsMeetings] =
    React.useState(null);
  const [openNetworkPrograms, setOpenNetworkPrograms] = React.useState([]);
  const [resetForm, setResetForm] = React.useState({});
  const [showForm, setShowForm] = React.useState(false);
  const [isDeleteModal, setIsDeleteModal] = React.useState(false);
  const [isDeleteRequest, setIsDeleteRequest] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isCalenderDropdownActive, setIsCalenderDropdownActive] =
    useState(false);
  const [isCancelRequest, setIsCancelRequest] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState();
  const [isProcess, setIsProcessing] = useState(null);
  const [disableButtonId, setDisableButtonId] = useState("");

  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [showAddProgram, setShowAddProgram] = useState(false);

  function handleSelect(item) {
    setSelectedItemId(item.id);
    setIsCalenderDropdownActive((prev) => !prev);
  }

  const [eventData, setEventData] = useState({
    eventName: "ICal",
    eventDescription: "This is a sample event description",
  });

  const downloadICS = (icsContent, filename) => {
    const blob = new Blob([icsContent]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const generateICS = (startDate, endDate, item) => {
    const isMyMente = item?.Mentor?.id === item?.user?.id;
    const { eventName, eventDescription } = eventData;
    //direct link to got calender meeting schedule
    const icsContent = `BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-// https://add-to-calendar-pro.com // button v2.4.3 //ENCALSCALE:GREGORIAN\nMETHOD:PUBLISH\nBEGIN:VEVENT\nUID:f4ab1cbb-b29d-4dee-83b1-e83cad00a26f\nDTSTAMP:20231009T122014Z\nDTSTART;VALUE=DATE:${startDate}\nDTEND;VALUE=DATE:${endDate}\nSUMMARY:${
      item.program?.title
    } meeting with ${
      isMyMente ? item.meeting.user.firstName : item.Mentor.firstName
    }\nSEQUENCE:0\nSTATUS:CONFIRMED\nCREATED:20231009T122003Z\nLAST-MODIFIED:20231009T122003Z\nEND:VEVENT\nEND:VCALENDAR`;
    downloadICS(icsContent, `${eventName}.ics`);
  };
  const recipientId = params.connectionId;
  function getUpcommingMeetings() {
    !isTeam
      ? callAPI(
          dispatch,
          isUpcoming
            ? params.selectedProgram && recipientId
              ? "upCommingRequestsOneOnOne"
              : params.selectedProgram
              ? "upCommingRequests"
              : "upCommingForMyAllRequests"
            : params.selectedProgram && recipientId
            ? "pastRequestsOneOnOne"
            : params.selectedProgram
            ? "pastRequests"
            : "pastforAllRequests",
          { id: params.selectedProgram, rid: recipientId }
        ).then((res) => {
          setOpenNetworkProgramsMeetings([]);
          if (res.isResponseOk) {
            if (!isUpcoming && !isUnfulfilled) {
              const filteredData = res.data.filter(
                (data) =>
                  data.meeting?.meetingScheduleDate || data?.meetingScheduleDate
              );
              filteredData.sort((a, b) => {
                const timestampA = new Date(a.meeting?.meetingScheduleDate);
                const timestampB = new Date(b.meeting?.meetingScheduleDate);
                return timestampB - timestampA;
              });
              setOpenNetworkProgramsMeetings(filteredData);
              dispatch(CompletedCount(filteredData.length));
            } else if (!isUpcoming && isUnfulfilled) {
              const filteredData = res.data.filter(
                (data) => data.meeting?.meetingScheduleDate === null
              );
              filteredData.sort((a, b) => {
                const timestampA = new Date(a.meeting?.createdAt);
                const timestampB = new Date(b.meeting?.createdAt);
                return timestampB - timestampA;
              });
              setOpenNetworkProgramsMeetings(filteredData);
            } else {
              setOpenNetworkProgramsMeetings(res.data);
              dispatch(UpcomingCount(res.data.length));
            }
          }
        })
      : callAPI(
          dispatch,
          isUpcoming ? "teamProgramMeetings" : "teamPastProgramMeetings",
          {
            id: params.selectedProgram,
            tid: params.connectionId,
          }
        ).then((res) => {
          setOpenNetworkProgramsMeetings([]);
          if (res.isResponseOk) {
            if (isUpcoming) {
              dispatch(UpcomingTeamCount(res.data.length));
            } else dispatch(CompletedTeamCount(res.data.length));
            setOpenNetworkProgramsMeetings(res.data);
          }
        });
  }

  useEffect(() => {
    if (!params.selectedProgram) {
      callAPI(dispatch, "upCommingForMyAllRequests", {
        id: params.selectedProgram,
        rid: recipientId,
      }).then((res) => {
        if (res.isResponseOk) {
          dispatch(UpcomingCount(res.data.length));
        }
      });
      callAPI(dispatch, "pastforAllRequests", {
        id: params.selectedProgram,
        rid: recipientId,
      }).then((res) => {
        if (res.isResponseOk) {
          if (!isUnfulfilled) {
            const filteredData = res.data.filter(
              (data) =>
                data.meeting?.meetingScheduleDate || data?.meetingScheduleDate
            );
            filteredData.sort((a, b) => {
              const timestampA = new Date(a.meeting?.meetingScheduleDate);
              const timestampB = new Date(b.meeting?.meetingScheduleDate);
              return timestampB - timestampA;
            });
            dispatch(CompletedCount(filteredData.length));
          } else {
            const filteredData = res.data.filter(
              (data) => data.meeting?.meetingScheduleDate === null
            );
            filteredData.sort((a, b) => {
              const timestampA = new Date(a.meeting?.createdAt);
              const timestampB = new Date(b.meeting?.createdAt);
              return timestampB - timestampA;
            });
            dispatch(UnacceptedCount(filteredData.length));
          }
        }
      });
    }
  }, [!params.selectedProgram]);

  useEffect(() => {
    if (isTeam) {
      callAPI(dispatch, "teamProgramMeetings", {
        id: params.selectedProgram,
        tid: params.connectionId,
      }).then((res) => {
        if (res.isResponseOk) {
          dispatch(UpcomingTeamCount(res.data.length));
        }
      });
      callAPI(dispatch, "teamPastProgramMeetings", {
        id: params.selectedProgram,
        tid: params.connectionId,
      }).then((res) => {
        if (res.isResponseOk) {
          dispatch(CompletedTeamCount(res.data.length));
        }
      });
    }
  }, [isTeam, params.connectionId, params.selectedProgram]);

  function reassignMentee(item) {
    setDisableButtonId(item.id);
    setIsLoading(true);
    callAPI(dispatch, "reassignMenteeAppHome", {
      id: item?.program?.id,
      mentorId: item?.Mentor?.participant[0]?.id,
      menteeId: item?.meeting?.user?.id,
      meetingId: item?.meeting?.id,
    }).then((res) => {
      setIsLoading(false);
      getUpcommingMeetings();
    });
  }

  function removeItemFromArray(itemToRemove) {
    const updatedArray = openNetworkProgramsMeetings.filter(
      (item) => item.meetingId !== itemToRemove
    );
    setOpenNetworkProgramsMeetings(updatedArray);
  }

  function deleteProgram() {
    setIsDeleteRequest(true);
    // eslint-disable-next-line no-lone-blocks
    {
      !isTeam && selectedMeeting
        ? callAPI(dispatch, "trimOutMeetingRequest", {
            rid: selectedMeeting.meetingId,
            id: selectedMeeting.meetingId,
          }).then(() => {
            setIsDeleteRequest(false);
            removeItemFromArray(selectedMeeting.meetingId);
            setIsDeleteModal(false);
            if (params.selectedProgram) {
              specificProgramMeetingsStatistics();
            } else {
              allProgramMeetingStatistics();
            }
          })
        : callAPI(dispatch, "deleteTeamMeetingRequest", {
            rid: selectedMeeting.id,
            id: params.selectedProgram,
            tid: params.connectionId,
          }).then(() => {
            setIsDeleteRequest(false);
            removeItemFromArray(selectedMeeting.meetingId);
            setIsDeleteModal(false);
            if (params.selectedProgram) {
              specificProgramMeetingsStatistics();
            } else {
              allProgramMeetingStatistics();
            }
          });
    }
  }

  function handlePerkBalance(item) {
    setDisableButtonId(item.id);
    setIsProcessing(true);
    callAPI(dispatch, "requestPerk", {
      id: item?.meeting?.id,
      perk: item?.meeting?.perk,
      programId: item?.program?.id,
    }).then(() => {
      // programs();
      getUpcommingMeetings();
      setIsProcessing(false);
    });
  }

  const handleAddToCalender = (item) => (type) => {
    const isMyMente = item?.Mentor?.id === item?.user?.id;
    callAPI(dispatch, "AddtoCalendar", {
      id: item.id,
      addedToCalender: true,
    }).then(() => {});

    const startDate = convertDateToCustomFormat(
      item.meeting?.meetingScheduleDate
    );
    const endDate = convertDateToCustomFormat(
      addHourToUnixTimeStamp(item.meeting?.meetingScheduleDate)
    );
    if (type === "google") {
      const url = `https://calendar.google.com/calendar/u/0/r/eventedit?dates=${startDate}/${endDate}&text=${
        item.program?.title
      } meeting with ${
        isMyMente ? item.meeting.user.firstName : item.Mentor.firstName
      }`;
      window.open(url, "_blank");
    }
    if (type === "ical") {
      generateICS(startDate, endDate, item);
    }
    setIsCalenderDropdownActive((prev) => !prev);
    getUpcommingMeetings();
  };

  const locations = [
    { title: "In person", id: "In person" },
    { title: "virtual", id: "virtual" },
  ];

  const handleCancelRequest = (item) => {
    setIsDeleteRequest(true);
    callAPI(dispatch, "cancelMeetingRequest", { id: item.meeting.id }).then(
      () => {
        setIsDeleteRequest(false);
        if (params.selectedProgram) {
          specificProgramMeetingsStatistics();
        } else {
          allProgramMeetingStatistics();
        }
      }
    );

    setIsCancelRequest(false);
    removeItemFromArray(item.id);
  };

  React.useEffect(() => {
    getUpcommingMeetings();
    // programs();
    // to render meeting count

    if (params.selectedProgram) {
      specificProgramMeetingsStatistics();
    } else {
      allProgramMeetingStatistics();
    }
    return () => {
      setOpenNetworkProgramsMeetings(null);
    };
  }, [currentTab]);

  if (!isUpcoming && openNetworkProgramsMeetings === null && !isUnfulfilled) {
    return (
      <ProgramLoaderContainer>
        <Loading loadingItem="Completed Meetings" />
      </ProgramLoaderContainer>
    );
  } else if (
    !isUpcoming &&
    openNetworkProgramsMeetings === null &&
    isUnfulfilled
  ) {
    return (
      <ProgramLoaderContainer>
        <Loading loadingItem="Unaccepted Meetings" />
      </ProgramLoaderContainer>
    );
  } else if (openNetworkProgramsMeetings === null) {
    return (
      <ProgramLoaderContainer>
        <Loading loadingItem="Upcoming Meetings" />
      </ProgramLoaderContainer>
    );
  }
  if (openNetworkProgramsMeetings.length === 0 && isTeam === false) {
    return (
      <NoProgramContainer isProgram={!params.selectedProgram}>
        <NoProgramContainerInner isProgram={!params.selectedProgram}>
          <EmptyState src={EmptyStateImage} alt="Empty Meetings" />
          <NoProgramText>
            You do not currently have any{" "}
            {isUpcoming
              ? "Upcoming"
              : isUnfulfilled
              ? "Unaccepted"
              : "Completed"}{" "}
            Meetings.
          </NoProgramText>
          {!params.selectedProgram && (
            <>
              <NoProgramDescription>
                Click the button below to create new program.
              </NoProgramDescription>
              <NoProgramButton
                onClick={() => {
                  setShowAddProgram(true);
                }}
              >
                Create a new Program
              </NoProgramButton>
            </>
          )}
        </NoProgramContainerInner>
        {showAddProgram && (
          <AddProgram
            closeModal={(shouldRefresh) => {
              if (shouldRefresh) {
                dispatch(refreshProgramList());
              }
              setShowAddProgram(false);
            }}
          />
        )}
      </NoProgramContainer>
    );
  }

  if (openNetworkProgramsMeetings.length === 0 && isTeam === true) {
    getUpcommingMeetings();
    return (
      <NoprogramContainer>
        <RequestsKeyHeaders size={13} widthSize={300} marginSpace={30}>
          You do not currently have any{" "}
          {isUpcoming ? "Upcoming" : isUnfulfilled ? "Unaccepted" : "Completed"}{" "}
          Team Meetings.
        </RequestsKeyHeaders>
        <ImagePreviews src={NoProgramImage} />
        <ButtonsWraper></ButtonsWraper>
      </NoprogramContainer>
    );
  }

  return (
    <>
      {isUpcoming ? (
        <Meetings
          openNetworkProgramsMeetings={openNetworkProgramsMeetings}
          params={params}
          navigate={navigate}
          setIsDeleteModal={setIsDeleteModal}
          setSelectedMeeting={setSelectedMeeting}
          setShowForm={setShowForm}
          handleAddToCalender={handleAddToCalender}
          handleCancelRequest={handleCancelRequest}
          isUpcoming={isUpcoming}
        />
      ) : isPast && openNetworkProgramsMeetings !== null ? (
        <Meetings
          openNetworkProgramsMeetings={openNetworkProgramsMeetings}
          params={params}
          navigate={navigate}
          setIsDeleteModal={setIsDeleteModal}
          setSelectedMeeting={setSelectedMeeting}
          setShowForm={setShowForm}
          handleAddToCalender={handleAddToCalender}
          handleCancelRequest={handleCancelRequest}
          isPast={isPast}
          reassignMentee={reassignMentee}
          disableButtonId={disableButtonId}
          isLoading={isLoading}
        />
      ) : (
        ""
      )}
      {showForm && (
        <Modal onClose={() => setShowForm(false)}>
          <NewRequestForm
            openNetworkPrograms={openNetworkPrograms}
            resetForm={resetForm}
            setResetForm={setResetForm}
            setShowForm={setShowForm}
            editForm={showForm}
            isEdit={true}
          />
        </Modal>
      )}
    </>
  );
}

export default ProgramOutBonds;
