import React from "react";
import { PillsContainer,Pill, ItemNumber } from "./styles";
import { Context } from "../../Store";
import { getCompletedTeamMeetingsCount, getRequestedMeetingsCount, getUpcomingTeamMeetingsCount } from "../../Store/ProgramMeetingCount/accessors";

// Similar to Tabs
function Pills({options,value,setValue,count,isWidth,isMeetingTab=false,applicationPills, meetings }) {
  const { dispatch, state } = React.useContext(Context);
  const RenderComponent = (value) => {
    switch (value) {
      case  "Requests":
        return getRequestedMeetingsCount(state).count;
      case "Upcoming":
        return getUpcomingTeamMeetingsCount(state).count ? getUpcomingTeamMeetingsCount(state).count : count?.upComingRequests
      case "Past":
        return getCompletedTeamMeetingsCount(state).count ? getCompletedTeamMeetingsCount(state).count : count?.pastRequests;
      case "unfulfilled":
        return count?.unfulfilledRequests;
      case "Archived":
        return count?.archivedRequests;
    }
  };

  return <PillsContainer isWidth={isWidth} isMeetingTab={isMeetingTab} meetings={meetings}>
     {
      options && options.map(option => 
        <Pill 
        isWidth={isWidth}
        applicationPills={applicationPills}
        meetings={meetings}
        key={option?.value} active={value===option?.value} onClick={()=>{
          setValue(option?.value);
        }}>{option.label}
        {RenderComponent(option?.value) && <ItemNumber>{RenderComponent(option?.value)}</ItemNumber>}
        </Pill>
      )
    }
  </PillsContainer>;
}
export default Pills;