/* eslint-disable import/no-anonymous-default-export */
const BASE = process.env.NODE_ENVIRONMENT === "production"
    ? "https://onemauve.com"
    // : "https://onemauve.com"
    : "http://dev.onemauve.com"
    // : "https://onemauve.com"
    // : "http://dev.onemauve.com"
    // : "https://1k3q96w6-3000.inc1.devtunnels.ms";
    // : "http://localhost:3000";
    


const version = "v1";
/**
 * Format Request and Format Response are things that should be implemented in the backend in the future
 */

export default {
  // "login":{
  //     method:"POST",
  //     url:BASE+"/api/`origin/${version}/postLoginRequest",
  //     info:{
  //         "inProgress":"Logging in User",
  
  //         "success":"Successfully Logged in User",
  //         "failure":"Failed to log in User",
  //     },
  //     formatRequest:(request)=>{
  //         return {
  //             ...request,
  //             loginMethod:0,
  //             password:atob(request.password)
  //         }
  //     },
  //     formatResponse:(response)=>{
  //         if(response === "This user is not registered. Please do the registration and then sign in."){
  //             throw "403 | User Unauthorized"
  //         }
  //         else if (response === "User will be allowed for login, proceeding."){
  //             return {
  //                 firstName: "Amal",
  //                 lastName: "Jose",
  //                 id: 1,
  //                 role: "user",
  //                 image: "https://as2.ftcdn.net/v2/jpg/03/64/21/11/1000_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg",
  //                 email: "amaljose96@gmail.com",
  //                 accessToken: "test"
  //             }
  //         }
  //     }
  // },
  createUser: {
    method: "POST",
    url: BASE + `/api/${version}/auth/register`,
    info: {
      inProgress: "Creating User",
      success: "Successfully Created User.",
      failure: "Failed to Create User",
    },
  },

  login: {
    method: "POST",
    url: BASE + `/api/${version}/auth/login`,
    info: {
      inProgress: "Logging in",
      success: "Login successful",
      failure: "login Failed",
    },
  },

  checkAccessToken: {
    method: "GET",
    url: BASE + `/api/${version}/auth/current-user`,
  },
  sendForgotPasswordEmail: {
    method: "POST",
    url: BASE + `/api/${version}/auth/passwordreset/initialize`,
    info: {
      inProgress: "send link to email",
      success: "Password reset link email has been sent on your email",
      failure: "some thing went wrong",
    },
  },

  changePassword: {
    method: "POST",
    url: BASE + `/api/${version}/user/password/change`,
    info: {
      inProgress: "changing password",
      success: "password has been changed successfully",
      failure: "password change request failed",
    },
  },

  updateUser: {
    method: "PATCH",
    url: BASE + `/api/${version}/user/:id`,
    info: {
      inProgress: "updating user",
      success: "user updated successfully",
      failure: "update request failed",
    },
  },
  signedUrl: {
    method: "POST",
    url: BASE + `/api/${version}/integration/signed-url`,
    info: {
      inProgress: "uploading image",
      success: "image has been uploaded successfully",
      failure: "some thing went wrong",
    },
  },

  uploadFile: {
    method: "PUT",
  },
  addNewProgram: {
    method: "POST",
    url: BASE + `/api/${version}/program`,
    info: {
      inProgress: "uploading Image",
      success: "Image uploaded Successfully",
      failure: "image uploading failed",
    },
  },
  logout: {
    method: "POST",
    url: BASE + `/api/${version}/auth/logout`,
  },
  fetchPrograms: {
    method: "GET",
    url: BASE + `/api/${version}/program/participant/enrolled`,
  },
  getProgramInformation: {
    method: "GET",
    url: BASE + `/api/${version}/program/:id`,
    accessToken: localStorage.getItem("accessToken"),
  },
  saveProgram: {
    method: "PATCH",
    url: BASE + `/api/${version}/program/:id`,
    accessToken: localStorage.getItem("accessToken"),
  },

  addFolder: {
    method: "POST",
    url: BASE + `/api/${version}/program/folder/add`,
    info: {
      inProgress: "logging out",
      success: "logged out",
      failure: "some thing went wrong",
    },
  },

  sendAnnouncement: {
    method: "POST",
    url: BASE + `/api/${version}/program/announcements`,
    info: {
      inProgress: "announcement sending",
      success: "announcement has been send",
      failure: "some thing went wrong",
    },
  },

  getProgramAnnouncements: {
    method: "GET",
    url: BASE + `/api/${version}/program/announcements/getByProgram/:id`,
  },

  getAnnouncement: {
    method: "GET",
    url: BASE + `/api/${version}/program/announcements/:id`,
  },

  removeProgramManager: {
    method: "DELETE",
    url: BASE + `/api/${version}/program/manager/remove`,
    info: {
      inProgress: "logging out",
      success: "delete Program manager",
      failure: "some thing went wrong",
    },
  },

  saveAnnouncement: {
    method: "PATCH",
    url: BASE + `/api/${version}/program/announcements/:id`,
  },

  addProgramManager: {
    method: "POST",
    url: BASE + `/api/${version}/program/manager/add`,
    info: {
      inProgress: "inviting manager",
      success: "invited successfully",
      failure: "some thing went wrong",
    },
  },

  moveProgram: {
    method: "POST",
    url: BASE + `/api/${version}/program/folder/move`,
    info: {
      inProgress: "inviting manager",
      success: "invited successfully",
      failure: "some thing went wrong",
    },
  },

  addParticipants: {
    method: "POST",
    url: BASE + `/api/${version}/program/participant`,
    info: {
      inProgress: "inviting participant",
      success: "invited successfully",
      failure: "some thing went wrong",
    },
  },

  deleteProgram: {
    method: "DELETE",
    url: BASE + `/api/${version}/program/:id`,
    info: {
      inProgress: "Deleting Program",
      success: "successfully deleted",
      failure: "some thing went wrong",
    },
  },

  applyToProgram: {
    method: "POST",
    url: BASE + `/api/${version}/program/request/:id`,
    info: {
      inProgress: "Applying for Program",
      success: "successfully Applied",
      failure: "some thing went wrong",
    },
  },

  getApplicationForm: {
    method: "GET",
    url: BASE + `/api/${version}/program/questions/:id`,
  },

  getProgramInvites: {
    method: "GET",
    url: BASE + `/api/${version}/program/participant/invited/:id`,
  },

  denyRequest: {
    method: "PATCH",
    url: BASE + `/api/${version}/program/participant/decline`,
  },
  acceptRequest: {
    method: "PATCH",
    url: BASE + `/api/${version}/program/participant/accept`,
  },

  getConnections: {
    method: "GET",
    url: BASE + `/api/${version}/program/users/my-mentors/:id`,
  },

  //mentee by programId
  getMyMentees: {
    method: "GET",
    url: BASE + `/api/${version}/program/users/my-mentee/:id`,
  },

  getSelectedMentee: {
    method: "GET",
    url: BASE + `/api/${version}/program/users/get-mentee/:id`,
  },

  getSelectedMentor: {
    method: "GET",
    url: BASE + `/api/${version}/program/users/get-mentor/:id`,
  },

  // /participant/track-hour

  getProgramConnectionTimesheet: {
    method: "GET",
    url:
      BASE +
      `/api/${version}/participant/track-hour?programId=:id&recipientId=:rid`,
  },

  addTimesheetRecord: {
    method: "POST",
    url: BASE + `/api/${version}/participant/track-hour`,
  },

  deleteTimesheetRecord: {
    method: "DELETE",
    url: BASE + `/api/${version}/participant/track-hour/:id`,
  },

  approveTimesheetRecord: {
    method: "POST",
    url: BASE + `/api/${version}/participant/track-hour/:id?status=approved`,
  },

  approveTrackHours: {
    method: "POST",
    url: BASE + `/api/${version}/participant/track-hour/:id?status=approved`,
  },

  deleteTrackHours: {
    method: "POST",
    url: BASE + `/api/${version}/participant/track-hour/:id?status=rejected`,
  },

  saveTask: {
    method: "POST",
    url: BASE + `/api/${version}/program/task`,
  },

  deleteTask: {
    method: "Delete",
    url: BASE + `/api/${version}/program/task/delete/my-single-task/:id`,
  },

  getProgramTasks: {
    method: "GET",
    url: BASE + `/api/${version}/program/task/:id`,
  },

  getProgramTaskMembers: {
    method: "GET",
    url: BASE + `/api/${version}/program/task/my-single-task/:id`,
  },

  getTask: {
    method: "GET",
    url: BASE + `/api/${version}/program/task/:id/:rid`,
  },
  updateTask: {
    method: "PATCH",
    url: BASE + `/api/${version}/program/task/:id`,
  },

  resendInvite: {
    method: "GET",
    url: BASE + `/api/${version}/invite/resend/:id`,
  },

  markTaskAsCompleteForUser: {
    method: "GET",
    url: BASE + `/api/${version}/program/task/my-tasks/complete/:id/:tid/:rid`,
  },

  getProgramParticipants: {
    method: "GET",
    url: BASE + `/api/${version}/program/participant/:id`,
  },

  getMentorProgramParticipants: {
    method: "GET",
    url: BASE + `/api/${version}/program/participant/mentors/participants/:id?page=:page&take=:take`,
  },


  getSearchMentorProgramParticipants: {
    method: "GET",
    url: BASE + `/api/${version}/program/participant/all-mentors/participants/:id`,
  },

  getUnAssignedProgramParticipants: {
    method: "GET",
    url: BASE + `/api/${version}/program/participant/unassigned/:id?page=:page&take=:take`,
  },

  getUserProgramInfo: {
    method: "GET",
    url:
      BASE +
      `/api/${version}/program/participant/profile?profileId=:rid&programId=:id`,
  },

  getAvailableMentors: {
    method: "GET",
    url: BASE + `/api/${version}/program/participant/mentors/:id`,
  },

  reassignMentee: {
    method: "POST",
    url: BASE + `/api/${version}/program/participant/assign-mentor/:id?appHome=false`,
  },

  reassignMenteeAppHome: {
    method: "POST",
    url: BASE + `/api/${version}/program/participant/assign-mentor/:id?appHome=true`,
  },

  cancelInvite: {
    method: "DELETE",
    url: BASE + `/api/${version}/invite/:id`,
  },

  remindAll: {
    method: "POST",
    url: BASE + `/api/${version}/program/task/remind/bulk/:id`,
    info: {
      inProgress: "sending Reminder to all",
      success: "Reminder sended to all  successfully",
      failure: "some thing went wrong",
    },
  },

  remindUser: {
    method: "POST",
    url: BASE + `/api/${version}/program/task/remind/:id`,
    info: {
      inProgress: "sending Reminder",
      success: "Reminder sended successfully",
      failure: "some thing went wrong",
    },
  },

  markAllTaskAsComplete: {
    method: "POST",
    url: BASE + `/api/${version}/program/task/complete/bulk/:id`,
  },
  markTeamAllTaskAsComplete: {
    method: "PATCH",
    url: BASE + `/api/${version}/team-tasks/bulk/update`,
  },

  getNotifications: {
    method: "GET",
    url: BASE + `/api/${version}/notification?page=:page&take=:take`,
  },

  saveApplicationForm: {
    method: "POST",
    url: BASE + `/api/${version}/program/application/:id`,
  },
  getMessageListForPersonal: {
    method: "GET",
    url: BASE + `/api/${version}/chat/v2/conversations`,
  },

  getInitiatedMessageList: {
    method: "GET",
    url: BASE + `/api/${version}/chat/v3/conversations?page=:page&take=:take`,
  },


  // getMessageListForPersonal: {
  //   method: "GET",
  //   url: BASE + `/api/${version}/ /chat/conversation",
  // },

  updateGroupName:{
    method: "PATCH",
    url: BASE + `/api/${version}/chat/group/:id`,
    info: {
      success: "Group Name Updated",
      failure: "some thing went wrong",
    },
 
},
  markNotificationAsRead: {
    method: "PATCH",
    url: BASE + `/api/${version}/notification/mark-read/:id`,
  },

  getUser: {
    method: "GET",
    url: BASE + `/api/${version}/user/:id`,
  },

  // /chat/conversation/1
  getChat: {
    method: "GET",
    // url: BASE + `/api/${version}/chat/conversation/:id`,
    url: BASE + `/api/${version}/chat/conversation/:id?page=:page&take=:take`,

  },

  createGroup: {
    method: "POST",
    url: BASE + `/api/${version}/chat/group`,
  },
  sendMessage: {
    method: "POST",
    url: BASE + `/api/${version}/chat/send`,
  },

  getMessageList: {
    method: "GET",
    //id is page number
    url: BASE + `/api/${version}/chat/conversations/`,
  },

  initializeMessageList: {
    method: "GET",
    //id is page number
    url: BASE + `/api/${version}/chat/initialize-conversations/`,
  },

  initializeConversation: {
    method: "GET",
    //id is page number
    url: BASE + `/api/${version}/chat/initialize-conversation/:id`,
  },

  getGroupMessageList: {
    method: "GET",
    url: BASE + `/api/${version}/chat/all/group`,
  },

  getInitiatedGroupMessageList: {
    method: "GET",
    url: BASE + `/api/${version}/chat/all/initiatedgroup`,
  },

  leaveGroupMessages: {
    method: "DELETE",
    url: BASE + `/api/${version}/chat/conversation/leave/:id`,
    info: {
      inProgress: "Leaving Conversation ",
      success: "Conversation Left",
      failure: "some thing went wrong",
    },
  },
  getGroupMessages: {
    method: "GET",
    // url: BASE + `/api/${version}/chat/group/:id`,
    url: BASE + `/api/${version}/chat/group/:id?page=:page&take=:take`,
  },

  createNewConversation: {
    method: "POST",
    url: BASE + `/api/${version}/chat/initialize/private-chat`,
  },
  
  createNewUserInConversation: {
    method: "POST",
    url: BASE + `/api/${version}/chat/group/:id`,
    info: {
      inProgress: "New Member Adding",
      success: "Member Added successfully ",
      failure: "some thing went wrong",
    },
  },

  menteeTasks: {
    method: "GET",
    url: BASE + `/api/${version}/program/task/user/:rid/:id`,
  },
  markTaskAsCompleteForCurrentUser: {
    method: "GET",
    url: BASE + `/api/${version}/program/task/my-tasks/complete/:id/:tid/:rid`,
  },

  forgotPassword: {
    method: "POST",
    url: BASE + `/api/${version}/auth/passwordreset/complete/:id`,
    info: {
      inProgress: "forgetting password",
      success: "password has been reset",
      failure: "some thing went wrong",
    },
  },

  verifyAccount: {
    method: "POST",
    url: BASE + `/api/${version}/auth/verify-email`,
    info: {
      inProgress: "verifying account",
      success: "congrats your account has been verified",
      failure: "some thing went wrong",
    },
  },

  resendEmail: {
    method: "POST",
    url: BASE + `/api/${version}/auth/resend/verification-email`,
    info: {
      inProgress: "verifying account",
      success: "congrats your account has been verified",
      failure: "some thing went wrong",
    },
  },

  deleteUser: {
    method: "POST",
    url: BASE + `/api/${version}/user/deactivate-account`,
  },

  markConversationAsRead: {
    method: "POST",
    url: BASE + `/api/${version}/chat/conversation/mark/as/read/:id`,
  },

  unReadMessage: {
    method: "GET",
    url: BASE + `/api/${version}/chat/unread/count`,
  },

  getMenteeTrackHour: {
    method: "GET",
    url: BASE + `/api/${version}/participant/track-hour/mentee/:id`,
  },

  getMentorTrackHours: {
    method: "GET",
    url: BASE + `/api/${version}/participant/track-hour/:id/:rid`,
  }, // getMentor: {
  //   method: "GET",
  //   url: BASE + `/api/${version}/program/users/my-mentor/:id`,
  // },
  // getMentee: {
  //   method: "GET",
  //   url: BASE + `/api/${version}/program/users/my-mentor/:id",
  // },

  getOpenNetworkPrograms: {
    method: "GET",
    url: BASE + `/api/${version}/program/v2/:id`,
  },

  createMeeting: {
    method: "POST",
    url: BASE + `/api/${version}/meetings`,
    info: {
      inProgress: "Creating request for Meeting",
      success: "Request has been created",
      failure: "some thing went wrong",
    },
  },
  upCommingRequests: {
    method: "GET",
    url:
      BASE +
      `/api/${version}/user-meetings/v2?order[createdAt]=ASC&where[status]=UPCOMING&relations[]=meeting&relations[]=meeting.user&relations[]=program&relations[]=program.programBalance&relations[]=user&where[programId]=:id`,
  },

  upCommingRequestsOneOnOne: {
    method: "GET",
    url:
      BASE +
      `/api/${version}/user-meetings/v2?order[createdAt]=ASC&where[status]=UPCOMING&relations[]=meeting&relations[]=meeting.user&relations[]=program&relations[]=program.programBalance&relations[]=user&where[programId]=:id&where[userId]=:rid`,
  },

  upCommingForMyAllRequests: {
    method: "GET",
    url:
      BASE +
      `/api/${version}/user-meetings/v2?order[createdAt]=ASC&where[status]=UPCOMING&relations[]=meeting&relations[]=meeting.user&relations[]=program&relations[]=program.programBalance&relations[]=user`,
  },

  ProgramNewRequestForManager: {
    method: "GET",
    url:
      BASE +
      `/api/${version}/meetings?order[createdAt]=ASC&where[status]=UPCOMING&relations[]=user&relations[]=program&where[programId]=:id`,
  },

  ProgramCompleteRequestForManager: {
    method: "GET",
    url:
      BASE +
      `/api/${version}/meetings?order[createdAt]=ASC&where[status]=ACCEPT&relations[]=user&relations[]=program&where[programId]=:id`,
  },

  ProgramUnFullFiledRequestForManager: {
    method: "GET",
    url:
      BASE +
      `/api/${version}/meetings?order[createdAt]=ASC&where[status]=PENDING&relations[]=user&relations[]=program&where[programId]=:id`,
  },

  pastRequests: {
    method: "GET",
    url:
      BASE +
      `/api/${version}/user-meetings/v2?relations[]=user&relations[]=meeting&relations[]=meeting.user&relations[]=program&order[createdAt]=ASC&where[status]=PAST&where[programId]=:id`,
  },

  pastRequestsOneOnOne: {
    method: "GET",
    url:
      BASE +
      `/api/${version}/user-meetings/v2?relations[]=user&relations[]=meeting&relations[]=meeting.user&relations[]=program&order[createdAt]=ASC&where[status]=PAST&where[programId]=:id&where[userId]=:rid`,
  },

  pastforAllRequests: {
    method: "GET",
    url:
      BASE +
      `/api/${version}/user-meetings/v2?relations[]=user&relations[]=meeting&relations[]=meeting.user&relations[]=program&order[createdAt]=ASC&where[status]=PAST`,
  },
  getOpenNetworkRequest: {
    method: "GET",
    url:
      BASE +
      `/api/${version}/user-meetings/Requests?order[createdAt]=ASC&where[status]=PENDING&relations[]=meeting&relations[]=meeting.user&relations[]=program&relations[]=user&where[programId]=:id`,
  },
  getOpenNetworkRequestOneOnOne: {
    method: "GET",
    url:
      BASE +
      `/api/${version}/user-meetings/Requests?order[createdAt]=ASC&where[status]=PENDING&relations[]=meeting&relations[]=meeting.user&relations[]=program&relations[]=user&where[programId]=:id&where[userId]=:rid`,
  },

  getOpenNetworkArchiveRequests: {
    method: "GET",
    url:
      BASE +
      `/api/${version}/meetings?relations[]=user&relations[]=userMeetings&order[createdAt]=ASC&where[status]=ARCHIVE&where[programId]=:id`,
  },

  acceptMeetingRequest: {
    method: "PATCH",
    url: BASE + `/api/${version}/meetings/:id`,
    info: {
      inProgress: "Accepting request",
      success: "Meeting has been accepted",
      failure: "Meeting can't be accepted right now",
    },
  },

  updateMeeting: {
    method: "PATCH",
    url: BASE + `/api/${version}/meetings/:id`,
    info: {
      inProgress: "Accepting Meeting request",
      success: "Meeting Request has Updated",
      failure: "Meeting can't be updated right now",
    },
  },


  cancelMeetingRequest: {
    method: "DELETE",
    url: BASE + `/api/${version}/meetings/cancel/:id`,
    info: {
      inProgress: "Cancel Meeting request",
      success: "Meeting has been cancelled",
      failure: "Meeting can't be Cancel right now",
    },
  },

  handleRequests: {
    method: "POST",
    url: BASE + `/api/${version}/user-meetings`,
    info: {
      inProgress: "Archiving request",
      success: "Request has been archived",
      failure: "failed Archived request",
    },
  },

  handleRequestsUndo: {
    method: "DELETE",
    url: BASE + `/api/${version}/user-meetings/:id`,
    info: {
      inProgress: "Removing Archived request",
      success: "Request has been un-archived",
      failure: "failed un archived request",
    },
  },
  viewMoreMeeting: {
    method: "GET",
    url:
      BASE +
      `/api/${version}/meetings/statistics?where[userId]=:rid&where[programId]=:id`,
  },

  AddtoCalendar: {
    method: "PATCH",
    url: BASE + `/api/${version}/user-meetings/:id`,
    info: {
      inProgress: "Adding to Calendar",
      success: "Added to Calendar",
      failure: "failed Added to Calendar",
    }
  },

  UpdateTermsConditions: {
    method: "PATCH",
    url: BASE + `/api/${version}/auth/update-agree-terms-conditions/:id`,
  },

  //delete meetings

  deleteMeetingRequest: {
    method: "DELETE",
    url: BASE + `/api/${version}/meetings/:rid?&where[id]=:id&where[programId]=:tid`,
    info: {
      inProgress: "Canceling Meeting request",
      success: "Meeting  has been canceled",
      failure: "failed to cancel meeting request",
    },
  },
  // not params 
  trimOutMeetingRequest: {
    method: "DELETE",
    url: BASE + `/api/${version}/meetings/:rid?&where[id]=:id`,
    info: {
      inProgress: "Canceling Meeting request",
      success: "Meeting  has been canceled",
      failure: "failed to cancel meeting request",
    },
  },
  //delete Team Meeting
  deleteTeamMeetingRequest: {
    method: "DELETE",
    url: BASE + `/api/${version}/meetings/:rid?where[programId]=:id&where[teamId]=:tid`,
    info: {
      inProgress: "Canceling Meeting request",
      success: "Meeting  has been canceled",
      failure: "failed to cancel meeting request",
    },
  },

  //billings
  billingHistory: {
    method: "GET",
    url: BASE + `/api/${version}/paypal/?order[createdAt]=DESC`,
  },
  billingReceipt: {
    method: "GET",
    url: BASE + `/api/${version}/paypal/receipt/:id`,
  },
// perk which item you request (coffee and lunch)
  requestPerk: {
    method: "PATCH",
    url: BASE + `/api/${version}/program/Balance/:id`,
    info: {
      inProgress: "requesting for voucher",
      success: "request for voucher has been succeed",
      failure: "insufficient voucher ",
    },
  },
  // meeting count
  specificProgramMeetingsCount: {
    method: "GET",
    url: BASE + `/api/${version}/user-meetings/meetings-count?where[programId]=:id`,
  },
  specificProgramMeetingsCountOneOnOne: {
    method: "GET",
    url: BASE + `/api/${version}/user-meetings/meetings-count?where[programId]=:id&where[userId]=:rid`,
  },
  allProgramsMeetingsCount: {
    method: "GET",
    url: BASE + `/api/${version}/user-meetings/meetings-count`,
  },

  // get my team meetings list
  teamProgramMeetings: {
    method: "GET",
    url: BASE + `/api/${version}/meetings?relations[]=user&relations[]=program&order[date]=ASC&where[programId]=:id&where[status]=UPCOMING&where[teamId]=:tid&relations[]=team&relations[]=teamMeetings`,
  },
  // team past meeting
  teamPastProgramMeetings: {
    method: "GET",
    url: BASE + `/api/${version}/meetings?relations[]=user&relations[]=program&order[date]=ASC&where[programId]=:id&where[status]=PAST&where[teamId]=:tid&relations[]=team&relations[]=teamMeetings`,
  },


// miro board APIs
  miroAuthorization: {
    method: "GET",
    url: BASE + `/api/${version}/kanban/miro/callback?code=:id`,
  },
  
  createKanban: {
    method: "POST",
    url: BASE + `/api/${version}/kanban`,
    info: {
      inProgress: "adding miro board",
      success: "miro board successfully created",
      failure: "failed to create a board on miro",
    },
  },
  getKanbanBoards: {
    method: "Get",
    url: BASE + `/api/${version}/kanban?where[userId]=:rid&where[programId]=:id`,
   
  },

  getTeamBoards: {
    method: "Get",
    url: BASE + `/api/${version}/kanban?where[teamId]=:rid&where[programId]=:id`,
   
  },
  updateBoard: {
    method: "PATCH",
    url: BASE + `/api/${version}/kanban/:id`,
   
  },
  DeleteBoard: {
    method: "DELETE",
    url: BASE + `/api/${version}/kanban/:id`,
   
  },
  AddTeamMember: {
    method: "POST",
    url: BASE + `/api/${version}/team`,
    info: {
      inProgress: "adding Team",
      success: "Team  successfully created",
      failure: "failed to create a Team ",
    },
  },
  UpdateTeamMember: {
    method: "PATCH",
    url: BASE + `/api/${version}/team/:id`,
    info: {
      inProgress: "updating Team ",
      success: "Team  successfully updated",
      failure: "failed to update a Team ",
    },
  },
  getTeamMembers: {
    method: "GET",
    url: BASE + `/api/${version}/team?where[programId]=:id&relations[]=teamMembers.user&relations[]=teamMembers`,
    
  },
  getMyteams:{
    method: "GET",
    url: BASE + `/api/${version}/team?where[programId]=:id`,
    
  },

  getSpecificTeamData:{
    method: "GET",
    url: BASE + `/api/${version}/team?where[id]=:tid&where[programId]=:id&relations[]=teamMembers.user&relations[]=teamMembers`
  },
  AssignMemberToTeam:{
    method: "POST",
    url: BASE + `/api/${version}/team-members`,
    info: {
      inProgress: "assigning Team Member",
      success: "Team Member successfully assigned",
      failure: "failed to assign a Team Member",
    },
  },

  getProgramManagers:{
    method: "GET",
    url: BASE + `/api/${version}/program/manager/getProgramManager?where[programId]=:id&relations[]=user&relations[]=program`,
},
  DeleteMember:{
    method: "DELETE",
    url: BASE + `/api/${version}/program/participant/remove/:tid/:id`,
    info: {
      inProgress: " removing program participant",
      success: "program participant removed",
      failure: "failed to remove program participant",
    },
},
  RemoveFromGroup:{
    method: "DELETE",
    url: BASE + `/api/${version}/team-members/:id`,
    info: {
      inProgress: " removing member from group",
      success: " member removed from group",
      failure: "failed member to remove from group",
    },
},
  AssignMemberGroup:{
    method: "PATCH",
    url: BASE + `/api/${version}/team-members/:id`,
 
},


teamTasks:{
  method: "GET",
  url: BASE + `/api/${version}/team-tasks?where[teamId]=:id&relations[]=task`,
},

markTeamTaskAsComplete:{
  method: "PATCH",
  url: BASE + `/api/${version}/team-tasks/:id`,
},

markTourCompleted:{
  method: "PATCH",
  url: BASE + `/api/${version}/user/update-tour/:id?isTourCompletedFlag=true`,
},

updateStepCompleted:{
  method: "PATCH",
  url: BASE + `/api/${version}/user/update-tour/:id?step=:step`,
}

};