import React from "react";
import { UserInfoContainer, MeetingInfoLabel, Flex } from "./styles";
import Loading from "../../../../../common/Loading";
import { convertToLinks, unixStampToDate } from "../../../../../common/utils";
import styled from "styled-components";
import Icon from "../../../../../common/Icon";

const MeetingSpan = styled.span`
  color: #302F2F;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: ${props => props.space && "30px"}
`;

function MeetingInfo({ data, closeModal }) {
  if (data === null) {
    return (
      <UserInfoContainer>
        <Loading loadingItem="Meeting Information" />
      </UserInfoContainer>
    );
  }
  return (
    <>
      <UserInfoContainer>
        <MeetingInfoLabel>
          <Icon type="person" />
          Name:
          <MeetingSpan>
            {data?.user?.firstName} {data?.user?.lastName}
          </MeetingSpan>
        </MeetingInfoLabel>
        <MeetingInfoLabel>
          <Icon type="location" />
          Location:
          <MeetingSpan>{data?.location}</MeetingSpan>
        </MeetingInfoLabel>
        {data?.location === "virtual" ? (
          <MeetingInfoLabel>
            <Icon type="link" />
            Meeting Link:
            <div
              dangerouslySetInnerHTML={{
                __html: convertToLinks(data?.meetingLink),
              }}
            />
          </MeetingInfoLabel>
        ) : (
          <MeetingInfoLabel>
            <Icon type="additional-info" />
            Perks:
            <MeetingSpan> {data?.perk ? data?.perk : "-"}</MeetingSpan>
          </MeetingInfoLabel>
        )}
        <MeetingInfoLabel>
          <Icon type="meeting-reason" />
          Reason For Meeting:{" "}
          <MeetingSpan> {data?.reasonForMeeting}</MeetingSpan>
        </MeetingInfoLabel>
        <MeetingInfoLabel>
          <Icon type="accept" />
          Accepted By:
          <MeetingSpan>
            {" "}
            {data?.Mentor
              ? data?.Mentor.firstName + " " + data?.Mentor.lastName
              : "-"}
          </MeetingSpan>
        </MeetingInfoLabel>
        <MeetingInfoLabel flexColumn={true}>
          <Flex>
            <Icon type="meeting-schedule" />
            Meeting Schedule Date:
          </Flex>
          {data.meetingScheduleDate ? (
            <>
              <MeetingSpan space={true}>
                {unixStampToDate(data.meetingScheduleDate).split(" at ")[0]}&nbsp;
                {unixStampToDate(data.meetingScheduleDate).split(" at ")[1]}
              </MeetingSpan>
            </>
          ) : (
            <>
              <MeetingSpan space={true}>
                {unixStampToDate(data?.date).split(" at ")[0]}&nbsp;
                {unixStampToDate(data?.date).split(" at ")[1]}
              </MeetingSpan>
              <MeetingSpan space={true}>
                {unixStampToDate(data?.date2).split(" at ")[0]}&nbsp;
                {unixStampToDate(data?.date2).split(" at ")[1]}
              </MeetingSpan>
              <MeetingSpan space={true}>
                {unixStampToDate(data?.date3).split(" at ")[0]}&nbsp;
                {unixStampToDate(data?.date3).split(" at ")[1]}
              </MeetingSpan>
            </>
          )}
        </MeetingInfoLabel>
        <MeetingInfoLabel flexColumn={true}>
          <Flex>
            <Icon type="additional-info" />
            Additional Info:
          </Flex>
          <MeetingSpan
            space={true}
            dangerouslySetInnerHTML={{
              __html: convertToLinks(
                data?.additionalInfo ? data.additionalInfo : "-"
              ),
            }}
          />
        </MeetingInfoLabel>
      </UserInfoContainer>
    </>
  );
}
export default MeetingInfo;
