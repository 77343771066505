import React from "react";
import { LabelizeContainer,Label, RedText } from "./styles";

function Labelize({label,labelkey ,mode,children,horizontal = false, isValid, applicationLabel}) {

  return <LabelizeContainer horizontal={horizontal}>
    
    <Label horizontal={horizontal} applicationLabel={applicationLabel}>
      {label}      
      </Label>
    {children}
  </LabelizeContainer>;
}
export default Labelize;