import React from "react";
import colors from "../../config/colors";
import Icon from "../Icon";
import { ButtonContainer, ButtonSpacer } from "./styles";

function Button({
  className,
  text,
  onClick = () => {},
  icon,
  variant = "secondary",
  iconLast = false,
  disabled = false,
  isNotRound,
  textColor,
  hasShadow,
  isTH,
  width,
  simple,
  borderLeft,
  borderRight,
  isLogin,
  isSupercharge,
  isTask
}) {
  let buttonColors = {
    primary: {
      backgroundColor: colors.primary,
      color: colors.secondary,
    },
    secondary: {
      backgroundColor: colors.secondary,
      color: colors.primary,
    },
    danger: {
      backgroundColor: colors.failure,
      color: colors.background,
    },
    disabled: {
      backgroundColor: colors.backgroundGrey,
      color: colors.disabledText,
    },
    Thdisabled: {
      backgroundColor: colors.primary,
      color: colors.background,
    },
    dark: {
      backgroundColor: colors.background,
      color: colors.primary,
    },
    pending: {
      backgroundColor: colors.background,
      color: colors.textGray,
    },
    Completed: {
      backgroundColor: colors.secondary,
      color: colors.textGray,
    },
    Save: {
      backgroundColor: colors.primary,
      color: colors.background,
    },
  };
  let { color, backgroundColor } = textColor
    ? textColor
    : buttonColors[disabled ? (isTH ? "Thdisabled" : "disabled") : variant];
  return (
    <ButtonContainer
      isTask={isTask}
      width={width}
      isSupercharge={isSupercharge}
      className={className}
      disabled={disabled}
      isNotRound={isNotRound}
      isLogin={isLogin}
      color={color}
      backgroundColor={backgroundColor}
      simple={simple}
      borderLeft={borderLeft}
      borderRight={borderRight}
      hasShadow={hasShadow}
      onClick={() => {
        !disabled && onClick();
      }}
      onlyIcon={icon && !text}
    >
      {icon && !iconLast && <Icon type={icon} color={color} />}
      {icon && !iconLast && text && <ButtonSpacer></ButtonSpacer>}
      {text}
      {icon && iconLast && text && <ButtonSpacer></ButtonSpacer>}
      {icon && iconLast && <Icon type={icon} color={color} />}
    </ButtonContainer>
  );
}
export default Button;
